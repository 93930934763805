import Vue from 'vue';

const service = {
  async createAgent(payload) {
    // NOTE (SG) : Keep only fields with values for payload
    // const filteredPayload = Object.entries(payload).reduce((a, [k, v]) => (v === '' ? a : ((a[k] = v), a)), {});
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/agents/v1`, payload);
    return response.data.data;
  },

  async updateAgent(key, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/agents/v1/${key}`, payload);
    return response.data.data;
  },

  async deleteAgent(key) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/agents/v1/${key}`);
    return response.data;
  },

  async undeleteAgent(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/agents/v1/${key}/undelete`);
    return response.data;
  },

  async attachProgramToAgent(key, programKey, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/agents/v1/${key}/programs/${programKey}`, payload);
    return response.data.data;
  },

  async removeProgramFromAgent(key, programKey, payload) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/agents/v1/${key}/programs/${programKey}`, payload);
    return response.data.data;
  },

  async searchAgent(page, limit, keyword, exclude_deactivated) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generateSearchAgentPath(page, limit, keyword, exclude_deactivated)}`);
    return response.data.data;
  },
};

function generateSearchAgentPath(page, limit, keyword, exclude_deactivated) {
  let path = '/agents/v1/search';
  if (page || limit || keyword || exclude_deactivated) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (exclude_deactivated !== null) {
    queryStrings.push(['exclude_deactivated', exclude_deactivated]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
