<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('partner.add_partner_action.title')"
    context="addPartner"
    @submit="submit"
    @cancel="cancel"
    cancel_text="partner.add_partner_action.button_cancel"
    submit_text="partner.add_partner_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-partner--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.id"
            v-model="partner.id"
            :label="$t('partner.add_partner_action.fields.id')"
            :error-messages="errors.collect('partner.id')"
            data-vv-name="partner.id"
            :data-vv-as="$t('partner.add_partner_action.fields.id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--add--id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.name"
            v-model="partner.name"
            :label="$t('partner.add_partner_action.fields.name')"
            :error-messages="errors.collect('partner.name')"
            data-vv-name="partner.name"
            :data-vv-as="$t('partner.add_partner_action.fields.name')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-partner--add--name"
          />
        </v-flex>
        <v-flex xs12>
          <v-select
            name="partner.region"
            v-model="partner.region"
            :label="$t('partner.add_partner_action.fields.region')"
            :error-messages="errors.collect('partner.region')"
            data-vv-name="partner.region"
            :data-vv-as="$t('partner.add_partner_action.fields.region')"
            :items="regionOptions"
            v-validate="'required|max:20'"
            class="smoke-partner--add--region"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-select
            name="partner.timezone"
            v-model="partner.timezone"
            :label="$t('partner.add_partner_action.fields.timezone')"
            :error-messages="errors.collect('partner.timezone')"
            data-vv-name="partner.timezone"
            :data-vv-as="$t('partner.add_partner_action.fields.timezone')"
            :items="timezoneOptions"
            v-validate="'required|max:20'"
            class="smoke-partner--add--timezone"
          ></v-select>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.business_id"
            v-model="partner.business_id"
            :label="$t('partner.add_partner_action.fields.business_id')"
            :error-messages="errors.collect('partner.business_id')"
            data-vv-business_id="partner.business_id"
            :data-vv-as="$t('partner.add_partner_action.fields.business_id')"
            v-validate="'max:20'"
            required
            counter
            maxlength="20"
            class="smoke-partner--add--business-id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.card_packages_en"
            v-model="partner.card_packages.en"
            :label="$t('partner.add_partner_action.fields.card_packages_en')"
            :error-messages="errors.collect('partner.card_packages.en')"
            data-vv-name="partner.card_packages.en"
            :data-vv-as="$t('partner.add_partner_action.fields.card_packages_en')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--add--card_package_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.card_packages_fr"
            v-model="partner.card_packages.fr"
            :label="$t('partner.add_partner_action.fields.card_packages_fr')"
            :error-messages="errors.collect('partner.card_packages.fr')"
            data-vv-card_name="partner.card_packages.fr"
            :data-vv-as="$t('partner.add_partner_action.fields.card_packages_fr')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--add--card_package_fr"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.renew_card_packages_en"
            v-model="partner.renew_card_packages.en"
            :label="$t('partner.add_partner_action.fields.renew_card_packages_en')"
            :error-messages="errors.collect('partner.renew_card_packages.en')"
            data-vv-name="partner.renew_card_packages.en"
            :data-vv-as="$t('partner.add_partner_action.fields.renew_card_packages_en')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--add--renew_card_package_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.renew_card_packages_fr"
            v-model="partner.renew_card_packages.fr"
            :label="$t('partner.add_partner_action.fields.renew_card_packages_fr')"
            :error-messages="errors.collect('partner.renew_card_packages.fr')"
            data-vv-card_name="partner.renew_card_packages.fr"
            :data-vv-as="$t('partner.add_partner_action.fields.renew_card_packages_fr')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--add--renew_card_package_fr"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.instant_card_packages_en"
            v-model="partner.instant_card_packages.en"
            :label="$t('partner.add_partner_action.fields.instant_card_packages_en')"
            :error-messages="errors.collect('partner.instant_card_packages.en')"
            data-vv-name="partner.instant_card_packages.en"
            :data-vv-as="$t('partner.add_partner_action.fields.instant_card_packages_en')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--add--instant_card_packages_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.instant_card_packages_fr"
            v-model="partner.instant_card_packages.fr"
            :label="$t('partner.add_partner_action.fields.instant_card_packages_fr')"
            :error-messages="errors.collect('partner.instant_card_packages.fr')"
            data-vv-card_name="partner.instant_card_packages.fr"
            :data-vv-as="$t('partner.add_partner_action.fields.instant_card_packages_fr')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--add--instant_card_packages_fr"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.instant_renew_card_packages_en"
            v-model="partner.instant_renew_card_packages.en"
            :label="$t('partner.add_partner_action.fields.instant_renew_card_packages_en')"
            :error-messages="errors.collect('partner.instant_renew_card_packages.en')"
            data-vv-name="partner.instant_renew_card_packages.en"
            :data-vv-as="$t('partner.add_partner_action.fields.instant_renew_card_packages_en')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--add--instant_renew_card_package_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.instant_renew_card_packages_fr"
            v-model="partner.instant_renew_card_packages.fr"
            :label="$t('partner.add_partner_action.fields.instant_renew_card_packages_fr')"
            :error-messages="errors.collect('partner.instant_renew_card_packages.fr')"
            data-vv-card_name="partner.instant__renew_card_packages.fr"
            :data-vv-as="$t('partner.add_partner_action.fields.instant_renew_card_packages_fr')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--add--instant_renew_card_package_fr"
          />
        </v-flex>
      </v-layout>

      <v-tabs height="34" class="modal-tabs">
        <v-tab key="options" class="smoke-partner--add--tab-options">{{ $t('partner.add_partner_action.fields.options.title') }}</v-tab>
        <v-tab key="webhooks" class="smoke-partner--add--tab-webhooks">{{ $t('partner.add_partner_action.fields.webhooks.title') }}</v-tab>
        <v-tab key="content" class="smoke-partner--add--tab-content">{{ $t('partner.add_partner_action.fields.content.title') }}</v-tab>
        <v-tab key="logo" class="smoke-partner--add--tab-logos">{{ $t('partner.add_partner_action.fields.logo.title') }}</v-tab>
        <v-tab key="features" class="smoke-partner--add--tab-features">{{ $t('partner.add_partner_action.fields.features.title') }}</v-tab>
        <v-tab key="apply" class="smoke-partner--add--tab-apply">{{ $t('partner.add_partner_action.fields.apply.title') }}</v-tab>
        <v-tab key="instant_card_application" class="smoke-partner--add--tab-options">{{ $t('partner.add_partner_action.fields.instant_card_application.title') }}</v-tab>
        <v-tab key="connector_interfaces" class="smoke-partner--add--tab-connector-interfaces">{{ $t('partner.add_partner_action.fields.connector_interfaces.title') }}</v-tab>

        <v-tab-item key="options">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('partner.add_partner_action.fields.options.title') }}</v-label>
              <div class="add-partner-action--options">
                <v-flex xs12>
                  <v-text-field
                    type="text"
                    name="currentPartner.options.integration_jwt_secret"
                    v-model="partner.options.integration_jwt_secret"
                    :label="$t('partner.add_partner_action.fields.options.integration_jwt_secret')"
                    class="smoke-partner--add--integration_jwt_secret"
                    :error-messages="errors.collect('currentPartner.options.integration_jwt_secret')"
                    data-vv-name="currentPartner.options.integration_jwt_secret"
                    :data-vv-as="$t('partner.add_partner_action.fields.options.integration_jwt_secret')"
                    v-validate="'max:255'"
                    maxlength="255"
                  >
                  </v-text-field>
                </v-flex>
                <v-checkbox v-model="partner.options.card_activation_reminder.active" :label="$t('partner.add_partner_action.fields.options.card_activation_reminder')" class="smoke-partner--add--card_activation_reminder" />
                <v-checkbox v-model="partner.options.join_program.active" :label="$t('partner.add_partner_action.fields.options.join_program')" class="smoke-partner--add--join_program" />
                <v-checkbox v-model="partner.options.card_application.active" :label="$t('partner.add_partner_action.fields.options.card_application')" class="smoke-partner--add--card_application" />

                <v-checkbox
                  v-model="partner.options.card_application.send_alert_on_approval"
                  :label="$t('partner.add_partner_action.fields.options.card_application_send_alert_on_approval')"
                  class="smoke-partner--add--card_application_send_alert_on_approval"
                />
                <v-checkbox v-model="partner.options.card_activation.active" :label="$t('partner.add_partner_action.fields.options.card_activation')" class="smoke-partner--add--card_activation" />
                <v-checkbox v-model="partner.options.manageable" :label="$t('partner.add_partner_action.fields.options.manageable')" class="smoke-partner--add--manageable" />
                <v-checkbox v-model="partner.options.invite_only" :label="$t('partner.update_partner_action.fields.options.invite_only')" class="smoke-partner--add--invite_only" />
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs11>
              <v-text-field
                name="allowed_card_package_ids"
                v-model="temp_allowed_card_package_ids"
                :label="$t('partner.add_partner_action.fields.options.allowed_card_package_ids')"
                class="smoke-partner--add--allowed_card_package_ids"
                v-validate="'max:255'"
                :error-messages="errors.collect('allowed_card_package_ids')"
                data-vv-name="allowed_card_package_ids"
                required
                counter
                maxlength="255"
                :data-vv-as="$t('partner.add_partner_action.fields.options.allowed_card_package_ids')"
              ></v-text-field>
            </v-flex>

            <v-flex xs1>
              <button type="button" class="smoke-partner--add--add-allowed_card_package_ids" @click="addAllowedCardPacakgeIdToArray">
                <v-icon small>fal fa-plus</v-icon>
              </button>
            </v-flex>
            <v-flex v-if="partner && partner.options.card_application.allowed_card_package_ids && partner.options.card_application.allowed_card_package_ids.length > 0" xs11 class="smoke-partner--add--allowed_card_package_ids-section">
              <v-label>{{ $t('partner.add_partner_action.fields.options.allowed_card_package_ids') }}</v-label>
              <ul class="smoke-partner--add--allowed_card_package_ids-list">
                <li v-for="(allowedCardPacakgeId, index) in partner.options.card_application.allowed_card_package_ids" :key="index">
                  {{ allowedCardPacakgeId }}
                  <button type="button" class="smoke-partner--add--remove-allowed_card_package_ids" @click="removeAllowedCardPacakgeIdFromArray(allowedCardPacakgeId)">
                    <v-icon small>fal fa-minus-circle</v-icon>
                  </button>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="webhooks" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.webhooks.title') }}</v-label>
            </v-flex>
          </v-layout>

          <!-- MEMBERSHIP -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="partner.webhooks.membership_activity.active" :label="$t('partner.add_partner_action.fields.webhooks.membership_activity')" class="smoke-partner--add--webhooks_membership_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.webhooks.membership_activity.uri"
                v-model="partner.webhooks.membership_activity.uri"
                :label="$t('partner.add_partner_action.fields.webhooks.uri') + `${partner.webhooks.membership_activity.active ? ' *' : ''}`"
                :error-messages="errors.collect('partner.webhooks.membership_activity.uri')"
                data-vv-name="partner.webhooks.membership_activity.uri"
                :data-vv-as="$t('partner.add_partner_action.fields.webhooks.uri')"
                :disabled="webhooksMembershipActivityDisabled()"
                v-validate="`${partner.webhooks.membership_activity.active ? 'required' : ''}|max:255`"
                maxlength="255"
                class="smoke-partner--add--webhooks_membership_activity_uri"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.webhooks.membership_activity.authorization"
                v-model="partner.webhooks.membership_activity.authorization"
                :label="$t('partner.add_partner_action.fields.webhooks.authorization')"
                :error-messages="errors.collect('partner.webhooks.membership_activity.authorization')"
                data-vv-name="partner.webhooks.membership_activity.authorization"
                :data-vv-as="$t('partner.add_partner_action.fields.webhooks.authorization')"
                :disabled="webhooksMembershipActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
                class="smoke-card-partner--add--webhooks_membership_activity_authorization"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <!-- MEMBER -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="partner.webhooks.member_activity.active" :label="$t('partner.add_partner_action.fields.webhooks.member_activity')" class="smoke-partner--add--webhooks_member_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.webhooks.member_activity.uri"
                v-model="partner.webhooks.member_activity.uri"
                :label="$t('partner.add_partner_action.fields.webhooks.uri') + `${partner.webhooks.member_activity.active ? ' *' : ''}`"
                :error-messages="errors.collect('partner.webhooks.member_activity.uri')"
                data-vv-name="partner.webhooks.member_activity.uri"
                :data-vv-as="$t('partner.add_partner_action.fields.webhooks.uri')"
                :disabled="webhooksMemberActivityDisabled()"
                v-validate="`${partner.webhooks.member_activity.active ? 'required' : ''}|max:255`"
                maxlength="255"
                class="smoke-partner--add--webhooks_member_activity_uri"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.webhooks.member_activity.authorization"
                v-model="partner.webhooks.member_activity.authorization"
                :label="$t('partner.add_partner_action.fields.webhooks.authorization')"
                :error-messages="errors.collect('partner.webhooks.member_activity.authorization')"
                data-vv-name="partner.webhooks.member_activity.authorization"
                :data-vv-as="$t('partner.add_partner_action.fields.webhooks.authorization')"
                :disabled="webhooksMemberActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
                class="smoke-card-partner--add--webhooks_member_activity_authorization"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <!-- OFFER -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="partner.webhooks.offer_activity.active" :label="$t('partner.add_partner_action.fields.webhooks.offer_activity')" class="smoke-partner--add--webhooks_offer_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.webhooks.offer_activity.uri"
                v-model="partner.webhooks.offer_activity.uri"
                :label="$t('partner.add_partner_action.fields.webhooks.uri') + `${partner.webhooks.offer_activity.active ? ' *' : ''}`"
                :error-messages="errors.collect('partner.webhooks.offer_activity.uri')"
                data-vv-name="partner.webhooks.offer_activity.uri"
                :data-vv-as="$t('partner.add_partner_action.fields.webhooks.uri')"
                :disabled="webhooksOfferActivityDisabled()"
                v-validate="`${partner.webhooks.offer_activity.active ? 'required' : ''}|max:255`"
                maxlength="255"
                class="smoke-partner--add--webhooks_offer_activity_uri"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.webhooks.offer_activity.authorization"
                v-model="partner.webhooks.offer_activity.authorization"
                :label="$t('partner.add_partner_action.fields.webhooks.authorization')"
                :error-messages="errors.collect('partner.webhooks.offer_activity.authorization')"
                data-vv-name="partner.webhooks.offer_activity.authorization"
                :data-vv-as="$t('partner.add_partner_action.fields.webhooks.authorization')"
                :disabled="webhooksOfferActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
                class="smoke-card-partner--add--webhooks_offer_activity_authorization"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="content" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.content.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.brand_color"
                v-model="partner.content.brand_color"
                :label="$t('partner.add_partner_action.fields.content.brand_color')"
                :error-messages="errors.collect('partner.content.brand_color')"
                data-vv-name="partner.content.brand_color"
                :data-vv-as="$t('partner.add_partner_action.fields.content.brand_color')"
                v-validate="'length:7'"
                required
                counter
                maxlength="7"
                class="smoke-partner--add--content_brand_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${partner.content.brand_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.content.name_en"
                v-model="partner.content.name.en"
                :label="$t('partner.add_partner_action.fields.content.name_en')"
                :error-messages="errors.collect('partner.content.name.en')"
                data-vv-name="partner.content.name.en"
                :data-vv-as="$t('partner.add_partner_action.fields.content.name_en')"
                v-validate="'required|max:100'"
                required
                counter
                maxlength="100"
                class="smoke-partner--add--content_name_en"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.content.url_en"
                v-model="partner.content.url.en"
                :label="$t('partner.add_partner_action.fields.content.url_en')"
                :error-messages="errors.collect('partner.content.url.en')"
                data-vv-name="partner.content.url.en"
                :data-vv-as="$t('partner.add_partner_action.fields.content.url_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--add--content_url_en"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.content.program_url_en"
                v-model="partner.content.program_url.en"
                :label="$t('partner.add_partner_action.fields.content.program_url_en')"
                :error-messages="errors.collect('partner.content.program_url.en')"
                data-vv-name="partner.content.program_url.en"
                :data-vv-as="$t('partner.add_partner_action.fields.content.program_url_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--add--content_program_url_en"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.content.name_fr"
                v-model="partner.content.name.fr"
                :label="$t('partner.add_partner_action.fields.content.name_fr')"
                :error-messages="errors.collect('partner.content.name.fr')"
                data-vv-name="partner.content.name.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.content.name_fr')"
                v-validate="'max:100'"
                counter
                maxlength="100"
                class="smoke-partner--add--content_name_fr"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.content.url_fr"
                v-model="partner.content.url.fr"
                :label="$t('partner.add_partner_action.fields.content.url_fr')"
                :error-messages="errors.collect('partner.content.url.fr')"
                data-vv-name="partner.content.url.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.content.url_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_url_fr"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="partner.content.program_url_fr"
                v-model="partner.content.program_url.fr"
                :label="$t('partner.add_partner_action.fields.content.program_url_fr')"
                :error-messages="errors.collect('partner.content.program_url.fr')"
                data-vv-name="partner.content.program_url.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.content.program_url_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_program_url_fr"
              />
            </v-flex>
          </v-layout>

          <v-layout row class="section-pad-top">
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.content.default_location.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.content.default_location.street"
                v-model="default_location.address.street"
                :label="$t('partner.add_partner_action.fields.content.default_location.street')"
                :error-messages="errors.collect('partner.content.default_location.street')"
                data-vv-name="partner.content.default_location.street"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.street')"
                v-validate="'max:50'"
                counter
                maxlength="50"
                class="smoke-partner--add--content_default_location_street"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.city"
                v-model="default_location.address.city"
                :label="$t('partner.add_partner_action.fields.content.default_location.city')"
                :error-messages="errors.collect('partner.content.default_location.city')"
                data-vv-name="partner.content.default_location.city"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.city')"
                v-validate="'max:50'"
                counter
                maxlength="50"
                class="smoke-partner--add--content_default_location_city"
              />
            </v-flex>
            <v-flex xs12 sm4>
              <v-select
                name="partner.content.default_location.state"
                v-model="default_location.address.state"
                :label="$t('partner.add_partner_action.fields.content.default_location.state')"
                :error-messages="errors.collect('partner.content.default_location.state')"
                data-vv-name="partner.content.default_location.state"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.state')"
                :items="stateOptions"
                item-text="name"
                item-value="abbr"
                v-validate="'max:50'"
                class="smoke-partner--add--content_default_location_state"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm4>
              <v-select
                name="partner.content.default_location.country"
                v-model="default_location.address.country"
                :label="$t('partner.add_partner_action.fields.content.default_location.country')"
                :error-messages="errors.collect('partner.content.default_location.country')"
                data-vv-name="partner.content.default_location.country"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.country')"
                :items="countryOptions"
                v-validate="'max:50'"
                item-text="name"
                item-value="abbr"
                class="smoke-partner--add--content_default_location_country"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.zip"
                v-model="default_location.address.zip"
                :label="$t('partner.add_partner_action.fields.content.default_location.zip')"
                :error-messages="errors.collect('partner.content.default_location.zip')"
                data-vv-name="partner.content.default_location.zip"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.zip')"
                v-validate="{ regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
                maxlength="7"
                class="smoke-partner--add--content_default_location_zip"
              />
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.lat"
                v-model="default_location.coordinates.lat"
                :label="$t('partner.add_partner_action.fields.content.default_location.lat')"
                :error-messages="errors.collect('partner.content.default_location.lat')"
                data-vv-name="partner.content.default_location.lat"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.lat')"
                v-validate="'max:50'"
                maxlength="50"
                class="smoke-partner--add--content_default_location_lat"
              />
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.lng"
                v-model="default_location.coordinates.lng"
                :label="$t('partner.add_partner_action.fields.content.default_location.lng')"
                :error-messages="errors.collect('partner.content.default_location.lng')"
                data-vv-name="partner.content.default_location.lng"
                :data-vv-as="$t('partner.add_partner_action.fields.content.default_location.lng')"
                v-validate="'max:50'"
                maxlength="50"
                class="smoke-partner--add--content_default_location_lng"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="logo" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.logo.light_background') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.light_background.horizontal_en"
                v-model="partner.content.logo.light_background.horizontal.en"
                :label="$t('partner.add_partner_action.fields.logo.horizontal_en')"
                :error-messages="errors.collect('partner.content.logo.light_background.horizontal.en')"
                data-vv-name="partner.content.logo.light_background.horizontal.en"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.horizontal_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--add--content_light_background_horizontal_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img :src="partner.content.logo.light_background.horizontal.en"></v-img> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.light_background.horizontal_fr"
                v-model="partner.content.logo.light_background.horizontal.fr"
                :label="$t('partner.add_partner_action.fields.logo.horizontal_fr')"
                :error-messages="errors.collect('partner.logo.light_background.horizontal_fr')"
                data-vv-name="partner.logo.light_background.horizontal_fr"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.horizontal_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_light_background_horizontal_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img v-if="partner.content.logo.light_background.horizontal.fr" :src="partner.content.logo.light_background.horizontal.fr" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.light_background.square_en"
                v-model="partner.content.logo.light_background.square.en"
                :label="$t('partner.add_partner_action.fields.logo.square_en')"
                :error-messages="errors.collect('partner.content.logo.light_background.square.en')"
                data-vv-name="partner.content.logo.light_background.square.en"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.square_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--add--content_light_background_square_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img :src="partner.content.logo.light_background.square.en" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.light_background.square_fr"
                v-model="partner.content.logo.light_background.square.fr"
                :label="$t('partner.add_partner_action.fields.logo.square_fr')"
                :error-messages="errors.collect('partner.content.logo.light_background.square.fr')"
                data-vv-name="partner.content.logo.light_background.square.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.square_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_light_background_square_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img v-if="partner.content.logo.light_background.square.fr" :src="partner.content.logo.light_background.square.fr" /> </v-flex>
          </v-layout>

          <v-layout row class="section-pad-top">
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.logo.dark_background') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.dark_background.horizontal_en"
                v-model="partner.content.logo.dark_background.horizontal.en"
                :label="$t('partner.add_partner_action.fields.logo.horizontal_en')"
                :error-messages="errors.collect('partner.content.logo.dark_background.horizontal.en')"
                data-vv-name="partner.content.logo.dark_background.horizontal.en"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.horizontal_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--add--content_dark_background_horizontal_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img :src="partner.content.logo.dark_background.horizontal.en" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.dark_background.horizontal_fr"
                v-model="partner.content.logo.dark_background.horizontal.fr"
                :label="$t('partner.add_partner_action.fields.logo.horizontal_fr')"
                :error-messages="errors.collect('partner.content.logo.dark_background.horizontal.fr')"
                data-vv-name="partner.content.logo.dark_background.horizontal.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.horizontal_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_dark_background_horizontal_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img v-if="partner.content.logo.dark_background.horizontal.fr" :src="partner.content.logo.dark_background.horizontal.fr" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.dark_background.square_en"
                v-model="partner.content.logo.dark_background.square.en"
                :label="$t('partner.add_partner_action.fields.logo.square_en')"
                :error-messages="errors.collect('partner.content.logo.dark_background.square.en')"
                data-vv-name="partner.content.logo.dark_background.square.en"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.square_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--add--content_dark_background_square_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img :src="partner.content.logo.dark_background.square.en" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="partner.logo.dark_background.square_fr"
                v-model="partner.content.logo.dark_background.square.fr"
                :label="$t('partner.add_partner_action.fields.logo.square_fr')"
                :error-messages="errors.collect('partner.content.logo.dark_background.square.fr')"
                data-vv-name="partner.content.logo.dark_background.square.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.logo.square_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_dark_background_square_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img v-if="partner.content.logo.dark_background.square.fr" :src="partner.content.logo.dark_background.square.fr" /> </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item kye="features" class="section-pad-top">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('partner.add_partner_action.fields.features.title') }}</v-label>
            </v-flex>
            <v-flex v-for="feature in checkboxFeature" :key="feature.value" xs12 class="smoke-partner--add--features">
              <v-checkbox :class="{ [`smoke-partner--add--features--${feature.value}`]: true }" xs12 v-model="partner.features" :label="feature.label" :value="feature.value"></v-checkbox>
            </v-flex>

            <v-flex xs11>
              <v-text-field
                name="feature"
                v-model="temp_feature"
                :label="$t('partner.add_partner_action.fields.features.other')"
                class="smoke-partner--add--other-feature"
                v-validate="'max:255'"
                :error-messages="errors.collect('feature')"
                data-vv-name="feature"
                required
                counter
                maxlength="255"
                :data-vv-as="$t('partner.add_partner_action.fields.features.other')"
              ></v-text-field>
            </v-flex>

            <v-flex xs1>
              <button type="button" class="smoke-partner--add--add-other-feature" @click="addFeatureToArray" :disabled="disable_add_feature">
                <v-icon small>fal fa-plus</v-icon>
              </button>
            </v-flex>

            <v-flex v-if="partner && partner.features && partner.features.length > 0" xs11 class="smoke-partner--add--features-section">
              <v-label>{{ $t('partner.add_partner_action.fields.features.active') }}</v-label>
              <ul class="smoke-partner--add--features-list">
                <li v-for="(feature, index) in partner.features" :key="index">
                  {{ feature }}
                  <button type="button" class="smoke-partner--add--remove-feature" @click="removeFeatureFromArray(feature)">
                    <v-icon small>fal fa-minus-circle</v-icon>
                  </button>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="apply" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.apply.header_logo') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.apply.header_logo_url_en"
                v-model="partner.content.apply.header_logo_url.en"
                :label="$t('partner.add_partner_action.fields.apply.url_en')"
                :error-messages="errors.collect('partner.content.apply.header_logo_url.en')"
                data-vv-name="partner.content.apply.header_logo_url.en"
                :data-vv-as="$t('partner.add_partner_action.fields.apply.url_en')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_apply_header_url_en"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.apply.header_logo_url_fr"
                v-model="partner.content.apply.header_logo_url.fr"
                :label="$t('partner.add_partner_action.fields.apply.url_fr')"
                :error-messages="errors.collect('partner.content.apply.header_logo_url.fr')"
                data-vv-name="partner.content.apply.header_logo_url.fr"
                :data-vv-as="$t('partner.add_partner_action.fields.apply.url_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--add--content_apply_header_url_fr"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.apply.body_action_color"
                v-model="partner.content.apply.body_action_color"
                :label="$t('partner.add_partner_action.fields.apply.body_action_color')"
                :error-messages="errors.collect('partner.apply.body_action_color')"
                data-vv-name="partner.apply.body_action_color"
                :data-vv-as="$t('partner.add_partner_action.fields.apply.body_action_color')"
                v-validate="'length:7'"
                counter
                maxlength="7"
                class="smoke-partner--add--content_body_action_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${partner.content.apply.body_action_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.apply.header_background_color"
                v-model="partner.content.apply.header_background_color"
                :label="$t('partner.add_partner_action.fields.apply.header_background_color')"
                :error-messages="errors.collect('partner.apply.header_background_color')"
                data-vv-name="partner.apply.header_background_color"
                :data-vv-as="$t('partner.add_partner_action.fields.apply.header_background_color')"
                v-validate="'length:7'"
                counter
                maxlength="7"
                class="smoke-partner--add--content_header_background_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${partner.content.apply.header_background_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.apply.header_text_color"
                v-model="partner.content.apply.header_text_color"
                :label="$t('partner.add_partner_action.fields.apply.header_text_color')"
                :error-messages="errors.collect('partner.apply.header_text_color')"
                data-vv-name="partner.apply.header_text_color"
                :data-vv-as="$t('partner.add_partner_action.fields.apply.header_text_color')"
                v-validate="'length:7'"
                counter
                maxlength="7"
                class="smoke-partner--add--content_header_text_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${partner.content.apply.header_text_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-checkbox v-model="partner.content.apply.preview_page_active" :label="$t('partner.add_partner_action.fields.apply.preview_page_active')" class="smoke-partner--add--content_preview_page_active" />
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="instant_card_application">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('partner.add_partner_action.fields.instant_card_application.title') }}</v-label>
              <div class="add-partner-action--options">
                <v-flex xs12>
                  <v-checkbox v-model="partner.account_fields.gender" :label="$t('partner.add_partner_action.fields.instant_card_application.gender')" class="smoke-partner--add--card_application_gender" />

                  <v-checkbox v-model="partner.account_fields.year_of_birth" :label="$t('partner.add_partner_action.fields.instant_card_application.year_of_birth')" class="smoke-partner--add--card_application_day_of_birth" />
                </v-flex>
              </div>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="connector_interfaces" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.add_partner_action.fields.connector_interfaces.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-for="(connector_interface_name, index) in getConnectorInterfaces" :key="index">
            <v-flex xs12>
              <v-checkbox v-model="partner.connector_interfaces[connector_interface_name].active" :label="connector_interface_name" :class="`smoke-partner--add--connector_interface_${connector_interface_name}_active`"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-select
                name="language"
                :disabled="!partner.connector_interfaces[connector_interface_name].active"
                v-model="partner.connector_interfaces[connector_interface_name].connector_name"
                :label="$t('partner.add_partner_action.fields.connector_interfaces.connector_name')"
                :error-messages="errors.collect(`${connector_interface_name}_connector_name`)"
                :data-vv-name="`${connector_interface_name}_connector_name`"
                :data-vv-as="$t(`partner.add_partner_action.fields.connector_interfaces.connector_name`)"
                :items="getConnectorNames"
                :class="`smoke-partner--add--connector_interface_${connector_interface_name}_connector_name`"
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import states from '@/utils/states';
import { mapGetters } from 'vuex';
import CANADIAN_TIMEZONES from '@/tools/canadian-timezones';

export default {
  name: 'add-partner-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      temp_feature: '',
      temp_allowed_card_package_ids: '',
      disable_add_feature: true,
      partner: emptyPartner(),
      default_location: emptyDefaultLocation(),
      checkboxFeature: [
        { label: 'Magis Prime', value: 'magis-prime' },
        { label: 'Hello Offer', value: 'hello-offers' },
        { label: 'Hello Workplace', value: 'hello-workplace' },
        { label: 'Request Consent Communication', value: 'request-consent-communication' },
      ],
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: states.selectOptions(this),
      regionOptions: ['ON', 'QC', 'NB'],
      timezoneOptions: [...CANADIAN_TIMEZONES],
    };
  },
  watch: {
    temp_feature() {
      if (this.temp_feature && this.isFeatureValid()) {
        this.disable_add_feature = false;
      } else {
        this.disable_add_feature = true;
      }
    },
  },
  computed: {
    ...mapGetters('partner', ['getConnectorNames', 'getConnectorInterfaces']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  methods: {
    isFeatureValid() {
      if (this.errors.has('feature')) {
        return false;
      }
      return true;
    },
    addAllowedCardPacakgeIdToArray() {
      this.partner.options.card_application.allowed_card_package_ids.push(this.temp_allowed_card_package_ids);
    },
    removeAllowedCardPacakgeIdFromArray(allowedCardPacakgeId) {
      const updatedAllowedCardPacakgeId = _.filter(this.partner.options.card_application.allowed_card_package_ids, (item) => {
        return item !== allowedCardPacakgeId;
      });
      this.partner.options.card_application.allowed_card_package_ids = updatedAllowedCardPacakgeId;
    },
    addFeatureToArray() {
      this.partner.features.push(this.temp_feature);
    },
    removeFeatureFromArray(feature) {
      const updatedFeature = _.filter(this.partner.features, (item) => {
        return item !== feature;
      });
      this.partner.features = updatedFeature;
    },

    resetForm() {
      this.partner = emptyPartner();
      this.default_location = emptyDefaultLocation();
      this.temp_feature = '';
      this.temp_allowed_card_package_ids = '';
      this.disable_add_feature = true;
    },
    async submit() {
      if (await this.validationComplete()) {
        this.partner.options.integration_jwt_secret = this.partner.options.integration_jwt_secret === '' ? null : this.partner.options.integration_jwt_secret;

        await this.executeAction(
          {
            action: 'partner/createPartner',
            name: 'addPartner',
            success: this.success,
          },
          { partner: this.buildPayload() },
        );
      }
      return await true;
    },
    buildPayload() {
      if (this.default_location.address.street) {
        this.partner.content.default_location = this.default_location;
      }
      return this.partner;
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    webhooksMembershipActivityDisabled() {
      return !this.partner.webhooks.membership_activity.active;
    },
    webhooksMemberActivityDisabled() {
      return !this.partner.webhooks.member_activity.active;
    },
    webhooksOfferActivityDisabled() {
      return !this.partner.webhooks.offer_activity.active;
    },
  },
};

function emptyPartner() {
  return {
    id: '',
    name: '',
    region: undefined,
    business_id: '',
    card_packages: {
      en: '',
      fr: '',
    },
    renew_card_packages: {
      en: '',
      fr: '',
    },
    instant_card_packages: {
      en: '',
      fr: '',
    },
    instant_renew_card_packages: {
      en: '',
      fr: '',
    },
    features: [],
    options: {
      integration_jwt_secret: null,
      card_activation_reminder: {
        active: false,
      },
      join_program: {
        active: false,
      },
      card_application: {
        active: false,
        send_alert_on_approval: false,
        allowed_card_package_ids: [],
      },
      card_activation: {
        active: false,
      },
      invite_only: false,
    },
    webhooks: {
      membership_activity: {
        active: false,
        uri: null,
        authorization: null,
      },
      member_activity: {
        active: false,
        uri: null,
        authorization: null,
      },
      offer_activity: {
        active: false,
        uri: null,
        authorization: null,
      },
    },
    connector_interfaces: {
      magis: {
        active: false,
      },
    },
    content: {
      name: {
        en: '',
        fr: null,
      },
      default_location: undefined,
      url: {
        en: '',
        fr: null,
      },
      program_url: {
        en: '',
        fr: null,
      },
      logo: {
        light_background: {
          horizontal: {
            en: '',
            fr: null,
          },
          square: {
            en: '',
            fr: null,
          },
        },
        dark_background: {
          horizontal: {
            en: '',
            fr: null,
          },
          square: {
            en: '',
            fr: null,
          },
        },
      },
      apply: {
        header_logo_url: {
          en: null,
          fr: null,
        },
        body_action_color: null,
        header_background_color: null,
        header_text_color: null,
        preview_page_active: false,
      },
    },
    account_fields: {
      gender: true,
      year_of_birth: true,
    },
  };
}

function emptyDefaultLocation() {
  return {
    address: {
      street: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      zip: undefined,
    },
    coordinates: {
      lat: undefined,
      lng: undefined,
    },
  };
}
</script>
