<template>
  <v-btn outline color="warning" v-on="listeners">{{ text }}</v-btn>
</template>

<script>
export default {
  name: 'button-warning',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>
