import Vue from 'vue';

const service = {
  async listOrganizations(page, limit, excludeOrgKey) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatelistOrgsPath(page, limit, excludeOrgKey)}`);
    return response.data.data.items;
  },
};

function generatelistOrgsPath(page, limit, excludeOrgKey) {
  let path = '/organizations/v1';
  if (page || limit || excludeOrgKey) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (excludeOrgKey) {
    queryStrings.push(['excludeOrgKey', excludeOrgKey]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
