import moment from 'moment';
import datetimeFactory from 'datetime-factory';

export default ({ Vue, app }) => {
  Vue.filter('formatDate', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(String(date)).format('MMMM D YYYY, h:mm:ss a');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatDateHourMin', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(String(date)).format('MMMM D YYYY, h:mm a');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatInvertedDateWithElapsedDays', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);

      const diff = datetimeFactory.now().diff(moment(date));
      const currentDuration = moment.duration(diff);

      return `${moment(String(date)).format('YYYY-MM-DD HH:MM:SS')} (${currentDuration.humanize()} ${app.i18n.t('system.dates.ago')})`;
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatDateNoTime', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(String(date)).format('MMMM D, YYYY');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatShortDate', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(String(date)).format('DD-MM-YYYY');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatShortDateInverted', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(String(date)).format('YYYY-MM-DD');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatYearHyphenMonth', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(String(date)).format('YYYY-MM');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('addHyphensToDate', (date) => {
    if (date && !includesHyphen(date)) {
      return hyphenateDate(date);
    }
  });

  Vue.filter('calculateTimeElapsed', (date) => {
    moment.locale(app.i18n.locale);
    if (date) {
      const diff = datetimeFactory.now().diff(moment(date));
      const currentDuration = moment.duration(diff);

      if (currentDuration.years() >= 3) {
        return `${currentDuration.years()} ${app.i18n.tc('system.dates.year', currentDuration.years())}`;
      } else if (currentDuration.asMonths() < 1) {
        return `${currentDuration.days()} ${app.i18n.tc('system.dates.day', currentDuration.days())}`;
      }
      const monthsTruncated = Math.trunc(currentDuration.asMonths());
      return `${monthsTruncated} ${app.i18n.tc('system.dates.month', monthsTruncated)}`;
    }
  });

  function includesHyphen(string) {
    return string.includes('-');
  }

  function hyphenateDate(date) {
    return [date.slice(0, 4), date.slice(4, 6), date.slice(6)].join('-');
  }
};
