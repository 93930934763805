<template>
  <card icon="fal fa-user" :title="`${$t('program.list.title')}`">
    <template slot="headerZoneRight"> </template>

    <template slot="content">
      <programs-table :items="items"></programs-table>
    </template>
  </card>
</template>

<script>
import ProgramsTable from './ProgramsTable';

export default {
  name: 'programs-list',
  components: { ProgramsTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
