<template>
  <div>
    <table class="packages-table responsive-table smoke-card-program--list-table">
      <thead>
        <tr>
          <td>{{ $t('program.listTable.key') }}</td>
          <td>{{ $t('program.listTable.id') }}</td>
          <td>{{ $t('program.listTable.type') }}</td>
          <td>{{ $t('program.listTable.name') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="smoke-card-program--list-table--key-column">
            <h5>{{ $t('program.listTable.key') }}:</h5>
            <span :class="{ 'info-not-available': !item.key }">{{ item.key | orNone }}</span>
          </td>
          <td class="smoke-card-program--list-table--id-column">
            <h5>{{ $t('program.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td class="smoke-card-program--list-table--name-type">
            <h5>{{ $t('program.listTable.type') }}:</h5>
            <span :class="{ 'info-not-available': !item.type }">{{ item.type | orNone }}</span>
          </td>
          <td class="smoke-card-program--list-table--name-column">
            <h5>{{ $t('program.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td class="smoke-card-program--list-table--actions-column">
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passProgramToModal(item);
                showModalUpdateProgram();
              "
              class="smoke-card-program--list-table--edit-button"
              >{{ $t(`program.listTable.edit`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdateProgramModal"> <update-program-action :program="currentProgram" @close="closeModalUpdateProgram" @action="reloadPage" /> </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="programs-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdateProgramAction from './UpdateProgramAction';
import { mapGetters } from 'vuex';

export default {
  name: 'programs-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateProgramAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentProgram: {},
      pagination: 1,
      showUpdateProgramModal: false,
    };
  },
  computed: {
    ...mapGetters('program', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'program/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showUpdateProgramModal = false;
    },
    passProgramToModal(cardProgram) {
      this.currentProgram = cardProgram;
    },
    showModalUpdateProgram() {
      this.showUpdateProgramModal = true;
    },
    closeModalUpdateProgram() {
      this.showUpdateProgramModal = false;
    },
  },
};
</script>
