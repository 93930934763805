import _ from 'lodash';

export function createDefaultContext(contextOverwrite = {}) {
  function defaultHookFunction() {}

  return {
    action: contextOverwrite.action || null,
    name: contextOverwrite.name || 'global',
    success: contextOverwrite.success || defaultHookFunction,
    beforeError: contextOverwrite.beforeError || defaultHookFunction,
    afterError: contextOverwrite.afterError || defaultHookFunction,
    finally: contextOverwrite.finally || defaultHookFunction,
  };
}

export function safeDispatcher(dispatch, context) {
  if (_.isString(context)) {
    context = { name: context };
  }
  return (actionName, params) => {
    return dispatch('general/execute', { context: createDefaultContext(_.merge({}, { action: actionName }, context)), params }, { root: true });
  };
}
