<template>
  <view-main title="page_title.agent"> <view-agent-form slot="content"></view-agent-form> </view-main>
</template>

<script>
import ViewAgentForm from './ViewAgentForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'agent-view',
  mixins: [renderErrorTrackable],
  components: { ViewAgentForm },
};
</script>
