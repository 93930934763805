<template>
  <card :title="$t(`code.alert.id.${alert.id}`)" icon="" class="view-alert">
    <template slot="headerZoneRight">
      <label-text :label="$t('alert.status')" :value="$t(`code.alert.status.${alert.status}`)" :class-array="[`alert-status-${alert.status}`, 'alert--status-field']"></label-text>
      <label-text :label="$t('alert.sms_status')" :value="$t(`code.alert.sms.${alert.sms.active}`)" :class-array="[`alert-sms-status-${alert.sms.active}`, 'alert--sms-status-field']"></label-text>
      <label-text :label="$t('alert.push_status')" :value="$t(`code.alert.push.${alert.push.active}`)" :class-array="[`alert-sms-status-${alert.push.active}`, 'alert--push-status-field']"></label-text>
      <label-text :label="$t('alert.webhook_status')" :value="$t(`code.alert.webhook.${alert.webhook.active}`)" :class-array="[`alert-webhook-status-${alert.webhook.active}`, 'alert--webhook-status-field']"></label-text>
      <label-text :label="$t('alert.email_status')" :value="$t(`code.alert.email.${alert.email.active}`)" :class-array="[`alert-email-status-${alert.email.active}`, 'alert--email-status-field']"></label-text>
      <label-text :label="$t('alert.action')" id="update-trigger">
        <link-secondary-action slot="labelTextLink" @click="showModalUpdateAlert"> {{ $t('alert.edit') }} </link-secondary-action>
      </label-text>
    </template>

    <template slot="content">
      <modal-action avoid-rendering :show-modal="showUpdateAlertModal">
        <update-alert :alert="alert" @close="closeModalUpdateAlert" @action="reloadPage" />
      </modal-action>

      <article>
        <h4>{{ $t('alert.messages.title') }}</h4>
        <label-text :label="$t('alert.messages.en')" :value="alert.message.en" :class-array="['alert--message-en']"></label-text>
        <label-text :label="$t('alert.messages.fr')" :value="alert.message.fr" :class-array="['alert--message-fr']"></label-text>
      </article>

      <article v-if="alert.push.active" class="view-alert--push-notification-section">
        <h4>{{ $t('alert.messages.push') }}</h4>
        <v-layout row wrap>
          <v-flex xs12 v-if="alert.push.message.en">
            <h5>{{ $t('alert.messages.en') }}</h5>
            <label-text-compact :label="$t('alert.messages.push_title')" :value="alert.push.title.en" :class-array="['alert--push-title-en']"></label-text-compact>
            <label-text-compact :label="$t('alert.messages.push_message')" :value="alert.push.message.en" :class-array="['alert--push-message-en']"></label-text-compact>
          </v-flex>
          <v-flex xs12 v-if="alert.push.message.fr">
            <h5>{{ $t('alert.messages.fr') }}</h5>
            <label-text-compact :label="$t('alert.messages.push_title')" :value="alert.push.title.fr" :class-array="['alert--push-title-fr']"></label-text-compact>
            <label-text-compact :label="$t('alert.messages.push_message')" :value="alert.push.message.fr" :class-array="['alert--push-message-fr']"></label-text-compact>
          </v-flex>
        </v-layout>
      </article>

      <article v-if="alert.push.data && alert.push.android && alert.push.apns" class="view-alert--extra-push-info">
        <v-layout>
          <v-flex>
            <label-text v-if="alert.push.data" :label="$t('alert.push_data')" :value="alert.push.data" :class-array="['alert--push-data']"></label-text>
            <label-text v-if="alert.push.android" :label="$t('alert.push_android')" :value="alert.push.android" :class-array="['alert--push-android']"></label-text>
            <label-text v-if="alert.push.apns" :label="$t('alert.push_apns')" :value="alert.push.apns" :class-array="['alert--push-apns']"></label-text>
          </v-flex>
        </v-layout>
      </article>
    </template>
  </card>
</template>

<script>
import UpdateAlert from './UpdateAlert';

export default {
  name: 'view-alert',
  components: { UpdateAlert },
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showUpdateAlertModal: false,
    };
  },
  methods: {
    showModalUpdateAlert() {
      this.showUpdateAlertModal = true;
    },
    closeModalUpdateAlert() {
      this.showUpdateAlertModal = false;
    },
    reloadPage() {
      this.showUpdateAlertModal = false;
    },
  },
};
</script>
