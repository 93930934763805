<template>
  <v-flex xs1> <v-select :items="locales" v-model="locale" item-text="text" item-value="value" label="Locale" single-line /> </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Validator } from 'vee-validate';

export default {
  data() {
    return {
      locale: 'en',
    };
  },

  watch: {
    locale(val) {
      this.changeLocale(val);
      this.$i18n.locale = val;
      Validator.localize(val);
    },
  },

  methods: {
    ...mapActions('ui', ['changeLocale']),
  },

  computed: {
    ...mapGetters('ui', ['locales']),
  },
};
</script>
