import Vue from 'vue';
import Errors from './../utils/error/error-types';

const service = {
  async createSpace(space) {
    try {
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces`, space);
      return response.data.data;
    } catch (e) {
      if (spaceAlreadyExists(e)) {
        return Promise.reject(transformToHttpError(400, 'space.id.already_exists'));
      }

      throw e;
    }
  },

  async updateSpace(id, space) {
    delete space.created;
    delete space.deleted;

    try {
      const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces/${id}`, space);
      return response.data.data;
    } catch (e) {
      if (spaceAlreadyExists(e)) {
        return Promise.reject(transformToHttpError(400, 'space.id.already_exists'));
      }
      throw e;
    }
  },

  async getSpace(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/space/v1/spaces/${id}`);
    return response.data.data;
  },

  async listSpaces(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/space/v1/spaces', page, limit)}`);
    return response.data.data;
  },
};

function spaceAlreadyExists(e) {
  return e.response_code === 'space.already.exists';
}

function transformToHttpError(statusCode, code) {
  return new Errors.HttpError(statusCode, { error: { code } });
}

function generatePath(startPath, page, limit) {
  let path = startPath;
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
