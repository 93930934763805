import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';
import _ from 'lodash';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,

  state: {
    noteType: { name: {} },
    noteTypes: [],
    pageNumber: defaultPage,
    pageCount: 0,
  },

  getters: {
    noteTypes: (state) => state.noteTypes,
    getNoteType: (state) => (id) => state.noteTypes.find((type) => type.id === id),
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },

  mutations: {
    noteTypes(state, types) {
      state.noteTypes = _.sortBy(types, ['category_id', 'name.en']);
    },
    noteType(state, type) {
      state.noteType = type;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listNoteTypes({ commit }, { page, limit }) {
      const service = Vue.prototype.$services.amlNoteType;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const noteTypesList = await service.listNoteTypes(thePage, theLimit);

      commit('page', thePage);
      commit('pageCount', noteTypesList.page_count);
      commit('noteTypes', noteTypesList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('noteType/listNoteTypes', { page });
    },

    async getNoteType({ commit }, { id }) {
      const service = Vue.prototype.$services.amlNoteType;
      const noteType = await service.getNoteType(id);

      commit('noteType', noteType);
    },

    async updateNoteType({ dispatch }, { note_type }) {
      const service = Vue.prototype.$services.amlNoteType;
      const defaultDispatch = safeDispatcher(dispatch);

      const { id } = note_type;
      const noteType = _.pick(note_type, ['name.fr', 'name.en', 'active']);

      await service.updateNoteType(id, noteType);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'note_type.update_action.success' });
      await defaultDispatch('noteType/listNoteTypes');
    },

    async createNoteType({ dispatch }, { note_type }) {
      const service = Vue.prototype.$services.amlNoteType;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.createNoteType(note_type);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'note_type.add_action.success' });
      await defaultDispatch('noteType/listNoteTypes');
    },
  },
};
