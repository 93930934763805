<template>
  <div class="simple-list">
    <h5>{{ label }}</h5>
    <v-list>
      <template v-if="listItems.length">
        <li v-for="(listItem, index) in listItems" :key="index" class="simple-list--list-item">{{ $t(`${translationPrefix}.${listItem}`) }}</li>
      </template>
      <li v-else class="info-not-available">{{ $t('system.na') }}</li>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'simple-list',
  props: {
    label: {
      type: String,
      required: true,
    },
    listItems: {
      type: Array,
      required: true,
    },
    translationPrefix: {
      type: String,
      required: true,
    },
  },
};
</script>
