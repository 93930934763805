<template>
  <card icon="fal fa-user" :title="`${$t('package.list.title')}`">
    <template slot="headerZoneRight"> </template>

    <template slot="content">
      <packages-table :items="items"></packages-table>
    </template>
  </card>
</template>

<script>
import PackagesTable from './PackagesTable';

export default {
  name: 'packages-list',
  components: { PackagesTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
