<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('program.add_program_action.title')"
    context="addProgram"
    @submit="submit"
    @cancel="cancel"
    cancel_text="program.add_program_action.button_cancel"
    submit_text="program.add_program_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-card-program--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm4 xs12>
          <v-text-field
            name="cardProgram.id"
            v-model="cardProgram.id"
            :label="$t('program.add_program_action.fields.id')"
            :error-messages="errors.collect('cardProgram.id')"
            data-vv-name="cardProgram.id"
            :data-vv-as="$t('program.add_program_action.fields.id')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-card-program--add--id"
          />
        </v-flex>
        <v-flex sm8 xs12>
          <v-text-field
            name="cardProgram.name"
            v-model="cardProgram.name"
            :label="$t('program.add_program_action.fields.name')"
            :error-messages="errors.collect('cardProgram.name')"
            data-vv-name="cardProgram.name"
            :data-vv-as="$t('program.add_program_action.fields.name')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-card-program--add--name"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm4 xs12>
          <v-text-field
            name="cardProgram.currency"
            v-model="cardProgram.currency"
            :label="$t('program.add_program_action.fields.currency')"
            :error-messages="errors.collect('cardProgram.currency')"
            data-vv-name="cardProgram.currency"
            :data-vv-as="$t('program.add_program_action.fields.currency')"
            v-validate="'required|min:3|max:3'"
            required
            counter
            maxlength="3"
            class="smoke-card-program--add--currency"
          />
        </v-flex>

        <v-flex sm4 xs12>
          <v-text-field
            name="cardProgram.gps.iss_code"
            v-model="cardProgram.gps.iss_code"
            :label="$t('program.add_program_action.fields.gps.iss_code')"
            :error-messages="errors.collect('cardProgram.gps.iss_code')"
            data-vv-name="cardProgram.gps.iss_code"
            :data-vv-as="$t('program.add_program_action.fields.gps.iss_code')"
            v-validate="'required|min:1|max:4'"
            required
            counter
            maxlength="4"
            class="smoke-card-program--add--iss_code"
          />
        </v-flex>

        <v-flex sm4 xs12>
          <v-select
            name="cardProgram.type"
            v-model="cardProgram.type"
            :items="programTypes"
            item-text="label"
            item-value="value"
            :label="$t('program.add_program_action.fields.type.label')"
            :error-messages="errors.collect('cardProgram.type')"
            data-vv-name="cardProgram.type"
            :data-vv-as="$t('program.add_program_action.fields.type.label')"
            v-validate="'required'"
            required
            class="smoke-card-program--add--type"
          ></v-select>
        </v-flex>
      </v-layout>

      <v-tabs height="34" class="modal-tabs">
        <v-tab key="options" class="smoke-card-program--add--tab-options">{{ $t('program.add_program_action.fields.options.title') }}</v-tab>
        <v-tab key="webhooks" class="smoke-card-program--add--tab-webhooks">{{ $t('program.add_program_action.fields.webhooks.title') }}</v-tab>

        <v-tab-item key="options">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('program.add_program_action.fields.options.title') }}</v-label>
              <div>
                <v-checkbox v-model="cardProgram.options.allow_open_wallet_unload" :label="$t('program.add_program_action.fields.options.allow_open_wallet_unload')" class="smoke-card-program--add--allow_open_wallet_unload"></v-checkbox>
                <v-checkbox v-model="cardProgram.options.allow_partial_authorization" :label="$t('program.add_program_action.fields.options.allow_partial_authorization')" class="smoke-card-program--add--allow_partial_auth"></v-checkbox>
                <v-checkbox v-model="cardProgram.options.exclude_padding_amount" :label="$t('program.add_program_action.fields.options.exclude_padding_amount')" class="smoke-card-program--add--exclude_padding_amount"></v-checkbox>
              </div>
            </v-flex>

            <v-flex sm6 xs12 class="section-pad-top">
              <v-label>{{ $t('program.add_program_action.fields.limits.title') }}</v-label>
              <v-text-field
                name="cardProgram.limits.minimum_load_amount"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.limits.minimum_load_amount"
                :label="$t('program.add_program_action.fields.limits.minimum_load_amount')"
                class="smoke-card-program--add--limit--minimum_load_amount"
                :error-messages="errors.collect('cardProgram.limits.minimum_load_amount')"
                data-vv-name="cardProgram.limits.minimum_load_amount"
                :data-vv-as="$t('program.add_program_action.fields.limits.minimum_load_amount')"
                v-validate="'required|min_value:0'"
                required
              >
              </v-text-field>
              <v-text-field
                name="cardProgram.limits.minimal_authorization_amount"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.limits.minimal_authorization_amount"
                :label="$t('program.add_program_action.fields.limits.minimal_authorization_amount')"
                class="smoke-card-program--add--limit-minimal_authorization_amount"
                :error-messages="errors.collect('cardProgram.limits.minimal_authorization_amount')"
                data-vv-name="cardProgram.limits.minimal_authorization_amount"
                :data-vv-as="$t('program.add_program_action.fields.limits.minimal_authorization_amount')"
                v-validate="'required|min_value:0'"
                required
              >
              </v-text-field>
              <v-text-field
                name="cardProgram.limits.card_max_balance"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.limits.card_max_balance"
                :label="$t('program.add_program_action.fields.limits.card_max_balance')"
                class="smoke-card-program--add--limit-card_max_balance"
                :error-messages="errors.collect('cardProgram.limits.card_max_balance')"
                data-vv-name="cardProgram.limits.card_max_balance"
                :data-vv-as="$t('program.add_program_action.fields.limits.card_max_balance')"
                v-validate="'required|min_value:0'"
                required
              >
              </v-text-field>
              <v-text-field
                name="cardProgram.limits.card_upper_max_balance"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.limits.card_upper_max_balance"
                :label="$t('program.add_program_action.fields.limits.card_upper_max_balance')"
                class="smoke-card-program--add--limit-card_upper_max_balance"
                :error-messages="errors.collect('cardProgram.limits.card_upper_max_balance')"
                data-vv-name="cardProgram.limits.card_upper_max_balance"
                :data-vv-as="$t('program.add_program_action.fields.limits.card_upper_max_balance')"
                v-validate="'required|min_value:0'"
                required
              >
              </v-text-field>
              <v-text-field
                name="cardProgram.limits.open_wallet_max_balance"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.limits.open_wallet_max_balance"
                :label="$t('program.add_program_action.fields.limits.open_wallet_max_balance')"
                class="smoke-card-program--add--limit-open_wallet_max_balance"
                :error-messages="errors.collect('cardProgram.limits.open_wallet_max_balance')"
                data-vv-name="cardProgram.limits.open_wallet_max_balance"
                :data-vv-as="$t('program.add_program_action.fields.limits.open_wallet_max_balance')"
                v-validate="'required|min_value:0'"
                required
              >
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex sm12>
              <v-label>{{ $t('program.add_program_action.fields.velocity.title') }}</v-label>
            </v-flex>
            <v-flex sm6 xs12>
              <v-text-field
                name="cardProgram.velocity.load_daily_maximum_amount"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.velocity.load_daily_maximum_amount"
                :label="$t('program.add_program_action.fields.velocity.load_daily_maximum_amount')"
                class="smoke-card-program--add--velocity_load_daily_maximum_amount"
                :error-messages="errors.collect('cardProgram.velocity.load_daily_maximum_amount')"
                data-vv-name="cardProgram.velocity.load_daily_maximum_amount"
                :data-vv-as="$t('program.add_program_action.fields.velocity.load_daily_maximum_amount')"
                v-validate="'required'"
                required
              >
              </v-text-field>
            </v-flex>
            <v-flex sm6 xs12>
              <v-text-field
                name="cardProgram.velocity.authorization_daily_maximum_amount"
                append-icon="fas fa-dollar-sign"
                v-model="cardProgram.velocity.authorization_daily_maximum_amount"
                :label="$t('program.add_program_action.fields.velocity.authorization_daily_maximum_amount')"
                class="smoke-card-program--add--velocity_authorization_daily_maximum_amount"
                :error-messages="errors.collect('cardProgram.velocity.authorization_daily_maximum_amount')"
                data-vv-name="cardProgram.velocity.authorization_daily_maximum_amount"
                :data-vv-as="$t('program.add_program_action.fields.limits.minimal_authorization_amount')"
                v-validate="'required'"
                required
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="webhooks" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('program.add_program_action.fields.webhooks.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="cardProgram.webhooks.authorization_alert.active" :label="$t('program.add_program_action.fields.webhooks.authorization_alert')" class="smoke-card-program--add--webhooks_authorization_alert_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.authorization_alert.uri"
                v-model="cardProgram.webhooks.authorization_alert.uri"
                :label="$t('program.add_program_action.fields.webhooks.uri')"
                class="smoke-card-program--add--webhooks_authorization_alert_uri"
                :error-messages="errors.collect('cardProgram.webhooks.authorization_alert.uri')"
                data-vv-name="cardProgram.webhooks.authorization_alert.uri"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.uri')"
                :disabled="webhooksAuthorizationAlertDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.authorization_alert.authorization"
                v-model="cardProgram.webhooks.authorization_alert.authorization"
                :label="$t('program.add_program_action.fields.webhooks.authorization')"
                class="smoke-card-program--add--webhooks_authorization_alert_authorization"
                :error-messages="errors.collect('cardProgram.webhooks.authorization_alert.authorization')"
                data-vv-name="webhooks.authorization_alert.authorization"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.authorization')"
                :disabled="webhooksAuthorizationAlertDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox
                v-model="cardProgram.webhooks.card_application_process.active"
                :label="$t('program.add_program_action.fields.webhooks.card_application_process')"
                class="smoke-card-program--add--webhooks_card_application_process_check"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.card_application_process.uri"
                v-model="cardProgram.webhooks.card_application_process.uri"
                :label="$t('program.add_program_action.fields.webhooks.uri')"
                class="smoke-card-program--add--webhooks_card_application_process_uri"
                :error-messages="errors.collect('cardProgram.webhooks.card_application_process.uri')"
                data-vv-name="cardProgram.webhooks.card_application_process.uri"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.uri')"
                :disabled="webhooksCardApplicationProcessDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.card_application_process.authorization"
                v-model="cardProgram.webhooks.card_application_process.authorization"
                :label="$t('program.add_program_action.fields.webhooks.authorization')"
                class="smoke-card-program--add--webhooks_card_application_process_authorization"
                :error-messages="errors.collect('cardProgram.webhooks.card_application_process.authorization')"
                data-vv-name="cardProgram.webhooks.card_application_process.authorization"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.authorization')"
                :disabled="webhooksCardApplicationProcessDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12> <v-checkbox v-model="cardProgram.webhooks.card_activity.active" :label="$t('program.add_program_action.fields.webhooks.card_activity')" class="smoke-card-program--add--webhooks_card_activity_check"></v-checkbox> </v-flex>
            <v-flex xs12>
              <v-text-field
                name="webhooks.card_activity.uri"
                v-model="cardProgram.webhooks.card_activity.uri"
                :label="$t('program.add_program_action.fields.webhooks.uri')"
                class="smoke-card-program--add--webhooks_card_activity_uri"
                :error-messages="errors.collect('cardProgram.webhooks.card_activity.uri')"
                data-vv-name="cardProgram.webhooks.card_activity.uri"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.uri')"
                :disabled="webhooksCardActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.card_activity.authorization"
                v-model="cardProgram.webhooks.card_activity.authorization"
                :label="$t('program.add_program_action.fields.webhooks.authorization')"
                class="smoke-card-program--add--webhooks_card_activity_authorization"
                :error-messages="errors.collect('cardProgram.webhooks.card_activity.authorization')"
                data-vv-name="cardProgram.webhooks.card_activity.authorization"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.authorization')"
                :disabled="webhooksCardActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox
                v-model="cardProgram.webhooks.debits_and_credits_activity.active"
                :label="$t('program.add_program_action.fields.webhooks.debits_and_credits_activity')"
                class="smoke-card-program--add--webhooks_debits_and_credits_activity_check"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.debits_and_credits_activity.uri"
                v-model="cardProgram.webhooks.debits_and_credits_activity.uri"
                :label="$t('program.add_program_action.fields.webhooks.uri')"
                class="smoke-card-program--add--webhooks_debits_and_credits_activity_uri"
                :error-messages="errors.collect('cardProgram.webhooks.debits_and_credits_activity.uri')"
                data-vv-name="cardProgram.webhooks.debits_and_credits_activity.uri"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.uri')"
                :disabled="webhooksDebitsAndCreditsActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.debits_and_credits_activity.authorization"
                v-model="cardProgram.webhooks.debits_and_credits_activity.authorization"
                :label="$t('program.add_program_action.fields.webhooks.authorization')"
                class="smoke-card-program--add--webhooks_debits_and_credits_activity_authorization"
                :error-messages="errors.collect('cardProgram.webhooks.debits_and_credits_activity.authorization')"
                data-vv-name="cardProgram.webhooks.debits_and_credits_activity.authorization"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.authorization')"
                :disabled="webhooksDebitsAndCreditsActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="cardProgram.webhooks.transaction_activity.active" :label="$t('program.add_program_action.fields.webhooks.transaction_activity')" class="smoke-card-program--add--webhooks_transactions_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.transaction_activity.uri"
                v-model="cardProgram.webhooks.transaction_activity.uri"
                :label="$t('program.add_program_action.fields.webhooks.uri')"
                class="smoke-card-program--add--webhooks_transactions_activity_uri"
                :error-messages="errors.collect('cardProgram.webhooks.transaction_activity.uri')"
                data-vv-name="cardProgram.webhooks.transaction_activity.uri"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.uri')"
                :disabled="webhooksTransactionActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="cardProgram.webhooks.transaction_activity.authorization"
                v-model="cardProgram.webhooks.transaction_activity.authorization"
                :label="$t('program.add_program_action.fields.webhooks.authorization')"
                class="smoke-card-program--add--webhooks_transactions_activity_authorization"
                :error-messages="errors.collect('cardProgram.webhooks.transaction_activity.authorization')"
                data-vv-name="cardProgram.webhooks.transaction_activity.authorization"
                :data-vv-as="$t('program.add_program_action.fields.webhooks.authorization')"
                :disabled="webhooksTransactionActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'add-program-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      cardProgram: emptyCardProgram(),
      programTypes: [
        { label: this.$t('program.add_program_action.fields.type.options.instant'), value: 'instant' },
        { label: this.$t('program.add_program_action.fields.type.options.gpr'), value: 'gpr' },
      ],
    };
  },
  computed: {
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  methods: {
    resetForm() {
      this.cardProgram = emptyCardProgram();
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'program/createCardProgram',
            name: 'addProgram',
            success: this.success,
          },
          { cardProgram: this.cardProgram },
        );
      }
      return await true;
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    webhooksAuthorizationAlertDisabled() {
      return !this.cardProgram.webhooks.authorization_alert.active;
    },
    webhooksCardApplicationProcessDisabled() {
      return !this.cardProgram.webhooks.card_application_process.active;
    },
    webhooksCardActivityDisabled() {
      return !this.cardProgram.webhooks.card_activity.active;
    },
    webhooksDebitsAndCreditsActivityDisabled() {
      return !this.cardProgram.webhooks.debits_and_credits_activity.active;
    },
    webhooksTransactionActivityDisabled() {
      return !this.cardProgram.webhooks.transaction_activity.active;
    },
  },
};

function emptyCardProgram() {
  return {
    id: '',
    name: '',
    currency: '',
    gps: {
      iss_code: '',
    },
    options: {
      allow_open_wallet_unload: false,
      allow_partial_authorization: false,
      exclude_padding_amount: false,
      pad_mapping_required: false,
    },
    limits: {
      minimum_load_amount: null,
      minimal_authorization_amount: null,
      card_max_balance: null,
      card_upper_max_balance: null,
      open_wallet_max_balance: null,
    },
    velocity: {
      load_daily_maximum_amount: null,
      authorization_daily_maximum_amount: null,
    },
    webhooks: {
      card_application_process: {
        active: false,
        uri: '',
        authorization: '',
      },
      card_activity: {
        active: false,
        uri: '',
        authorization: '',
      },
      debits_and_credits_activity: {
        active: false,
        uri: '',
        authorization: '',
      },
      transaction_activity: {
        active: false,
        uri: '',
        authorization: '',
      },
      authorization_alert: {
        active: false,
        uri: '',
        authorization: '',
      },
    },
  };
}
</script>
