<template>
  <v-btn :loading="loading" outline color="success" v-on="listeners">{{ text }}</v-btn>
</template>

<script>
export default {
  name: 'button-success',
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>
