import Vue from 'vue';

const service = {
  async listNoteCategories() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/note-categories`);
    return response.data.data.items;
  },
};

export default service;
