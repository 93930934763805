<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('group.update_group_action.title')"
    context="addGroup"
    @submit="submit"
    @cancel="cancel"
    cancel_text="group.update_group_action.button_cancel"
    submit_text="group.update_group_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm12 xs12>
          <v-text-field
            name="id"
            v-model="currentGroup.id"
            v-validate="'required|max:50'"
            :label="$t('group.update_group_action.fields.id')"
            :error-messages="errors.collect('id')"
            data-vv-name="id"
            :data-vv-as="$t('group.update_group_action.fields.id')"
            required
            counter
            maxlength="50"
            class="update-group--id"
          />
          <v-text-field
            name="name"
            v-model="currentGroup.name"
            v-validate="'required|max:50'"
            :label="$t('group.update_group_action.fields.name')"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            :data-vv-as="$t('group.update_group_action.fields.name')"
            required
            counter
            maxlength="50"
            class="update-group--name"
          />
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex sm12 xs12>
          <section class="checkboxes-indented-with-heading">
            <v-label>{{ $t('group.update_group_action.fields.permissions') }}</v-label>
            <div v-if="permissions" class="edit-permissions-action--permissions">
              <v-layout row wrap>
                <v-checkbox
                  style="width: 50%"
                  v-for="(permission, index) in permissions"
                  :key="index"
                  v-model="selectedPermissions"
                  :label="$t(`program.permissions.${permission}`)"
                  :value="permission"
                  :error-messages="errors.collect('permissions')"
                  data-vv-name="permissions"
                  :data-vv-as="$t('group.update_group_action.fields.permissions')"
                  @change="addToSelectedPermissions(permission)"
                />
              </v-layout>
            </div>
          </section>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';
import _includes from 'lodash/includes';
import { applyCardProgramPermissionPolicy } from '@/utils/ui/card-program-permissions-policy';

export default {
  name: 'update-group-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  props: {
    group: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentGroup: {},
      selectedPermissions: this.group.permissions,
    };
  },
  computed: {
    ...mapGetters('program', ['permissions']),
    ...mapGetters('group', ['getGroup']),
    isFormChanged() {
      // TODO: Problem: like the other isFormChanged methods, this is imperfect as it doesn't refire if values reset
      const textFieldChanged = Object.keys(this.fields).some((key) => this.fields[key].changed);
      const permissionsChanged = this.selectedPermissions === this.group.permissions ? false : true;

      if (textFieldChanged || permissionsChanged) {
        return true;
      }

      return false;
    },
  },
  watch: {
    group(val) {
      this.currentGroup = _.cloneDeep(val);
      this.selectedPermissions = val.permissions;
    },
  },
  methods: {
    resetForm() {
      this.currentGroup = _.cloneDeep(this.getGroup(this.group.id));
    },
    addToSelectedPermissions(permission) {
      let value;
      _includes(this.selectedPermissions, permission) ? (value = true) : (value = false);
      applyCardProgramPermissionPolicy(permission, value, this.selectedPermissions);
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.$validator.reset();
      this.$emit('action');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'group/updateGroup',
            name: 'updateGroup',
            success: this.success,
          },
          {
            id: this.group.id,
            group: {
              name: this.currentGroup.name,
              permissions: this.selectedPermissions,
            },
          },
        );
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.layout-for-columns {
  @media screen and (min-width: 600px) {
    display: flex;
    gap: 2em;
  }
}
.column-one {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 50%;
  }
}
.column-two {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 50%;
  }
}
</style>
