import Vue from 'vue';

const service = {
  async searchBrandings(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/brandings/search', page, limit, params)}`);
    return response.data.data;
  },
  async listBrandings(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/brandings', page, limit)}`);
    return response.data.data.items;
  },
};

function generatePath(path, page, limit, { keyword }) {
  if (page || limit || keyword) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
