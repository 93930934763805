<template>
  <div class="label-text">
    <div v-if="actionData">
      <h5>{{ label }}</h5>
      <p class="label-text--action-wrap">
        <span v-if="htmlValue" v-html="htmlValue">{{ htmlValue }}</span>
        <span v-else
          ><span>{{ (value || htmlValue) | orNotAvailable }}</span> <span class="label-text--action" @click="showForm">{{ $t(actionData.text) }}</span></span
        >
      </p>
      <slot name="labelTextForm" v-if="showLabelTextForm"></slot>
    </div>

    <div v-else>
      <h5 v-bind:class="[classArray]">{{ label }}</h5>
      <slot name="labelTextLink">
        <p v-if="htmlValue" v-html="htmlValue" class="label-text--value" v-bind:class="[classArray, isNotAvailable]" :title="title">
          {{ htmlValue | orNotAvailable }}
        </p>
        <p v-else class="label-text--value" v-bind:class="[classArray, isNotAvailable]" :title="title">{{ value | orNotAvailable }}</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'label-text',
  props: {
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    htmlValue: {
      required: false,
    },
    actionData: {
      type: Object,
      required: false,
    },
    classArray: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      showLabelTextForm: this.actionData ? this.actionData.showLabelTextForm : null,
    };
  },
  watch: {
    actionData() {
      this.showLabelTextForm = this.actionData ? this.actionData.showLabelTextForm : null;
    },
  },
  computed: {
    classes() {
      return {
        ...this.$classes,
      };
    },
    isNotAvailable() {
      const currentValue = this.value || this.htmlValue;
      if (this.$options.filters.orNotAvailable(currentValue) === currentValue) {
        return;
      }
      return 'label-text-na';
    },
  },
  methods: {
    showForm() {
      this.showLabelTextForm = !this.showLabelTextForm;
    },
  },
};
</script>
