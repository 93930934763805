import Vue from 'vue';
import _ from 'lodash';
import { safeDispatcher } from '../../utils/context/context-helper';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    currentProgram: {},
    programs: [],
    pageNumber: defaultPage,
    pageCount: 0,
    permissions: [
      'view_application',
      'manage_application',
      'comment_application',
      'upload_document_application',
      'view_card',
      'manage_card',
      'comment_card',
      'upload_document_card',
      'view_wallet_definition',
      'view_merchant',
      'manage_merchant',
      'view_offer',
      'manage_offer',
      'view_reward',
      'manage_reward',
      'view_alert',
      'manage_alert',
      'manage_aml_program',
      'internal',
      'view_task',
      'aml_officer',
      'beta',
      'dev',
      'manage_instant_card',
      'approve_instant_card_batch',
    ],
    notifications: ['fraud_monitoring', 'real_time', 'application', 'card', 'aml'],
  },

  getters: {
    programNameAndKeys: (state) => {
      return state.programs.map((program) => {
        return _.pick(program, ['name', 'key']);
      });
    },
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
    permissions: (state) => _.sortBy(state.permissions),
    notifications: (state) => state.notifications,
    currentProgram: (state) => state.currentProgram,
    programs: (state) => state.programs,
    getLocalProgramByKey: (state) => (key) => state.programs.find((cardProgram) => cardProgram.key === key),
  },

  mutations: {
    program(state, program) {
      state.program = program;
    },
    programs(state, programs) {
      state.programs = programs;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listPrograms({ commit }, { page, limit }) {
      const programService = Vue.prototype.$services.cardProgram;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const programList = await programService.listPrograms(thePage, theLimit, limit);

      commit('page', thePage);
      commit('pageCount', programList.page_count);
      commit('programs', programList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('program/listPrograms', { page });
    },

    async getProgram({ commit }, { key }) {
      const programService = Vue.prototype.$services.cardProgram;

      const program = await programService.getProgram(key);
      commit('program', program);
    },

    async updateCardProgram({ dispatch }, { cardProgram }) {
      const programService = Vue.prototype.$services.cardProgram;
      const defaultDispatch = safeDispatcher(dispatch);

      const { key } = cardProgram;
      delete cardProgram.key;
      delete cardProgram.type;
      delete cardProgram.options.cardholder_kyc;
      delete cardProgram.options.open_wallet;

      await programService.updateCardProgram(key, cardProgram);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'program.update_program_action.success' });
      await defaultDispatch('program/listPrograms', {});
    },

    async createCardProgram({ dispatch }, { cardProgram }) {
      const notEmptyString = (value) => value !== '';
      const programService = Vue.prototype.$services.cardProgram;
      const defaultDispatch = safeDispatcher(dispatch);

      cardProgram.webhooks.card_application_process = _.pickBy(cardProgram.webhooks.card_application_process, notEmptyString);
      cardProgram.webhooks.card_activity = _.pickBy(cardProgram.webhooks.card_activity, notEmptyString);
      cardProgram.webhooks.debits_and_credits_activity = _.pickBy(cardProgram.webhooks.debits_and_credits_activity, notEmptyString);
      cardProgram.webhooks.transaction_activity = _.pickBy(cardProgram.webhooks.transaction_activity, notEmptyString);
      cardProgram.webhooks.authorization_alert = _.pickBy(cardProgram.webhooks.authorization_alert, notEmptyString);

      await programService.createCardProgram(cardProgram);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'program.add_program_action.success' });
      await defaultDispatch('program/listPrograms', {});
    },
  },
};
