<template>
  <v-flex>
    <h1 hidden>{{ $t('subscription_plan.list.title') }}</h1>

    <v-card class="form-basic pa-2">
      <button-primary data-test="add-subscription-plan" :loading="working" :text="$t('subscription_plan.form.add')" @click="showModalAddSubscriptionPlan" />
    </v-card>

    <modal-action :show-modal="showAddSubscriptionPlan" :avoid-rendering="true">
      <add-subscription-plan-action @close="closeModalAddSubscriptionPlan" @action="reloadPage" />
    </modal-action>

    <v-flex class="data-section">
      <subscription-plan-list v-if="dataReady && subscriptionPlans" :items="subscriptionPlans" />
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

import SubscriptionPlanList from './SubscriptionPlanList.vue';
import AddSubscriptionPlanAction from './AddSubscriptionPlanAction.vue';

export default {
  name: 'view-subscription-plan-form',

  mixins: [actionErrorTrackable, security],
  components: { SubscriptionPlanList, AddSubscriptionPlanAction },

  $_veeValidate: {
    validator: 'new',
  },

  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddSubscriptionPlan: false,
    };
  },

  computed: {
    ...mapGetters('subscriptionPlan', ['subscriptionPlans']),
  },

  async created() {
    this.dataReady = false;
    await this.executeAction({ action: 'partner/listPartners', success: this.success }, {});
    await this.executeAction({ action: 'subscriptionPlan/listSubscriptionPlans', success: this.success }, {});
    this.dataReady = true;
  },

  methods: {
    showModalAddSubscriptionPlan() {
      this.showAddSubscriptionPlan = true;
    },

    closeModalAddSubscriptionPlan() {
      this.showAddSubscriptionPlan = false;
    },

    reloadPage() {
      this.showAddSubscriptionPlan = false;
    },
  },
};
</script>
