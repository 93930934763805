import Vue from 'vue';
import _ from 'lodash';

export default {
  namespaced: true,

  state: {
    noteCategories: [],
  },

  getters: {
    noteCategories: (state) => state.noteCategories,
  },

  mutations: {
    noteCategories(state, categories) {
      state.noteCategories = _.sortBy(categories, ['name.en']);
    },
  },

  actions: {
    async listNoteCategories({ commit }) {
      const service = Vue.prototype.$services.amlNoteCategory;
      const noteCategories = await service.listNoteCategories();

      commit('noteCategories', noteCategories);
    },
  },
};
