<template>
  <div>
    <a role="button" class="button-subtle" @click="action" v-if="!isGlobalErrorActive"><slot></slot></a> <span class="is-disabled" v-if="isGlobalErrorActive"><slot></slot></span>
  </div>
</template>

<script>
import disabler from '@/mixins/disable-with-error';

export default {
  name: 'link-secondary-action',
  mixins: [disabler],
  methods: {
    action(event) {
      this.$emit('click', event);
    },
  },
};
</script>
