<template>
  <card icon="fal fa-user" :title="`${$t('space.list.title')}`">
    <template slot="headerZoneRight"> </template>

    <template slot="content">
      <space-table :items="items"></space-table>
    </template>
  </card>
</template>

<script>
import SpaceTable from './SpaceTable';

export default {
  name: 'space-list',
  components: { SpaceTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
