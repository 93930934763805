<template>
  <view-main title="page_title.partner">
    <view-subscription-plan-form slot="content" />
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';

import ViewSubscriptionPlanForm from './ViewSubscriptionPlanForm.vue';

export default {
  name: 'subscription-plan-view',

  mixins: [renderErrorTrackable],

  components: { ViewSubscriptionPlanForm },
};
</script>
