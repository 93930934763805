<template>
  <v-flex>
    <h1 hidden>{{ $t('note_type.list.title') }}</h1>

    <v-card class="form-basic pa-2">
      <button-primary class="smoke-note-type--btn-add" :loading="working" :text="$t('note_type.form.add')" @click="showModalAddNoteType"></button-primary>
    </v-card>

    <modal-action :show-modal="showAddNoteTypeModal">
      <add-note-type-action @close="closeModalAddNoteType" @action="reloadPage"></add-note-type-action>
    </modal-action>

    <v-flex class="data-section">
      <note-type-list v-if="dataReady && noteTypes" :items="noteTypes"></note-type-list>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddNoteTypeAction from './AddNoteTypeAction';
import NoteTypeList from './NoteTypeList';

export default {
  name: 'view-note-type-form',
  mixins: [actionErrorTrackable, security],
  components: { AddNoteTypeAction, NoteTypeList },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddNoteTypeModal: false,
    };
  },
  computed: {
    ...mapGetters('noteType', ['noteTypes']),
  },
  async created() {
    await this.fetchNoteTypes();
  },
  methods: {
    async fetchNoteTypes() {
      this.dataReady = false;
      await this.executeAction({ action: 'noteType/listNoteTypes', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddNoteType() {
      this.showAddNoteTypeModal = true;
    },
    closeModalAddNoteType() {
      this.showAddNoteTypeModal = false;
    },
    reloadPage() {
      this.showAddNoteTypeModal = false;
    },
  },
};
</script>
