<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('package.add_package_action.title')"
    context="addPackage"
    @submit="submit"
    @cancel="cancel"
    cancel_text="package.add_package_action.button_cancel"
    submit_text="package.add_package_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            name="id"
            v-model="id"
            v-validate="'required|max:30'"
            :label="$t('package.add_package_action.fields.id')"
            :error-messages="errors.collect('id')"
            data-vv-name="id"
            :data-vv-as="$t('package.add_package_action.fields.id')"
            required
            counter
            maxlength="30"
            class="add-package--id"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="name"
            v-model="name"
            v-validate="'required|max:30'"
            :label="$t('package.add_package_action.fields.name')"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            :data-vv-as="$t('package.add_package_action.fields.name')"
            required
            counter
            maxlength="30"
            class="add-package--name"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-select :items="programNameAndKeys" v-model="program" item-text="name" item-value="key" :label="dropdownLabel" class="add-package--program" />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="card_design"
            v-model="card_design"
            v-validate="'required|max:50'"
            :label="$t('package.add_package_action.fields.card_design')"
            :error-messages="errors.collect('card_design')"
            data-vv-card_design="card_design"
            :data-vv-as="$t('package.add_package_action.fields.card_design')"
            required
            counter
            maxlength="8"
            class="add-package--card_design"
          />
        </v-flex>

        <v-flex sm6 xs12>
          <v-text-field
            name="logo_front_id"
            v-model="logo_front_id"
            v-validate="'max:30'"
            :label="$t('package.add_package_action.fields.logo_front_id')"
            :error-messages="errors.collect('logo_front_id')"
            data-vv-logo_front_id="logo_front_id"
            :data-vv-as="$t('package.add_package_action.fields.logo_front_id')"
            maxlength="30"
            class="add-package--logo_front_id"
          />
        </v-flex>

        <v-flex sm6 xs12>
          <v-text-field
            name="logo_back_id"
            v-model="logo_back_id"
            v-validate="'max:30'"
            :label="$t('package.add_package_action.fields.logo_back_id')"
            :error-messages="errors.collect('logo_back_id')"
            data-vv-logo_back_id="logo_back_id"
            :data-vv-as="$t('package.add_package_action.fields.logo_back_id')"
            maxlength="30"
            class="add-package--logo_back_id"
          />
        </v-flex>

        <v-flex sm6 xs12>
          <v-text-field
            name="product_ref"
            v-model="product_ref"
            v-validate="'required|max:50'"
            :label="$t('package.add_package_action.fields.product_ref')"
            :error-messages="errors.collect('product_ref')"
            data-vv-product_ref="product_ref"
            :data-vv-as="$t('package.add_package_action.fields.product_ref')"
            required
            counter
            maxlength="50"
            class="add-package--product_ref"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="carrier_type"
            v-model="carrier_type"
            v-validate="'required|max:50'"
            :label="$t('package.add_package_action.fields.carrier_type')"
            :error-messages="errors.collect('carrier_type')"
            data-vv-carrier_type="carrier_type"
            :data-vv-as="$t('package.add_package_action.fields.carrier_type')"
            required
            counter
            maxlength="30"
            class="add-package--carrier_type"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="card_product_id"
            v-model="card_product_id"
            v-validate="'max:50'"
            :label="$t('package.add_package_action.fields.card_product_id')"
            :error-messages="errors.collect('card_product_id')"
            data-vv-card_product_id="card_product_id"
            :data-vv-as="$t('package.add_package_action.fields.card_product_id')"
            counter
            maxlength="50"
            class="add-package--card_product_id"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-checkbox v-model="bulk" :label="$t('package.add_package_action.fields.bulk')" :error-messages="errors.collect('bulk')" data-vv-bulk="bulk" :data-vv-as="$t('package.add_package_action.fields.bulk')" />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-package-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      dataReady: false,
      id: '',
      name: '',
      program: '',
      card_design: '',
      product_ref: '',
      carrier_type: '',
      card_product_id: '',
      logo_front_id: '',
      logo_back_id: '',
      dropdownLabel: this.$t('package.add_package_action.fields.programs'),
      bulk: false,
    };
  },
  computed: {
    ...mapGetters('program', ['programNameAndKeys']),
    isFormChanged() {
      return this.programSelected ? true : false;
    },
    programSelected() {
      return this.program && this.program !== this.dropdownLabel;
    },
  },
  async created() {
    await this.fetchPrograms();
  },
  methods: {
    resetForm() {
      this.dataReady = false;
      this.id = '';
      this.name = '';
      this.program = this.dropdownLabel;
      this.card_design = '';
      this.product_ref = '';
      this.carrier_type = '';
      this.card_product_id = '';
      this.logo_front_id = '';
      this.logo_back_id = '';
      this.bulk = false;
    },
    async submit() {
      if (await this.validationComplete()) {
        const payload = {
          id: this.id,
          name: this.name,
          card_program_key: this.program,
          card_product_id: this.card_product_id,
          gps: {
            logo_back_id: this.logo_back_id,
            logo_front_id: this.logo_front_id,
            card_design: this.card_design,
            product_ref: this.product_ref,
            carrier_type: this.carrier_type,
          },
          bulk: this.bulk,
        };

        await this.executeAction(
          {
            action: 'package/createPackage',
            name: 'addPackage',
            success: this.success,
          },
          payload,
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async fetchPrograms() {
      this.dataReady = false;
      await this.executeAction(
        {
          action: 'program/listPrograms',
          success: this.success,
        },
        {},
      );
      this.dataReady = true;
    },
  },
};
</script>
