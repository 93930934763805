<template>
  <div>
    <table class="packages-table responsive-table smoke-partner--list-table">
      <thead>
        <tr>
          <td>{{ $t('partner.listTable.id') }}</td>
          <td>{{ $t('partner.listTable.name') }}</td>
          <td>{{ $t('partner.listTable.region') }}</td>
          <td>{{ $t('partner.listTable.timezone') }}</td>
          <td>{{ $t('partner.listTable.business_id') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="smoke-partner--list-table--id-column">
            <h5>{{ $t('partner.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td class="smoke-partner--list-table--name-column">
            <h5>{{ $t('partner.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td class="smoke-partner--list-table--name-column">
            <h5>{{ $t('partner.listTable.region') }}:</h5>
            <span :class="{ 'info-not-available': !item.region }">{{ item.region | orNone }}</span>
          </td>
          <td class="smoke-partner--list-table--name-column">
            <h5>{{ $t('partner.listTable.timezone') }}:</h5>
            <span :class="{ 'info-not-available': !item.timezone }">{{ item.timezone | orNone }}</span>
          </td>
          <td class="smoke-partner--list-table--business-column">
            <h5>{{ $t('partner.listTable.business_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.business_id }">{{ item.business_id | orNone }}</span>
          </td>
          <td class="smoke-partner--list-table--actions-column">
            <v-btn align-bottom small outline color="primary" @click="modalOpen(item)" class="smoke-partner--list-table--edit-button">{{ $t(`partner.listTable.edit`) }}</v-btn>
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdatePartnerModal" avoid-rendering> <update-partner-action :partner="currentPartner" @close="closeModalUpdatePartner" @action="reloadPage" /> </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="partners-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdatePartnerAction from './UpdatePartnerAction';
import { mapGetters } from 'vuex';

export default {
  name: 'partners-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdatePartnerAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPartner: {},
      pagination: 1,
      showUpdatePartnerModal: false,
    };
  },
  computed: {
    ...mapGetters('partner', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'partner/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showUpdatePartnerModal = false;
    },
    passPartnerToModal(cardPartner) {
      this.currentPartner = cardPartner;
    },
    modalOpen(item) {
      this.showModalUpdatePartner();
      setTimeout(() => {
        this.passPartnerToModal(item);
      }, 1);
    },
    showModalUpdatePartner() {
      this.showUpdatePartnerModal = true;
    },
    closeModalUpdatePartner() {
      this.showUpdatePartnerModal = false;
      this.currentPartner = {};
    },
  },
};
</script>
