<template>
  <v-alert class="error-banner" v-model="show" type="error" transition="scale-transition" outline icon="fa-exclamation-square">
    <v-layout>
      <h2>{{ text }}</h2>
    </v-layout>
  </v-alert>
</template>

<script>
export default {
  name: 'alert-normal-error',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
