<template>
  <div>
    <table class="packages-table responsive-table smoke-partner--list-table">
      <thead>
        <tr>
          <td>{{ $t('subscription_plan.listTable.id') }}</td>
          <td>{{ $t('subscription_plan.listTable.name') }}</td>
          <td>{{ $t('subscription_plan.listTable.partner_id') }}</td>
          <td>{{ $t('subscription_plan.listTable.monthly_fee') }}</td>
          <td>{{ $t('subscription_plan.listTable.status') }}</td>
          <td />
          <td />
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td data-test="subscription-plan-list-id">
            <h5>{{ $t('subscription_plan.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>

          <td data-table="subscription-plan-list-name">
            <h5>{{ $t('subscription_plan.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>

          <td data-table="subscription-plan-list-partner">
            <h5>{{ $t('subscription_plan.listTable.partner_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.partner_id }">{{ item.partner_id | orNone }}</span>
          </td>

          <td data-table="subscription-plan-list-partner">
            <h5>{{ $t('subscription_plan.listTable.monthly_fee') }}:</h5>
            <span :class="{ 'info-not-available': !item.monthly_fee }">{{ item.monthly_fee | currency | dollarSignI18n }}</span>
          </td>

          <td data-table="subscription-plan-list-status" v-if="item.status">
            <h5>{{ $t('subscription_plan.listTable.status') }}:</h5>
            {{ item.status }}
          </td>

          <td data-table="subscription-plan-list-product" v-if="item.stripe_product_id">
            <a :href="createEditProductLink(item)" target="_blank"> {{ $t('subscription_plan.listTable.edit_stripe_product') }}</a>
          </td>

          <td data-table="subscription-plan-list-monthly-price" v-if="item.stripe_subscription_price_id">
            <a :href="createEditPriceLink(item.stripe_subscription_price_id)" target="_blank">{{ $t('subscription_plan.listTable.edit_stripe_monthly_price') }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="subscription-plans-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
// import UpdatePartnerAction from './UpdatePartnerAction';

export default {
  name: 'subscription-plan-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('subscriptionPlan', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'subscriptionPlan/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    createEditProductLink({ stripe_product_id }) {
      return `${process.env.VUE_APP_STRIPE_BASE_URL}/products/${stripe_product_id}`;
    },
    createEditPriceLink(price_id) {
      return `${process.env.VUE_APP_STRIPE_BASE_URL}/prices/${price_id}`;
    },
  },
};
</script>
