<template>
  <div>
    <table class="apps-table responsive-table">
      <thead>
        <tr>
          <td>{{ $t('app.listTable.name') }}</td>
          <td>{{ $t('app.listTable.api_key') }}</td>
          <td>{{ $t('app.listTable.partner_id') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="apps-table--name">
            <h5>{{ $t('app.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('app.listTable.api_key') }}:</h5>
            <span :class="{ 'info-not-available': !item.api_key }">{{ item.api_key | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('app.listTable.partner_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.partner_id }">{{ item.partner_id | orNone }}</span>
          </td>
          <td>
            <button
              @click="
                passAppToModal(item);
                showModalUpdateApp();
              "
              class="apps-table--edit-app"
            >
              <v-icon>fas fa-pencil</v-icon>
            </button>
            <button
              @click="
                passAppToModal(item);
                showModalDeleteApp();
              "
              class="apps-table--delete-app"
            >
              <v-icon>fas fa-trash-alt</v-icon>
            </button>
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passAppToModal(item);
                showModalGetApiSecret();
              "
              class="apps-table--show-secret"
              >{{ $t('app.listTable.show_secret') }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdateAppModal"> <update-app-action :app="currentApp" @close="closeModalUpdateApp" @action="reloadPage" /> </modal-action>
      <modal-action :show-modal="showGetApiSecretModal"> <get-api-secret-action :app-key="currentAppKey" @close="closeModalGetApiSecret" @action="reloadPage" :visible="showGetApiSecretModal" /> </modal-action>
      <modal-confirm
        :show-modal="showDeleteAppModal"
        :title="$t('app.delete_app_action.title')"
        context="appDeleteApp"
        @submit="deleteApp()"
        @close="closeModalDeleteApp"
        :text="$t('app.delete_app_action.text')"
        cancel_text="app.delete_app_action.button_cancel"
        submit_text="app.delete_app_action.button_save"
        :submit_working="working"
      ></modal-confirm>
    </table>
    <div class="text-xs-center"><v-pagination v-if="pageCount > 1" class="apps-table--pagination" v-model="pagination" :length="pageCount"></v-pagination></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import UpdateAppAction from './UpdateAppAction';
import GetApiSecretAction from './GetApiSecretAction';

export default {
  name: 'apps-table',
  components: { UpdateAppAction, GetApiSecretAction },
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
      showDeleteAppModal: false,
      showUpdateAppModal: false,
      showGetApiSecretModal: false,
      currentApp: {},
      currentAppKey: '',
    };
  },
  computed: {
    ...mapGetters('app', ['roles', 'pageCount', 'pageNumber']),
  },
  watch: {
    currentApp() {
      if (this.currentApp && this.currentApp.key) {
        this.currentAppKey = this.currentApp.key;
      }
    },
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'app/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showDeleteAppModal = false;
      this.showUpdateAppModal = false;
      this.showGetApiSecretModal = false;
    },
    passAppToModal(app) {
      this.currentApp = app;
    },
    async deleteApp() {
      await this.executeAction(
        {
          action: 'app/deleteApp',
          name: 'appDeleteApp',
          success: this.deleteAppSuccess,
        },
        this.currentApp.key,
      );
    },
    deleteAppSuccess() {
      this.reloadPage();
    },
    showModalDeleteApp() {
      this.showDeleteAppModal = true;
    },
    closeModalDeleteApp() {
      this.showDeleteAppModal = false;
    },
    showModalUpdateApp() {
      this.showUpdateAppModal = true;
    },
    closeModalUpdateApp() {
      this.showUpdateAppModal = false;
    },
    showModalGetApiSecret() {
      this.showGetApiSecretModal = true;
    },
    closeModalGetApiSecret() {
      this.showGetApiSecretModal = false;
    },
  },
};
</script>
