<template>
  <v-flex>
    <h1 hidden>{{ $t('package.list.title') }}</h1>
    <v-card class="form-basic pa-2">
      <v-layout row wrap align-center justify-space-between>
        <v-flex shrink>
          <button-primary class="package-form--btn-add" :loading="working" :text="$t('package.form.add')" @click="showModalAddPackage"></button-primary>
        </v-flex>
        <v-flex>
          <form @submit.prevent="submit" class="form-search">
            <v-layout class="gap" row wrap align-center justify-end>
              <v-flex shrink>
                <v-checkbox
                  v-model="displayWithoutDisabled"
                  :label="$t(`package.list.filters.without_deactivated`)"
                  :value="displayWithoutDisabled"
                  :error-messages="errors.collect('displayWithoutDisabled')"
                  data-vv-name="displayWithoutDisabled"
                  :data-vv-as="$t('package.list.filters.without_deactivated')"
                />
              </v-flex>
              <v-flex shrink>
                <button-primary :text="$t('package.list.filters.submit')" :loading="working" @click="submit"></button-primary>
              </v-flex>
            </v-layout>
          </form>
        </v-flex>
      </v-layout>
    </v-card>

    <modal-action :show-modal="showAddPackageModal"> <add-package-action @close="closeModalAddPackage" @action="reloadPage"></add-package-action> </modal-action>

    <v-flex class="data-section"> <packages-list v-if="dataReady && packagesList" :items="packagesList"></packages-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, cypress, security } from '@/mixins';
import PackagesList from './PackagesList';
import AddPackageAction from './AddPackageAction';

export default {
  name: 'view-package-form',
  mixins: [actionErrorTrackable, security, cypress],
  components: { PackagesList, AddPackageAction },
  data() {
    return {
      dataReady: false,
      showAddPackageModal: false,
      displayWithoutDisabled: true,
      status: 'active',
    };
  },
  computed: {
    ...mapGetters('package', ['packagesList', 'listStatus', 'pageNumber']),
  },
  async created() {
    await this.fetchPackages();
  },
  methods: {
    async submit() {
      if (this.displayWithoutDisabled) {
        this.status = 'active';
      } else {
        this.status = '';
      }
      await this.fetchPackages();
    },
    async fetchPackages() {
      this.dataReady = false;
      await this.executeAction({ action: 'package/listPackages', success: this.success }, { status: this.status });
      this.dataReady = true;
    },
    showModalAddPackage() {
      this.showAddPackageModal = true;
    },
    closeModalAddPackage() {
      this.showAddPackageModal = false;
    },
    reloadPage() {
      this.showAddPackageModal = false;
    },
  },
};
</script>
