import _ from 'lodash';

function generateSnackBar(options) {
  return _.merge(
    {},
    {
      y: 'top',
      x: null,
      mode: '',
      color: 'success',
      text: '',
      show: true,
      timeout: 6000,
    },
    options,
  );
}

export default {
  namespaced: true,
  state: {
    errors: {},
    currentLocale: 'en',
    locales: [
      {
        text: 'English',
        value: 'en',
      },
      {
        text: 'Français',
        value: 'fr',
      },
    ],
    snackbars: [],
  },
  getters: {
    error: (state) => state.errors,
    currentLocale: (state) => state.currentLocale,
    locales: (state) => state.locales,
    snackbars: (state) => state.snackbars,
  },
  mutations: {
    currentLocale(state, locale) {
      state.currentLocale = locale;
    },
    error(state, { contextName, error }) {
      if (error) {
        if (state.errors[contextName]) {
          console.warn(`Only the first error will be displayed.  Caught: ${error}`); // eslint-disable-line no-console
        } else {
          state.errors = _.merge({}, state.errors, { [contextName]: error });
        }
      } else if (state.errors[contextName]) {
        state.errors = _.merge({}, _.omit(state.errors, contextName));
      }
    },
    clearError(state) {
      state.errors = {};
    },
    addSnackbar(state, snackbar) {
      state.snackbars = _.concat(state.snackbars, snackbar);
    },
    cleanSnackbar(state, snackbar) {
      state.snackbars = _.filter(state.snackbars, (e) => {
        return e !== snackbar;
      });
    },
  },
  actions: {
    showSuccessSnackBar({ commit }, { text }) {
      const timeout = 6000;

      const snackbarToAdd = generateSnackBar({ text, timeout });

      commit('addSnackbar', snackbarToAdd);

      setTimeout(() => {
        commit('cleanSnackbar', snackbarToAdd);
      }, timeout);
    },
    changeLocale({ commit }, newLocale) {
      commit('currentLocale', newLocale);
      const timeout = 6000;
      const snackbarToAdd = generateSnackBar({ text: 'Locale changed', timeout });

      commit('addSnackbar', snackbarToAdd);
      setTimeout(() => {
        commit('cleanSnackbar', snackbarToAdd);
      }, timeout);
    },
    addError({ commit }, { contextName, error }) {
      console.trace(`CATCHED ERROR. Context: ${contextName} Error: `, error); // eslint-disable-line no-console
      commit('error', { contextName, error });
    },
    clearError({ commit }, contextName) {
      commit('error', { contextName, error: null });
    },
    clearAllError({ commit }) {
      commit('clearError');
    },
  },
};
