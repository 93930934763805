function install(Vue) {
  Vue.prototype.$auth = {
    async logout() {
      return Vue.prototype.$keycloak.logoutFn ? Vue.prototype.$keycloak.logoutFn() : null;
    },
    async isAuthenticated() {
      return Vue.prototype.$keycloak.authenticated;
    },
    async getAccessToken() {
      return Vue.prototype.$keycloak.token;
    },
    async getUser() {
      return Vue.prototype.$keycloak.loadUserProfile();
    },
  };
}

export default { install };
