<template>
  <view-main title="page_title.package"> <view-package-form slot="content"></view-package-form> </view-main>
</template>

<script>
import ViewPackageForm from './ViewPackageForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'package-view',
  mixins: [renderErrorTrackable],
  components: { ViewPackageForm },
};
</script>
