<template>
  <card icon="fal fa-user" :title="`${$t('virtual_money.list.title')}`">
    <template slot="headerZoneRight"></template>

    <template slot="content">
      <virtual-money-table :items="items"></virtual-money-table>
    </template>
  </card>
</template>

<script>
import VirtualMoneyTable from './VirtualMoneyTable';

export default {
  name: 'virtual-money-list',
  components: { VirtualMoneyTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
