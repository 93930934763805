<template>
  <view-main title="page_title.partner"> <view-partner-form slot="content"></view-partner-form> </view-main>
</template>

<script>
import ViewPartnerForm from './ViewPartnerForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'partner-view',
  mixins: [renderErrorTrackable],
  components: { ViewPartnerForm },
};
</script>
