const REDIRECT_PATH_COOKIE_NAME = 'lr360-hc-console-redirect-path';

function isoToUTCString(str) {
  const parts = str.match(/\d+/g);
  const isoTime = Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
  const isoDate = new Date(isoTime);

  return isoDate.toUTCString();
}

function setCookie(name, value, expiresAt) {
  let expiresText = '';
  if (expiresAt) {
    expiresText = ' expires=' + isoToUTCString(expiresAt) + ';';
  }

  const cookieText = name + '=' + value + '; path=/;' + expiresText;
  document.cookie = cookieText;

  return cookieText;
}

function getCookie(name) {
  const pattern = new RegExp(name + '=([^;]*)');
  const matched = document.cookie.match(pattern);

  if (matched) {
    return matched[1];
  }

  return null;
}

function deleteCookie(name) {
  return setCookie(name, '', '1970-01-01T00:00:00Z');
}

export default ({ Vue }) => {
  Vue.prototype.$cookies = {
    setRedirectPathCookie: function(path) {
      return setCookie(REDIRECT_PATH_COOKIE_NAME, path);
    },
    getRedirectPathCookie: function() {
      return getCookie(REDIRECT_PATH_COOKIE_NAME);
    },
    deleteRedirectPathCookie: function() {
      return deleteCookie(REDIRECT_PATH_COOKIE_NAME);
    },
  };
};
