import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';
import errorTypes from '@/utils/error/error-types';
import { cypress } from '@/mixins';

const defaultKeyword = '';
const defaultPage = 1;
let paginationLimit = 20;

if (cypress.computed.cypress()) {
  paginationLimit = 5;
}

export default {
  namespaced: true,
  state: {
    app: {},
    apps: [],
    currentKeyword: defaultKeyword,
    pageNumber: defaultPage,
    pageCount: 0,
    roles: ['authenticated', 'public', 'admin', 'aml', 'aml_officer', 'superadmin', 'support', 'test', 'simulator'],
  },

  getters: {
    apiSecret: (state) => state.app.api_secret,
    appsFromSearch: (state) => state.apps,
    currentKeyword: (state) => state.currentKeyword,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
    roles: (state) => state.roles,
    appByKey: (state) => (key) => {
      return state.apps.find((app) => app.key === key);
    },
  },

  mutations: {
    app(state, app) {
      state.app = app;
    },
    apps(state, appsFromSearch) {
      state.apps = appsFromSearch;
    },
    keyword(state, keyword) {
      state.currentKeyword = keyword;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async searchApps({ commit }, { page, limit, keyword }) {
      const appService = Vue.prototype.$services.app;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || defaultKeyword;

      const appsFromSearch = await appService.searchApp(thePage, theLimit, theKeyword);

      commit('keyword', theKeyword);
      commit('page', thePage);
      commit('pageCount', appsFromSearch.page_count);
      commit('apps', appsFromSearch.items);
    },

    async changePage({ commit, state }, { page }) {
      commit('page', page);

      const appService = Vue.prototype.$services.app;
      const limit = paginationLimit;
      const keyword = state.currentKeyword;

      const appsFromSearch = await appService.searchApp(page, limit, keyword);

      commit('pageCount', appsFromSearch.page_count);
      commit('apps', appsFromSearch.items);
    },

    async createApp({ dispatch }, payload) {
      const appService = Vue.prototype.$services.app;
      const defaultDispatch = safeDispatcher(dispatch);

      await appService.createApp(payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'app.add_app_action.success' });

      await defaultDispatch('app/searchApps', { defaultPage, paginationLimit, defaultKeyword });
    },

    async updateApp({ dispatch }, { key, name, partner_id, roles, whitelist_cidrs }) {
      const appService = Vue.prototype.$services.app;
      const defaultDispatch = safeDispatcher(dispatch);

      const payload = {
        name,
        roles,
        whitelist_cidrs,
        partner_id,
      };

      await appService.updateApp(key, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'app.update_app_action.success' });

      await defaultDispatch('app/searchApps', { defaultPage, paginationLimit, defaultKeyword });
    },

    async deleteApp({ dispatch }, key) {
      const appService = Vue.prototype.$services.app;
      const defaultDispatch = safeDispatcher(dispatch);

      const res = await appService.deleteApp(key);

      if (res.result === 'fail') {
        res.error.code = 'app.deletion';
        throw new errorTypes.HttpError('fail', res);
      }

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'app.delete_app_action.success' });

      await defaultDispatch('app/searchApps', { defaultPage, paginationLimit, defaultKeyword });
    },

    async getApp({ commit }, key) {
      const appService = Vue.prototype.$services.app;

      const app = await appService.getApp(key);
      commit('app', app);
    },
  },
};
