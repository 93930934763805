import camelCase from 'lodash/camelCase';

export default ({ Vue, app }) => {
  const requireModule = require.context('.', true, /^((?!\.unit\.).)*\.js$/);
  const root = { modules: {} };

  requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return;

    // Get the module path as an array
    const modulePath = fileName
      .replace(/^\.\/|\.js$/g, '')
      .split(/\//)
      .map(camelCase);

    const { modules } = getNamespace(root, modulePath);

    const moduleIndex = modulePath.pop();

    modules[moduleIndex] = {
      namespaced: true,
      ...requireModule(fileName).default({ Vue, app }),
    };

    function getNamespace(subtree, path) {
      if (path.length === 1) return subtree;

      const namespace = path.shift();
      subtree.modules[namespace] = {
        modules: {},
        ...subtree.modules[namespace],
      };
      return getNamespace(subtree.modules[namespace], path);
    }
  });
};
