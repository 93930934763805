<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('guest_services_settings.update_action.title')"
    context="updateGuestServicesSettings"
    @submit="submit"
    @cancel="cancel"
    cancel_text="guest_services_settings.update_action.button_cancel"
    submit_text="guest_services_settings.update_action.button_save"
    :submit_working="working"
    class="smoke-guest-services-settings--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.partner_id"
            v-model="currentGuestServicesSettings.partner_id"
            :label="$t('guest_services_settings.update_action.fields.partner_id')"
            :error-messages="errors.collect('guest_services_settings.partner_id')"
            data-vv-name="guest_services_settings.partner_id"
            :data-vv-as="$t('guest_services_settings.update_action.fields.partner_id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-guest-services-settings--update--partner_id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.consumer_payment_notice.fr"
            v-model="currentGuestServicesSettings.consumer_payment_notice.fr"
            :label="$t('guest_services_settings.update_action.fields.consumer_payment_notice.fr')"
            :error-messages="errors.collect('guest_services_settings.consumer_payment_notice.fr')"
            data-vv-name="guest_services_settings.consumer_payment_notice.fr"
            :data-vv-as="$t('guest_services_settings.update_action.fields.consumer_payment_notice.fr')"
            v-validate="'max:200'"
            counter
            maxlength="200"
            class="smoke-guest-services-settings--update--consumer_payment_notice-fr"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.consumer_payment_notice.en"
            v-model="currentGuestServicesSettings.consumer_payment_notice.en"
            :label="$t('guest_services_settings.update_action.fields.consumer_payment_notice.en')"
            :error-messages="errors.collect('guest_services_settings.consumer_payment_notice.en')"
            data-vv-name="guest_services_settings.consumer_payment_notice.en"
            :data-vv-as="$t('guest_services_settings.update_action.fields.consumer_payment_notice.en')"
            v-validate="'max:200'"
            counter
            maxlength="200"
            class="smoke-guest-services-settings--update--consumer_payment_notice-en"
          />
        </v-flex>
        <div style="margin-top: 2em; margin-bottom: 1em;">
          <ul>
            <li>{{ $t('guest_services_settings.shared.consumer_purchase_over_limit_notice_instructions.example') }}</li>
            <li style="list-style-position: inside;">{{ $t('guest_services_settings.shared.consumer_purchase_over_limit_notice_instructions.example_1') }}</li>
            <li style="list-style-position: inside;">{{ $t('guest_services_settings.shared.consumer_purchase_over_limit_notice_instructions.example_2') }}</li>
          </ul>
        </div>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.consumer_purchase_over_limit_notice.fr"
            v-model="currentGuestServicesSettings.consumer_purchase_over_limit_notice.fr"
            :label="$t('guest_services_settings.update_action.fields.consumer_purchase_over_limit_notice.fr')"
            :error-messages="errors.collect('guest_services_settings.consumer_purchase_over_limit_notice.fr')"
            data-vv-name="guest_services_settings.consumer_purchase_over_limit_notice.fr"
            :data-vv-as="$t('guest_services_settings.update_action.fields.consumer_purchase_over_limit_notice.fr')"
            v-validate="'max:200'"
            counter
            maxlength="200"
            class="smoke-guest-services-settings--update--consumer_purchase_over_limit_notice-fr"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="guest_services_settings.consumer_purchase_over_limit_notice.en"
            v-model="currentGuestServicesSettings.consumer_purchase_over_limit_notice.en"
            :label="$t('guest_services_settings.update_action.fields.consumer_purchase_over_limit_notice.en')"
            :error-messages="errors.collect('guest_services_settings.consumer_purchase_over_limit_notice.en')"
            data-vv-name="guest_services_settings.consumer_purchase_over_limit_notice.en"
            :data-vv-as="$t('guest_services_settings.update_action.fields.consumer_purchase_over_limit_notice.en')"
            v-validate="'max:200'"
            counter
            maxlength="200"
            class="smoke-guest-services-settings--update--consumer_purchase_over_limit_notice-en"
          />
        </v-flex>
        <v-flex xs12>
          <v-checkbox
            v-model="currentGuestServicesSettings.features.print_generate_voucher"
            :label="$t('guest_services_settings.update_action.fields.features.print_generate_voucher')"
            class="smoke-guest-services-settings--update--print_generate_voucher"
          />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="currentGuestServicesSettings.features.cancel_voucher" :label="$t('guest_services_settings.update_action.fields.features.cancel_voucher')" class="smoke-guest-services-settings--update--cancel_voucher" />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="currentGuestServicesSettings.features.load_voucher" :label="$t('guest_services_settings.update_action.fields.features.load_voucher')" class="smoke-guest-services-settings--update--load_voucher" />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="currentGuestServicesSettings.features.reload_voucher" :label="$t('guest_services_settings.update_action.fields.features.reload_voucher')" class="smoke-guest-services-settings--update--reload_voucher" />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-guest-services-settings-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    guestServicesSettings: {
      type: Object,
      required: true,
      default: () => {
        return {
          partner_id: '',
          consumer_payment_notice: {
            en: '',
            fr: '',
          },
          consumer_purchase_over_limit_notice: {
            en: '',
            fr: '',
          },
          features: {
            print_generate_voucher: false,
            cancel_voucher: false,
            load_voucher: false,
            reload_voucher: false,
          },
        };
      },
    },
  },
  data() {
    return {
      currentGuestServicesSettings: {
        partner_id: '',
        consumer_payment_notice: {
          en: '',
          fr: '',
        },
        consumer_purchase_over_limit_notice: {
          en: '',
          fr: '',
        },
        features: {
          print_generate_voucher: false,
          cancel_voucher: false,
          load_voucher: false,
          reload_voucher: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters('guestServicesSettings', ['getGuestServicesSettings']),
  },
  watch: {
    guestServicesSettings(value) {
      this.currentGuestServicesSettings = _.cloneDeep(value);
    },
  },
  methods: {
    resetForm() {
      this.currentGuestServicesSettings = _.cloneDeep(this.getGuestServicesSettings(this.guestServicesSettings.partner_id));
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'guestServicesSettings/updateGuestServicesSettings',
            name: 'updateGuestServicesSettings',
            success: this.success,
          },
          this.currentGuestServicesSettings,
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
