import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store';

import HomeComponent from './components/Home.vue';

import AgentView from '@/components/agent/AgentView.vue';
import StatusView from '@/components/status/StatusView.vue';
import AlertView from '@/components/alert/AlertView.vue';
import NoteTypeView from '@/components/notetype/NoteTypeView.vue';
import VirtualMoneyView from '@/components/virtual-money/VirtualMoneyView.vue';
import AppView from '@/components/app/AppView.vue';
import GroupView from '@/components/group/GroupView.vue';
import PackageView from '@/components/package/PackageView.vue';
import ProgramView from '@/components/program/ProgramView.vue';
import PartnerView from '@/components/partner/PartnerView.vue';
import BrandingCategoryView from '@/components/branding-category/BrandingCategoryView.vue';
import SpaceView from '@/components/space/SpaceView.vue';
import SubscriptionPlanView from '@/components/subscription-plan/SubscriptionPlanView.vue';
import GuestServicesSettingsView from '@/components/guest-services-settings/GuestServicesSettingsView.vue';

import NoAccessView from '@/components/NoAccessView.vue';
import PageNotFoundView from '@/components/PageNotFoundView.vue';

import LanguageSwitcher from './components/header/LanguageSwitcher.vue';

import { registerSystemComponent } from './components/system';

Vue.use(Router);

Vue.component('language-switcher', LanguageSwitcher);

registerSystemComponent(Vue);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      component: HomeComponent,
    },
    {
      path: '/no-access',
      name: 'no-access',
      component: NoAccessView,
    },
    {
      path: '/agent/:agentId?',
      name: 'agent',
      component: AgentView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Agent',
      },
    },
    {
      path: '/status',
      name: 'status',
      component: StatusView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Agent',
      },
    },
    {
      path: '/alert/:alertId?',
      name: 'alert',
      component: AlertView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_alert',
        title: 'Alert',
      },
    },
    {
      path: '/app/:appId?',
      name: 'app',
      component: AppView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'view_alert',
        title: 'App',
      },
    },
    {
      path: '/program/:appId?',
      name: 'program',
      component: ProgramView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Program',
      },
    },
    {
      path: '/group/:groupId?',
      name: 'group',
      component: GroupView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Group',
      },
    },
    {
      path: '/package/:packageId?',
      name: 'package',
      component: PackageView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Package',
      },
    },
    {
      path: '/partner/:partnerId?',
      name: 'partner',
      component: PartnerView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Partner',
      },
    },
    {
      path: '/branding-category/:brandingCategoryKey?',
      name: 'branding-category',
      component: BrandingCategoryView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'BrandingCategory',
      },
    },
    {
      path: '/guest-services-settings/:partnerId?',
      name: 'guest-services-settings',
      component: GuestServicesSettingsView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'GuestServicesSettings',
      },
    },
    {
      path: '/space/:spaceKey?',
      name: 'space',
      component: SpaceView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'Space',
      },
    },
    {
      path: '/note-type/:noteTypeId?',
      name: 'note-type',
      component: NoteTypeView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'NoteType',
      },
    },
    {
      path: '/virtual-money/:virtualMoneyId?',
      name: 'virtual-money',
      component: VirtualMoneyView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'VirtualMoney',
      },
    },
    {
      path: '/subscription-plan/:planId?',
      name: 'subscription-plan',
      component: SubscriptionPlanView,
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermission: 'internal',
        title: 'SubscriptionPlan',
      },
    },
    {
      path: '/not-found',
      name: 'notFound',
      component: PageNotFoundView,
    },
    {
      path: '*',
      component: PageNotFoundView,
    },
  ],
});

const onAuthRequired = async (to, from, next) => {
  // NOTE: Clear only if change route
  if (to.name !== from.name && to.name !== 'login') {
    await store.dispatch('ui/clearAllError');
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    Vue.prototype.$cookies.setRedirectPathCookie(to.fullPath);
    next({ name: 'login' });
  } else if (to.path === '/login' && (await Vue.prototype.$auth.isAuthenticated())) {
    next({ name: 'root' });
  } else if (to.matched.some((record) => record.meta.requiresPermission)) {
    const canAccess = store.getters['security/hasPermission'](to.meta.requiresPermission);
    if (!canAccess) {
      next({ name: 'notFound' });
    } else {
      next();
    }
  } else {
    next();
  }
};

router.beforeEach(onAuthRequired);

export default router;
