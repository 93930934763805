import _get from 'lodash/get';
import _keys from 'lodash/keys';

const allowed = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
const forbidden = [];
const internals = {
  countries: {
    CA: {
      allowed,
      forbidden,
    },
  },
};

export default {
  selectOptions(_this, options = allowed) {
    return options.map((option) => {
      return { name: _this.$options.filters.state(option), abbr: option };
    });
  },

  getForbiddenStatesFor(country) {
    return _get(internals, ['countries', country, 'forbidden']);
  },

  getAllowedStatesFor(country) {
    return _get(internals, ['countries', country, 'allowed']);
  },

  getValidCountries() {
    return _keys(internals.countries);
  },
};
