<template>
  <v-flex>
    <h1 hidden>{{ $t('branding_category.list.title') }}</h1>
    <v-card class="form-basic pa-2"><button-primary class="smoke-branding-category--btn-add" :loading="working" :text="$t('branding_category.form.add')" @click="showModalAddBrandingCategory"></button-primary> </v-card>
    <modal-action :show-modal="showAddBrandingCategoryModal"> <add-branding-category-action @close="closeModalAddBrandingCategory" @action="reloadPage"></add-branding-category-action> </modal-action>
    <v-flex class="data-section"> <branding-category-list v-if="dataReady && brandingCategories" :items="brandingCategories"></branding-category-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddBrandingCategoryAction from './AddBrandingCategoryAction';
import BrandingCategoryList from './BrandingCategoryList';

export default {
  name: 'view-branding-category-form',
  mixins: [actionErrorTrackable, security],
  components: { BrandingCategoryList, AddBrandingCategoryAction },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      dataReady: false,
      showAddBrandingCategoryModal: false,
    };
  },
  computed: {
    ...mapGetters('brandingCategory', ['brandingCategories']),
  },
  async created() {
    await this.fetchBrandingCategories();
  },
  methods: {
    async fetchBrandingCategories() {
      this.dataReady = false;
      await this.executeAction({ action: 'brandingCategory/listBrandingCategories', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddBrandingCategory() {
      this.showAddBrandingCategoryModal = true;
    },
    closeModalAddBrandingCategory() {
      this.showAddBrandingCategoryModal = false;
    },
    reloadPage() {
      this.showAddBrandingCategoryModal = false;
    },
  },
};
</script>
