<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('app.add_app_action.title')"
    context="addApp"
    @submit="submit"
    @cancel="cancel"
    cancel_text="app.add_app_action.button_cancel"
    submit_text="app.add_app_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            name="name"
            v-model="name"
            v-validate="'required|max:20'"
            :label="$t('app.add_app_action.fields.name')"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            :data-vv-as="$t('app.add_app_action.fields.name')"
            required
            counter
            maxlength="20"
            class="add-app--name"
          ></v-text-field>
        </v-flex>
        <v-flex sm6 xs12>
          <v-select
            :items="organizations"
            v-model="organization_key"
            item-text="name"
            item-value="key"
            :label="organization_dropdown_label"
            v-validate="'required'"
            :error-messages="errors.collect('organizations')"
            data-vv-name="organizations"
            :data-vv-as="$t('app.add_app_action.organizations')"
            class="add-app--organization"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
          <v-label v-if="roles">{{ $t('app.add_app_action.fields.roles') }}</v-label>
          <div v-if="roles" class="add-app-action--role">
            <v-checkbox
              v-for="(role, index) in roles"
              :key="index"
              v-model="selected_roles"
              :label="$t(`code.roles.${role}`)"
              :value="role"
              v-validate="'required'"
              :error-messages="errors.collect('roles')"
              data-vv-name="roles"
              :data-vv-as="$t('app.add_app_action.roles')"
            />
          </div>
        </v-flex>
        <v-flex sm6 xs12>
          <v-layout column>
            <v-flex sm6 xs12>
              <v-text-field
                name="partner_id"
                v-model="partner_id"
                v-validate="'max:100'"
                :label="$t('app.add_app_action.fields.partner_id')"
                :error-messages="errors.collect('partner_id')"
                data-vv-name="partner_id"
                :data-vv-as="$t('app.add_app_action.fields.partner_id')"
                counter
                maxlength="100"
                class="add-app--partner-id"
              ></v-text-field>
            </v-flex>

            <v-flex xs11>
              <v-layout row>
                <v-flex xs11>
                  <v-text-field
                    name="whitelist_cidr"
                    v-model="whitelist_cidr"
                    :label="$t('app.add_app_action.fields.whitelist_cidr')"
                    class="add-app--whitelist-cidr"
                    v-validate="'validateRange'"
                    :error-messages="errors.collect('whitelist_cidr')"
                    data-vv-name="whitelist_cidr"
                    :data-vv-as="$t('app.add_app_action.fields.whitelist_cidr')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs1>
                  <button type="button" class="add-app-action--add-cidr" @click="addCidrToArray" :disabled="disable_add_cidr">
                    <v-icon small>fal fa-plus</v-icon>
                  </button>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="whitelist_cidrs.length > 0" xs11 class="add-app-action--whitelist-cidrs-section">
              <v-label>{{ $t('app.add_app_action.whitelist_cidrs_label') }}</v-label>
              <ul class="add-app-action--whitelist-cidrs-list">
                <li v-for="(cidr, index) in whitelist_cidrs" :key="index">
                  {{ cidr }}
                  <button type="button" class="add-app-action--remove-cidr" @click="removeCidrFromArray(cidr)">
                    <v-icon small>fal fa-minus-circle</v-icon>
                  </button>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import _ from 'lodash';
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-app-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      dataReady: false,
      name: '',
      partner_id: '',
      organization_key: '',
      whitelist_cidr: '',
      whitelist_cidrs: [],
      selected_roles: [],
      disable_add_cidr: true,
      organization_dropdown_label: this.$t('app.add_app_action.fields.organization_key'),
    };
  },
  computed: {
    ...mapGetters('app', ['roles']),
    ...mapGetters('organization', ['organizations']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    whitelist_cidr() {
      if (this.whitelist_cidr && this.isCidrValid()) {
        this.disable_add_cidr = false;
      } else {
        this.disable_add_cidr = true;
      }
    },
  },
  async created() {
    await this.fetchOrganizations();
  },
  methods: {
    resetForm() {
      this.dataReady = false;
      this.name = '';
      this.partner_id = '';
      this.organization_key = '';
      this.whitelist_cidr = '';
      this.whitelist_cidrs = [];
      this.selected_roles = [];
      this.disable_add_cidr = true;
    },
    isCidrValid() {
      if (this.errors.has('whitelist_cidr')) {
        return false;
      }
      return true;
    },
    addCidrToArray() {
      if (this.isCidrValid()) {
        this.whitelist_cidrs.push(this.whitelist_cidr);
        this.whitelist_cidr = '';
      }
    },
    removeCidrFromArray(cidr) {
      const updatedCidrs = _.filter(this.whitelist_cidrs, (item) => {
        return item !== cidr;
      });
      this.whitelist_cidrs = updatedCidrs;
    },
    async submit() {
      if (await this.validationComplete()) {
        const payload = {
          name: this.name,
          organization_key: this.organization_key,
          roles: this.selected_roles,
          whitelist_cidrs: this.whitelist_cidrs,
        };

        if (!_.isEmpty(this.partner_id)) {
          payload.partner_id = this.partner_id;
        }

        await this.executeAction(
          {
            action: 'app/createApp',
            name: 'addApp',
            success: this.success,
          },
          payload,
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async fetchOrganizations() {
      this.dataReady = false;
      await this.executeAction(
        {
          action: 'organization/listOrganizations',
          success: this.success,
        },
        {},
      );
      this.dataReady = true;
    },
  },
};
</script>
