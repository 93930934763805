import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';

const defaultPage = 1;
const paginationLimit = 50;

export default {
  namespaced: true,
  state: {
    space: {},
    spaces: [],
    pageNumber: 1,
    pageCount: 0,
  },

  getters: {
    spaces: (state) => state.spaces,
    getSpace: (state) => (id) => state.spaces.find((space) => space.id === id),
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },

  mutations: {
    spaces(state, spaces) {
      state.spaces = spaces;
    },
    space(state, space) {
      state.space = space;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listSpaces({ commit }, { page, limit }) {
      const spaceService = Vue.prototype.$services.space;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const listResults = await spaceService.listSpaces(thePage, theLimit);

      commit('spaces', listResults.items);
      commit('page', thePage);
      commit('pageCount', listResults.page_count);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('space/listSpaces', { page });
    },

    async getSpace({ commit }, { id }) {
      const spaceService = Vue.prototype.$services.space;
      const space = await spaceService.getSpace(id);

      commit('space', space);
    },

    async updateSpace({ dispatch }, { space }) {
      const spaceService = Vue.prototype.$services.space;
      const defaultDispatch = safeDispatcher(dispatch);

      const { id } = space;
      delete space.id;
      delete space.url;

      await spaceService.updateSpace(id, space);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'space.update_action.success' });
      await defaultDispatch('space/listSpaces', {});
    },

    async createSpace({ dispatch }, { space }) {
      const spaceService = Vue.prototype.$services.space;
      const defaultDispatch = safeDispatcher(dispatch);

      await spaceService.createSpace(space);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'space.add_action.success' });
      await defaultDispatch('space/listSpaces', {});
    },
  },
};
