<template>
  <card icon="fal fa-user" :title="$t('subscription_plan.list.title')">
    <template slot="headerZoneRight" />

    <template slot="content">
      <subscription-plan-table :items="items" />
    </template>
  </card>
</template>

<script>
import SubscriptionPlanTable from './SubscriptionPlanTable.vue';

export default {
  name: 'subscription-plan-list',

  components: { SubscriptionPlanTable },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
