<template>
  <view-main title="page_title.space"> <view-space-form slot="content"></view-space-form> </view-main>
</template>

<script>
import ViewSpaceForm from './ViewSpaceForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'space-view',
  mixins: [renderErrorTrackable],
  components: { ViewSpaceForm },
};
</script>
