<template>
  <card icon="fal fa-user" :title="`${$t('guest_services_settings.list.title')}`">
    <template slot="headerZoneRight"> </template>

    <template slot="content">
      <guest-services-settings-table :items="items"></guest-services-settings-table>
    </template>
  </card>
</template>

<script>
import GuestServicesSettingsTable from './GuestServicesSettingsTable';

export default {
  name: 'guest-services-settings-list',
  components: { GuestServicesSettingsTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
