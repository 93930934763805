<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('note_type.add_action.title')"
    context="createNoteType"
    @submit="submit"
    @cancel="cancel"
    cancel_text="note_type.add_action.button_cancel"
    submit_text="note_type.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-note-type--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            type="text"
            name="note_type.id"
            v-model="noteType.id"
            :label="$t('note_type.add_action.fields.id')"
            :error-messages="errors.collect('note_type.id')"
            data-vv-name="note_type.id"
            :data-vv-as="$t('note_type.add_action.fields.id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-note-type--add--id"
          />
        </v-flex>

        <v-flex sm6 xs12>
          <v-select :items="noteCategories" v-model="noteType.category_id" item-text="name.en" item-value="id" :label="$t('note_type.add_action.fields.category')" class="smoke-note-type--add--category" />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="note_type.name.fr"
            v-model="noteType.name.fr"
            :label="$t('note_type.add_action.fields.name.fr')"
            :error-messages="errors.collect('note_type.name.fr')"
            data-vv-name="note_type.name.fr"
            :data-vv-as="$t('note_type.add_action.fields.name.fr')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-note-type--add--name-fr"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="note_type.name.en"
            v-model="noteType.name.en"
            :label="$t('note_type.add_action.fields.name.en')"
            :error-messages="errors.collect('note_type.name.en')"
            data-vv-name="note_type.name.en"
            :data-vv-as="$t('note_type.add_action.fields.name.en')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-note-type--add--name-en"
          />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-note-type-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      dataReady: true,
      noteType: {
        id: '',
        category_id: null,
        name: {
          fr: '',
          en: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters('noteCategory', ['noteCategories']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  async created() {
    this.dataReady = false;
    await this.executeAction(
      {
        action: 'noteCategory/listNoteCategories',
        success: this.success,
      },
      {},
    );
    this.dataReady = true;
  },
  methods: {
    resetForm() {
      this.noteType = {
        id: '',
        category_id: null,
        name: {
          fr: '',
          en: '',
        },
      };
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'noteType/createNoteType',
            name: 'createNoteType',
            success: this.success,
          },
          { note_type: this.noteType },
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
