import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('security', ['currentUser', 'hasPermission']),
  },
  methods: {
    canViewApplication() {
      return this.hasPermission('view_application');
    },
    canManageApplication() {
      return this.hasPermission('manage_application');
    },
    canCommentApplication() {
      return this.hasPermission('comment_application');
    },
    canViewCard() {
      return this.hasPermission('view_card');
    },
    canManageCard() {
      return this.hasPermission('manage_card');
    },
    canCommentCard() {
      return this.hasPermission('comment_card');
    },
    canViewWalletDefinition() {
      return this.hasPermission('view_wallet_definition');
    },
    canManageAmlProgram() {
      return this.hasPermission('manage_aml_program');
    },
    canViewAlert() {
      return this.hasPermission('view_alert');
    },
    canManageAlert() {
      return this.hasPermission('manage_alert');
    },
    isAgentInternal() {
      return this.hasPermission('internal');
    },
  },
};
