<template>
  <form @submit.prevent="submit" class="form-basic form-search">
    <v-layout align-center wrap>
      <v-flex xs12 sm6 md5 class="form-search--main-input-wrap">
        <slot name="main-input"> <v-text-field class="form-search--search-field" :label="$t(inputLabel)" v-model="keyword" data-vv-name="keyword" :error-messages="errors.collect('keyword')" :maxlength="maxLength"></v-text-field> </slot>
      </v-flex>
      <v-flex xs12 sm6 md7 class="form-search--button-wrap">
        <button-primary class="form-search--btn-submit" :text="$t(submitText)" @click="submit"></button-primary>
        <slot name="buttons"></slot>
      </v-flex>
    </v-layout>
  </form>
</template>

<script>
export default {
  name: 'form-search',
  props: {
    maxLength: {
      type: Number,
      required: true,
    },
    inputLabel: {
      type: String,
      required: false,
    },
    submitText: {
      type: String,
      required: false,
    },
    updatedKeyword: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      keyword: '',
    };
  },
  watch: {
    updatedKeyword() {
      this.keyword = this.updatedKeyword;
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.keyword);
    },
  },
};
</script>
