<template>
  <v-card>
    <v-layout column>
      <v-card-title class="modal-card--card-title">
        <v-icon color="primary">{{ icon }}</v-icon>
        <h3>{{ title }}</h3>
      </v-card-title>

      <v-card-text class="modal-card--divider"> <v-divider class="modal-card--divider"></v-divider> </v-card-text>

      <v-card-text> <error :context="context" /> <slot name="content"></slot> </v-card-text>

      <v-card-actions>
        <v-layout row>
          <slot name="button-zone--left"></slot>
          <v-spacer></v-spacer>
          <button-modal-cancel :text="$t(cancel_text)" @click="cancel" class="card-modal--cancel-button"></button-modal-cancel>
          <button-primary v-if="submit_text" @click="submit" :loading="submit_working" :text="$t(submit_text)" :is-disabled="!enable_submit" class="card-modal--submit-button"></button-primary>
        </v-layout>
      </v-card-actions>

      <slot name="test-zone"></slot>
    </v-layout>
  </v-card>
</template>

<script>
import error from '@/mixins/action-error-trackable';

export default {
  name: 'card-modal',
  mixins: [error],
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
    cancel_text: {
      type: String,
      required: true,
    },
    submit_text: {
      type: String,
      required: false,
    },
    enable_submit: {
      type: Boolean,
      required: false,
      default: true,
    },
    submit_working: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    clear() {
      this.clearError(this.context);
    },
    submit() {
      this.clear();
      this.$emit('submit');
    },
    cancel() {
      this.clear();
      this.$emit('cancel');
    },
  },
};
</script>
