import Vue from 'vue';
import _ from 'lodash';
import { safeDispatcher } from '@/utils/context/context-helper';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    currentPartner: {},
    partners: [],
    pageNumber: defaultPage,
    pageCount: 0,
    connector_interfaces: ['magis'],
    connector_names: ['magis', 'mouvement_bleu'],
  },

  getters: {
    partners: (state) => state.partners,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
    getConnectorInterfaces: (state) => state.connector_interfaces,
    getConnectorNames: (state) => state.connector_names,
    getLocalPartnerById: (state) => (id) => state.partners.find((partner) => partner.id === id),
  },

  mutations: {
    partner(state, partner) {
      state.partner = partner;
    },
    partners(state, partners) {
      state.partners = _.orderBy(partners, [(partner) => partner.name.toLowerCase()], ['asc']);
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listPartners({ commit }, { page, limit }) {
      const partnerService = Vue.prototype.$services.partner;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const partnerList = await partnerService.listPartners(thePage, theLimit);

      commit('page', thePage);
      commit('pageCount', partnerList.page_count);
      commit('partners', partnerList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('partner/listPartners', { page });
    },

    async getPartner({ commit }, { id }) {
      const partnerService = Vue.prototype.$services.partner;
      const partner = await partnerService.getPartner(id);

      commit('partner', partner);
    },

    async updatePartner({ dispatch }, { partner }) {
      const partnerService = Vue.prototype.$services.partner;
      const defaultDispatch = safeDispatcher(dispatch);

      const { id } = partner;
      delete partner.id;
      delete partner.deleted;

      await partnerService.updatePartner(id, partner);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'partner.update_partner_action.success' });
      await defaultDispatch('partner/listPartners', {});
    },

    async createPartner({ dispatch }, { partner }) {
      const partnerService = Vue.prototype.$services.partner;
      const defaultDispatch = safeDispatcher(dispatch);

      await partnerService.createPartner(partner);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'partner.add_partner_action.success' });
      await defaultDispatch('partner/listPartners', {});
    },
  },
};
