<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('note_type.update_action.title')"
    context="updateNoteType"
    @submit="submit"
    @cancel="cancel"
    cancel_text="note_type.add_action.button_cancel"
    submit_text="note_type.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-note-type--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="note_type.name.fr"
            v-model="currentNoteType.name.fr"
            :label="$t('note_type.update_action.fields.name.fr')"
            :error-messages="errors.collect('note_type.name.fr')"
            data-vv-name="note_type.name.fr"
            :data-vv-as="$t('note_type.update_action.fields.name.fr')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-note-type--update--name-fr"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="note_type.name.en"
            v-model="currentNoteType.name.en"
            :label="$t('note_type.update_action.fields.name.en')"
            :error-messages="errors.collect('note_type.name.en')"
            data-vv-name="note_type.name.en"
            :data-vv-as="$t('note_type.update_action.fields.name.en')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-note-type--update--name-en"
          />
        </v-flex>

        <v-flex xs12>
          <v-checkbox
            v-model="currentNoteType.active"
            name="note_type.active"
            data-vv-name="note_type.active"
            :data-vv-as="$t('note_type.update_action.fields.active')"
            :error-messages="errors.collect('note_type.active')"
            v-validate="'required'"
            :label="$t('note_type.update_action.fields.active')"
            class="smoke-note-type--update--active"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-note-type-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    noteType: {
      type: Object,
      required: true,
      default: () => {
        return { name: {} };
      },
    },
  },
  data() {
    return {
      currentNoteType: { name: {} },
    };
  },
  computed: {
    ...mapGetters('noteType', ['getNoteType']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    noteType(value) {
      this.currentNoteType = _.cloneDeep(value);
    },
  },
  methods: {
    resetForm() {
      this.currentNoteType = _.cloneDeep(this.getNoteType(this.noteType.id));
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'noteType/updateNoteType',
            name: 'updateNoteType',
            success: this.success,
          },
          { note_type: this.currentNoteType },
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
