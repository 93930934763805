<template>
  <v-data-table :headers="headers" :items="items" hide-actions class="responsive-v-data-table">
    <template slot="items" slot-scope="props">
      <td v-for="key in Object.keys(props.item)" :key="key" class="responsive-v-data-table__td" v-html="`<h5>${getMobileHeader(key)}</h5> ${props.item[key]}`"></td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'responsive-vdata-table',
  props: {
    headers: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
    },
  },
  methods: {
    getMobileHeader(item) {
      const totalHeaders = this.headers.length;
      for (let i = 0; i <= totalHeaders; i++) {
        if (this.headers[i].value === item) {
          return this.headers[i].text;
        }
      }
    },
  },
};
</script>
