<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('app.update_app_action.title')"
    context="updateApp"
    @submit="submit"
    @cancel="cancel"
    cancel_text="app.update_app_action.button_cancel"
    submit_text="app.update_app_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-layout column sm6 xs12 class="update_app_action-column1">
          <v-flex sm12 xs12>
            <v-text-field
              name="name"
              v-model="currentApp.name"
              v-validate="'required|max:30'"
              :label="$t('app.update_app_action.fields.name')"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              :data-vv-as="$t('app.update_app_action.fields.name')"
              required
              counter
              maxlength="30"
              class="update-app--name"
            ></v-text-field>
          </v-flex>

          <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
            <v-label>{{ $t('app.update_app_action.fields.roles') }}</v-label>
            <div v-if="roles" class="update-app-action--role">
              <v-checkbox
                v-for="(role, index) in roles"
                :key="index"
                v-model="selectedRoles"
                :label="$t(`code.roles.${role}`)"
                :value="role"
                v-validate="'required'"
                :error-messages="errors.collect('roles')"
                data-vv-name="roles"
                :data-vv-as="$t('app.update_app_action.roles')"
              />
            </div>
          </v-flex>
        </v-layout>

        <v-layout column sm6 xs12 class="update_app_action-column2">
          <v-flex sm6 xs12>
            <v-layout column>
              <v-flex sm6 xs12>
                <v-text-field
                  name="partner_id"
                  v-model="currentApp.partner_id"
                  v-validate="'max:100'"
                  :label="$t('app.add_app_action.fields.partner_id')"
                  :error-messages="errors.collect('partner_id')"
                  data-vv-name="partner_id"
                  :data-vv-as="$t('app.add_app_action.fields.partner_id')"
                  counter
                  maxlength="100"
                  class="update-app--partner-id"
                ></v-text-field>
              </v-flex>

              <v-flex>
                <v-layout row>
                  <v-flex xs11>
                    <v-text-field
                      name="whitelist_cidr"
                      v-model="whitelist_cidr"
                      :label="$t('app.update_app_action.fields.whitelist_cidr')"
                      class="update-app--whitelist-cidr"
                      v-validate="'validateRange'"
                      :error-messages="errors.collect('whitelist_cidr')"
                      data-vv-name="whitelist_cidr"
                      :data-vv-as="$t('app.add_app_action.fields.whitelist_cidr')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <button type="button" class="update-app-action--add-cidr" @click="addCidrToArray" :disabled="disable_add_cidr">
                      <v-icon small>fal fa-plus</v-icon>
                    </button>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex v-if="app && selectedCidrs && selectedCidrs.length > 0" xs11 class="add-app-action--whitelist-cidrs-section">
                <v-label>{{ $t('app.update_app_action.whitelist_cidrs_label') }}</v-label>
                <ul class="add-app-action--whitelist-cidrs-list">
                  <li v-for="(cidr, index) in selectedCidrs" :key="index">
                    {{ cidr }}
                    <button type="button" class="update-app-action--remove-cidr" @click="removeCidrFromArray(cidr)">
                      <v-icon small>fal fa-minus-circle</v-icon>
                    </button>
                  </li>
                </ul>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import _ from 'lodash';
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-app-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentApp: {
        name: this.app.name,
        key: this.app.key,
        partner_id: this.app.partner_id,
      },
      selectedRoles: this.app.roles,
      selectedCidrs: _.clone(this.app.whitelist_cidrs),
      whitelist_cidr: '',
      disable_add_cidr: true,
    };
  },
  computed: {
    ...mapGetters('app', ['roles', 'appsFromSearch']),
    isFormChanged() {
      // TODO: Problem: the this.selectedCidrs begins empty so cidrsChanged always equals true
      const textFieldChanged = Object.keys(this.fields).some((key) => this.fields[key].changed);
      const cidrsChanged = this.selectedCidrs === this.app.whitelist_cidrs ? false : true;

      if (textFieldChanged || cidrsChanged) {
        return true;
      }
      return false;
    },
  },
  watch: {
    app(newVal) {
      this.currentApp = {
        name: newVal.name,
        key: newVal.key,
        partner_id: newVal.partner_id,
      };
      this.selectedRoles = newVal.roles;
      this.selectedCidrs = _.clone(newVal.whitelist_cidrs);
      this.whitelist_cidr = '';
    },
    whitelist_cidr() {
      if (this.whitelist_cidr && this.isCidrValid()) {
        this.disable_add_cidr = false;
      } else {
        this.disable_add_cidr = true;
      }
    },
  },
  methods: {
    isCidrValid() {
      if (this.errors.has('whitelist_cidr')) {
        return false;
      }
      return true;
    },
    addCidrToArray() {
      if (this.isCidrValid() && !this.selectedCidrs.includes(this.whitelist_cidr)) {
        this.selectedCidrs.push(this.whitelist_cidr);
        this.whitelist_cidr = '';
      }
    },
    removeCidrFromArray(cidr) {
      const updatedCidrs = _.filter(this.selectedCidrs, (item) => {
        return item !== cidr;
      });
      this.selectedCidrs = updatedCidrs;
    },
    resetForm() {
      const originalApp = this.$store.getters['app/appByKey'](this.app.key);

      this.currentApp = {
        name: originalApp.name,
        key: originalApp.key,
      };
      this.selectedRoles = originalApp.roles;
      this.selectedCidrs = _.clone(originalApp.whitelist_cidrs);
    },
    async submit() {
      if (await this.validationComplete()) {
        const app = {
          key: this.currentApp.key,
          name: this.currentApp.name,
          roles: this.selectedRoles,
          whitelist_cidrs: this.selectedCidrs,
        };

        if (!_.isEmpty(this.currentApp.partner_id)) {
          app.partner_id = this.currentApp.partner_id;
        } else {
          app.partner_id = null;
        }

        await this.executeAction(
          {
            action: 'app/updateApp',
            name: 'updateApp',
            success: this.success,
          },
          app,
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.$validator.reset();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
