<template>
  <div>
    <modal-action id="UpdateAgent" :show-modal="showUpdateAgentModal" @close="closeModalUpdateAgent()"> <update-agent-action :agent="currentAgent" @close="closeModalUpdateAgent" @action="reloadPage" /> </modal-action>
    <modal-action id="AttachAgent" :show-modal="showAttachProgramModal" @close="closeModalAttachProgram()"> <attach-program-action :agent-key="agentKey" :programs="agentPrograms" @close="closeModalAttachProgram" @action="reloadPage" /> </modal-action>
    <modal-action id="EditAgent" :show-modal="showEditPermissionsModal" @close="closeModalEditPermissions()">
      <edit-permissions-action :agent-key="agentKey" :program="currentProgram" @close="closeModalEditPermissions" @action="reloadPage" />
    </modal-action>
    <modal-confirm
      :show-modal="showRemoveProgramModal"
      :title="$t('agent.remove_program_action.title')"
      context="RemoveProgramFromAgentConfirmationModal"
      @submit="removeProgramFromAgent()"
      @close="closeModalRemoveProgram"
      :text="$t('agent.remove_program_action.text')"
      cancel_text="agent.remove_program_action.button_cancel"
      submit_text="agent.remove_program_action.button_save"
      :submit_working="working"
    ></modal-confirm>
    <modal-confirm
      :show-modal="showDeleteAgentModal"
      :title="$t('agent.delete_agent_action.title')"
      context="DeleteAgentConfirmationModal"
      @submit="deleteAgent()"
      @close="closeModalDeleteAgent"
      :text="$t('agent.delete_agent_action.text')"
      cancel_text="agent.delete_agent_action.button_cancel"
      submit_text="agent.delete_agent_action.button_save"
      :submit_working="working"
    ></modal-confirm>
    <modal-confirm
      :show-modal="showUndeleteAgentModal"
      :title="$t('agent.undelete_agent_action.title')"
      context="UndeleteAgentConfirmationModal"
      @submit="undeleteAgent()"
      @close="closeModalUndeleteAgent"
      :text="$t('agent.undelete_agent_action.text')"
      cancel_text="agent.undelete_agent_action.button_cancel"
      submit_text="agent.undelete_agent_action.button_save"
      :submit_working="working"
    ></modal-confirm>
    <table class="agents-table responsive-table">
      <thead>
        <tr>
          <td>{{ $t('agent.listTable.name') }}</td>
          <td>{{ $t('agent.listTable.email') }}</td>
          <td>{{ $t('agent.listTable.mobile') }}</td>
          <td>{{ $t('agent.listTable.team') }}</td>
          <td>{{ $t('agent.listTable.programs') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="agents-table--name">
            <h5>{{ $t('agent.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.first_name }">{{ item.first_name | orNone }} {{ item.last_name | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('agent.listTable.email') }}:</h5>
            <span :class="{ 'info-not-available': !item.email }">{{ item.email | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('agent.listTable.mobile') }}:</h5>
            <span :class="{ 'info-not-available': !item.mobile_number }">{{ item.mobile_number | phoneNumber }}</span>
          </td>
          <td>
            <h5>{{ $t('agent.listTable.team') }}:</h5>
            <span :class="{ 'info-not-available': !item.team }">{{ item.team | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('agent.listTable.programs') }}:</h5>
            <template v-if="hasPrograms(item.programs)">
              <div v-for="(program, indexp) in item.programs" :key="indexp" class="agents-table--program">
                <span v-if="program.name" class="agents-table--program_name">{{ program.name }}</span
                ><span v-if="program.currency">({{ program.currency }})</span>
                <v-spacer></v-spacer>
                <button
                  @click="
                    () => {
                      passAgentKeyToModal(item.key);
                      passProgramToModal(program);
                      showModalEditPermissions();
                    }
                  "
                  class="agents-table--edit-program"
                >
                  <v-icon>fas fa-pencil</v-icon>
                </button>
                <button
                  @click="
                    () => {
                      passAgentKeyToModal(item.key);
                      passProgramKeyToModal(program.key);
                      showModalRemoveProgram();
                    }
                  "
                  class="agents-table--remove-program"
                >
                  <v-icon>fas fa-trash-alt</v-icon>
                </button>
              </div>
            </template>
            <span
              class="agents-table--assign-program"
              aria-role="button"
              @click="
                () => {
                  passAgentKeyToModal(item.key);
                  passAgentProgramsToModal(item.programs);
                  showModalAttachProgram();
                }
              "
              >{{ $t('agent.listTable.program.assign') }}</span
            >
          </td>
          <td>
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                () => {
                  passAgentToModal(item);
                  showModalUpdateAgent();
                }
              "
              class="agents-table--update-agent"
              >{{ $t(`agent.listTable.edit`) }}</v-btn
            >
            <v-btn
              v-if="!item.deleted_at"
              align-bottom
              small
              outline
              color="error"
              @click="
                () => {
                  passAgentKeyToModal(item.key);
                  showModalDeleteAgent();
                }
              "
              class="agents-table--delete-agent"
              >{{ $t(`agent.listTable.delete`) }}</v-btn
            >
            <v-btn
              v-if="item.deleted_at"
              align-bottom
              small
              outline
              color="success"
              @click="
                () => {
                  passAgentKeyToModal(item.key);
                  showModalUndeleteAgent();
                }
              "
              class="agents-table--undelete-agent"
              >{{ $t(`agent.listTable.undelete`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center"><v-pagination v-if="pageCount > 1" class="agents-table--pagination" v-model="pagination" :length="pageCount"></v-pagination></div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AttachProgramAction from './AttachProgramAction';
import EditPermissionsAction from './EditPermissionsAction';
import UpdateAgentAction from './UpdateAgentAction';

export default {
  name: 'agents-table',
  components: { AttachProgramAction, EditPermissionsAction, UpdateAgentAction },
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentAgent: {},
      pagination: 1,
      agentKey: '',
      agentPrograms: [],
      programKey: '',
      currentProgram: {},
      showAttachProgramModal: false,
      showRemoveProgramModal: false,
      showEditPermissionsModal: false,
      showUpdateAgentModal: false,
      showDeleteAgentModal: false,
      showUndeleteAgentModal: false,
    };
  },
  computed: {
    ...mapGetters('agent', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'agent/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.agentKey = '';
      this.currentAgent = {};
      this.agentPrograms = [];
      this.programKey = '';
      this.currentProgram = {};
      this.showAttachProgramModal = false;
      this.showRemoveProgramModal = false;
      this.showEditPermissionsModal = false;
      this.showUpdateAgentModal = false;
      this.showDeleteAgentModal = false;
      this.showUndeleteAgentModal = false;
    },
    hasPrograms(object) {
      return !_.isEmpty(object) ? true : false;
    },
    passAgentKeyToModal(key) {
      this.agentKey = key;
    },
    passAgentToModal(agent) {
      this.currentAgent = agent;
    },
    passProgramKeyToModal(programKey) {
      this.programKey = programKey;
    },
    passAgentProgramsToModal(programs) {
      this.agentPrograms = programs;
    },
    passProgramToModal(program) {
      this.currentProgram = program;
    },
    async removeProgramFromAgent() {
      await this.executeAction(
        {
          action: 'agent/removeProgramFromAgent',
          success: this.removeProgramSuccess,
        },
        {
          key: this.agentKey,
          programKey: this.programKey,
        },
      );
    },
    removeProgramSuccess() {
      this.reloadPage();
    },
    async deleteAgent() {
      await this.executeAction(
        {
          action: 'agent/deleteAgent',
          success: this.deleteAgentSuccess,
        },
        this.agentKey,
      );
    },
    deleteAgentSuccess() {
      this.reloadPage();
    },
    async undeleteAgent() {
      await this.executeAction(
        {
          action: 'agent/undeleteAgent',
          success: this.undeleteAgentSuccess,
        },
        this.agentKey,
      );
    },
    undeleteAgentSuccess() {
      this.reloadPage();
    },
    showModalAttachProgram() {
      this.showAttachProgramModal = true;
    },
    closeModalAttachProgram() {
      this.showAttachProgramModal = false;
    },
    showModalRemoveProgram() {
      this.showRemoveProgramModal = true;
    },
    closeModalRemoveProgram() {
      this.showRemoveProgramModal = false;
    },
    showModalEditPermissions() {
      this.showEditPermissionsModal = true;
    },
    closeModalEditPermissions() {
      this.showEditPermissionsModal = false;
    },
    showModalUpdateAgent() {
      this.showUpdateAgentModal = true;
    },
    closeModalUpdateAgent() {
      this.showUpdateAgentModal = false;
    },
    showModalDeleteAgent() {
      this.showDeleteAgentModal = true;
    },
    closeModalDeleteAgent() {
      this.showDeleteAgentModal = false;
    },
    showModalUndeleteAgent() {
      this.showUndeleteAgentModal = true;
    },
    closeModalUndeleteAgent() {
      this.showUndeleteAgentModal = false;
    },
  },
};
</script>
