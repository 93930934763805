import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';

export default {
  namespaced: true,
  state: {
    alerts: [],
    cardProgramKey: '',
  },
  mutations: {
    alerts(state, alerts) {
      state.alerts = alerts;
    },
    cardProgramKey(state, cardProgramKey) {
      state.cardProgramKey = cardProgramKey;
    },
  },
  getters: {
    alerts: (state) => state.alerts,
  },
  actions: {
    async getAlerts({ commit }) {
      const alertService = Vue.prototype.$services.alert;

      const alerts = await alertService.listTrigger(process.env.VUE_APP_DEFAULT_PROGRAM_KEY);

      commit('cardProgramKey', process.env.VUE_APP_DEFAULT_PROGRAM_KEY);
      commit('alerts', alerts);
    },

    async updateAlert({ state, dispatch }, { id, alert }) {
      // validate params?
      const alertService = Vue.prototype.$services.alert;

      const defaultDispatch = safeDispatcher(dispatch);
      await alertService.updateTrigger({ cardProgramKey: state.cardProgramKey, id }, alert);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'alert.update_alert_action.success' });

      await defaultDispatch('alert/getAlerts');
    },
  },
};
