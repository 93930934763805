import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,

  state: {
    currentSubscriptionPlan: {},
    subscriptionPlans: [],
    pageNumber: defaultPage,
    pageCount: 0,
  },

  getters: {
    subscriptionPlans: (state) => state.subscriptionPlans,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },

  mutations: {
    partner(state, partner) {
      state.partner = partner;
    },
    subscriptionPlans(state, plans) {
      state.subscriptionPlans = plans;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listSubscriptionPlans({ commit }, { page, limit }) {
      const subscriptionPlanService = Vue.prototype.$services.subscriptionPlan;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const planList = await subscriptionPlanService.listSubscriptionPlans(thePage, theLimit);

      commit('page', thePage);
      commit('pageCount', planList.page_count);
      commit('subscriptionPlans', planList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('subscriptionPlan/listSubscriptionPlans', { page });
    },

    async createSubscriptionPlan({ dispatch }, plan) {
      const subscriptionPlanService = Vue.prototype.$services.subscriptionPlan;
      const defaultDispatch = safeDispatcher(dispatch);

      await subscriptionPlanService.createSubscriptionPlan(plan);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'subscription_plan.add_action.success' });
      await defaultDispatch('subscriptionPlan/listSubscriptionPlans', {});
    },
  },
};
