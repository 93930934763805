<template>
  <div>
    <table class="responsive-table smoke-branding-category--list-table">
      <thead>
        <tr>
          <td>{{ $t('branding_category.listTable.id') }}</td>
          <td>{{ $t('branding_category.listTable.name.fr') }}</td>
          <td>{{ $t('branding_category.listTable.name.en') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="smoke-branding-category--list-table--id-column">
            <h5>{{ $t('branding_category.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td class="smoke-branding-category--list-table--name-fr-column">
            <h5>{{ $t('branding_category.listTable.name.fr') }}:</h5>
            <span :class="{ 'info-not-available': !item.name && !item.name.fr }">{{ item.name.fr | orNone }}</span>
          </td>
          <td class="smoke-branding-category--list-table--name-en-column">
            <h5>{{ $t('branding_category.listTable.name.en') }}:</h5>
            <span :class="{ 'info-not-available': !item.name && !item.name.en }">{{ item.name.en | orNone }}</span>
          </td>
          <td class="smoke-branding-category--list-table--actions-column">
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passBrandingCategoryToModal(item);
                showModalUpdateBrandingCategory();
              "
              class="smoke-branding-category--list-table--edit-button"
              >{{ $t(`branding_category.listTable.edit`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdateBrandingCategoryModal"> <update-branding-category-action :branding-category="currentBrandingCategory" @close="closeModalUpdateBrandingCategory" @action="reloadPage" /> </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="branding-categories-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdateBrandingCategoryAction from './UpdateBrandingCategoryAction';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-category-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateBrandingCategoryAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentBrandingCategory: {},
      pagination: 1,
      showUpdateBrandingCategoryModal: false,
    };
  },
  computed: {
    ...mapGetters('brandingCategory', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'brandingCategory/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showUpdateBrandingCategoryModal = false;
    },
    passBrandingCategoryToModal(brandingCategory) {
      this.currentBrandingCategory = brandingCategory;
    },
    showModalUpdateBrandingCategory() {
      this.showUpdateBrandingCategoryModal = true;
    },
    closeModalUpdateBrandingCategory() {
      this.showUpdateBrandingCategoryModal = false;
    },
  },
};
</script>
