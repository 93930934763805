<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('space.add_action.title')"
    context="addSpace"
    @submit="submit"
    @cancel="cancel"
    cancel_text="space.add_action.button_cancel"
    submit_text="space.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-space--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.name"
            v-model="space.name"
            :label="$t('space.add_action.fields.name')"
            :error-messages="errors.collect('space.name')"
            data-vv-name="space.name"
            :data-vv-as="$t('space.add_action.fields.name')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-space--add--name"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.slug"
            v-model="space.slug"
            :label="$t('space.add_action.fields.slug')"
            :error-messages="errors.collect('space.slug')"
            data-vv-name="space.slug"
            :data-vv-as="$t('space.add_action.fields.slug')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-space--add--slug"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.partner_id"
            v-model="space.partner_id"
            :label="$t('space.add_action.fields.partner_id')"
            :error-messages="errors.collect('space.partner_id')"
            data-vv-name="space.partner_id"
            :data-vv-as="$t('space.add_action.fields.partner_id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-space--add--partner-id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.default_language"
            v-model="space.default_language"
            :label="$t('space.add_action.fields.default_language')"
            :error-messages="errors.collect('space.default_language')"
            data-vv-name="space.default_language"
            :data-vv-as="$t('space.add_action.fields.default_language')"
            v-validate="'max:2'"
            required
            counter
            maxlength="2"
            class="smoke-space--add--default-language"
          />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="space.private_space" :label="$t('space.add_action.fields.private_space')" class="smoke-partner--add--private-space" />
        </v-flex>
        <v-flex xs12>
          <h-autocomplete
            v-model="space.branding_id"
            :label="$t('space.add_action.fields.branding')"
            v-validate="'required'"
            :error-messages="errors.collect('branding_id')"
            name="branding_id"
            data-vv-name="branding_id"
            :data-vv-as="$t('space.add_action.fields.branding')"
            search-action="branding/autocompleteSearch"
          />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-space-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      space: {
        private_space: false,
      },
    };
  },
  methods: {
    resetForm() {
      this.space = { private_space: false };
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'space/createSpace',
            name: 'addSpace',
            success: this.success,
          },
          { space: this.space },
        );
      }
      return true;
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
