<template>
  <v-flex>
    <h1 hidden>{{ $t('alert.list.title') }}</h1>
    <v-card> </v-card>
    <v-flex class="data-section" v-if="dataReady">
      <template v-for="alert in alerts">
        <view-alert v-if="alert" :alert="alert" :key="alert.id" />
      </template>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import ViewAlert from './ViewAlert';

export default {
  name: 'view-alert-list',
  mixins: [actionErrorTrackable, security],
  components: { ViewAlert },
  props: {
    walletDefinitionId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dataReady: false,
    };
  },
  async mounted() {
    await this.fetchAlerts();
  },
  computed: {
    ...mapGetters('alert', ['alerts']),
  },
  methods: {
    async fetchAlerts() {
      await this.executeAction({ action: 'alert/getAlerts' });
      this.dataReady = true;
    },
  },
};
</script>
