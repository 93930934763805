import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    authenticated: false,
    currentUser: {},
    currentAgent: {},
    currentPermissions: [],
    startupPath: null,
    appNames: {},
  },
  getters: {
    authenticated: (state) => state.authenticated,
    currentUser: (state) => state.currentUser,
    currentPermissions: (state) => state.currentPermissions,
    hasPermission: (state) => (permission) => state.currentPermissions.includes(permission),
    startupPath: (state) => state.startupPath,
  },
  mutations: {
    authenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    currentUser(state, user) {
      state.currentUser = user;
    },
    currentAgent(state, agent) {
      state.currentAgent = agent;
    },
    currentPermissions(state, permissions) {
      state.currentPermissions = permissions;
    },
    startupPath(state, startupPath) {
      state.startupPath = startupPath;
    },
    appNames(state, appNames) {
      state.appNames = appNames;
    },
  },
  actions: {
    async getAppName({ state, commit }, key) {
      const appService = Vue.prototype.$services.app;

      if (state.appNames[key]) {
        return state.appNames[key];
      }

      try {
        const app = await appService.getAppName(key);
        if (app.apps) {
          state.appNames[app.apps[0].key] = app.apps[0].name;
          commit('appNames', state.appNames);
          return app.apps[0].name;
        }
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
        return null;
      }

      return null;
    },
    async login({ commit }, user) {
      const securityService = Vue.prototype.$services.security;

      commit('authenticated', true);
      commit('currentUser', user);

      Vue.prototype.$axios.defaults.headers.common.Authorization = `Bearer ${await Vue.prototype.$auth.getAccessToken()}`;

      const agent = await securityService.me();
      if (agent) {
        commit('currentAgent', agent);
        commit('currentPermissions', agent.permissions);

        const redirectPath = Vue.prototype.$cookies.getRedirectPathCookie();

        let startupPath = '/no-access';
        if (redirectPath) {
          console.log('Redirect to', redirectPath); // eslint-disable-line no-console
          startupPath = redirectPath;
        } else {
          startupPath = '/status';
        }

        Vue.prototype.$cookies.deleteRedirectPathCookie();
        commit('startupPath', startupPath);
      }
    },

    async logout({ commit }) {
      try {
        await Vue.prototype.$auth.logout();
      } catch (err) {
        console.log('CATCH ERROR', err); // eslint-disable-line no-console
      }
      commit('authenticated', false);
      commit('currentUser', {});
      commit('currentPermissions', []);
      commit('currentAgent', {});
      Vue.prototype.$axios.defaults.headers.common.Authorization = null;
    },

    async checkSecurityStatus({ state, dispatch }) {
      const authenticated = await Vue.prototype.$auth.isAuthenticated();
      if (authenticated) {
        if (!state.authenticated) {
          await dispatch('login', await Vue.prototype.$auth.getUser());
        }
      } else {
        if (state.authenticated) {
          // eslint-disable-line no-lonely-if
          await dispatch('logout');
        }
      }
    },
  },
};
