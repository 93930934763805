<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('space.update_action.title')"
    context="updateSpace"
    @submit="submit"
    @cancel="cancel"
    cancel_text="space.update_action.button_cancel"
    submit_text="space.update_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-space--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.name"
            v-model="currentSpace.name"
            :label="$t('space.update_action.fields.name')"
            :error-messages="errors.collect('space.name')"
            data-vv-name="space.name"
            :data-vv-as="$t('space.update_action.fields.name')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-space--update--name"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.slug"
            v-model="currentSpace.slug"
            :label="$t('space.update_action.fields.slug')"
            :error-messages="errors.collect('space.slug')"
            data-vv-name="space.slug"
            :data-vv-as="$t('space.update_action.fields.slug')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-space--update--slug"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.partner_id"
            v-model="currentSpace.partner_id"
            :label="$t('space.update_action.fields.partner_id')"
            :error-messages="errors.collect('space.partner_id')"
            data-vv-name="space.partner_id"
            :data-vv-as="$t('space.update_action.fields.partner_id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-space--update--partner-id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="space.default_language"
            v-model="currentSpace.default_language"
            :label="$t('space.update_action.fields.default_language')"
            :error-messages="errors.collect('space.default_language')"
            data-vv-name="space.default_language"
            :data-vv-as="$t('space.update_action.fields.default_language')"
            v-validate="'max:2'"
            required
            counter
            maxlength="2"
            class="smoke-space--update--default-language"
          />
        </v-flex>
        <v-flex xs12>
          <v-checkbox v-model="currentSpace.private_space" :label="$t('space.update_action.fields.private_space')" class="smoke-partner--update--private-space" />
        </v-flex>
        <v-flex xs12>
          <h-autocomplete
            v-model="currentSpace.branding_id"
            :label="$t('space.add_action.fields.branding')"
            v-validate="'required'"
            :error-messages="errors.collect('branding_id')"
            name="branding_id"
            data-vv-name="branding_id"
            :data-vv-as="$t('space.add_action.fields.branding')"
            search-action="branding/autocompleteSearch"
          />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-space-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    space: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentSpace: {},
    };
  },
  computed: {
    ...mapGetters('space', ['getSpace']),
    isFormChanged() {
      const changed = Object.keys(this.fields).some((key) => this.fields[key].changed);
      const privateSpaceChanged = this.currentSpace.private_space !== this.space.private_space;

      return changed || privateSpaceChanged;
    },
  },
  watch: {
    space(value) {
      this.currentSpace = _.cloneDeep(value);
    },
  },
  methods: {
    resetForm() {
      this.currentSpace = _.cloneDeep(this.getSpace(this.space.id));
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'space/updateSpace',
            name: 'updateSpace',
            success: this.success,
          },
          { space: this.currentSpace },
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
