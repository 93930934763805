import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css';
import '../styles/main.styl';
// import colors from 'vuetify/es5/util/colors'; // Uncomment to use the material color names

export default ({ Vue }) => {
  Vue.use(Vuetify, {
    theme: {
      primary: '#0580c4', //default: #0096C3
      secondary: '#c8cdc8',
      accent: '#08c341',
      error: '#f05032',
      info: '#0082C3',
      success: '#08c341',
      warning: '#FFC107',
    },
  });
};
