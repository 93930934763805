<template>
  <v-flex>
    <h1 hidden>{{ $t('app.form.title') }}</h1>
    <v-card>
      <form-search :input-label="'app.form.label'" :submit-text="'app.form.submit'" @submit="submit" :updated-keyword="keyword" :max-length="50">
        <template slot="buttons"><button-primary class="app-form--btn-add" :loading="working" :text="$t('app.form.add')" @click="showModalAddApp"></button-primary> </template>
      </form-search>
    </v-card>

    <modal-action :show-modal="showAddAppModal"> <add-app-action @close="closeModalAddApp" @action="reloadPage" :apps="appsFromSearch"></add-app-action> </modal-action>

    <v-flex class="data-section"> <apps-list v-if="dataReady && appsFromSearch" :items="appsFromSearch"></apps-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddAppAction from './AddAppAction';
import AppsList from './AppsList';

export default {
  name: 'view-app-form',
  mixins: [actionErrorTrackable, security],
  components: { AppsList, AddAppAction },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddAppModal: false,
    };
  },
  computed: {
    ...mapGetters('app', ['appsFromSearch', 'currentKeyword']),
  },
  async created() {
    await this.fetchApps();
  },
  watch: {
    currentKeyword() {
      this.keyword = this.currentKeyword;
    },
  },
  methods: {
    async submit(searchTerm) {
      this.keyword = searchTerm;

      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.clearAllError();
        this.fetchApps();
      }
    },
    async fetchApps() {
      this.dataReady = false;
      await this.executeAction(
        {
          action: 'app/searchApps',
          success: this.success,
        },
        {
          keyword: this.keyword,
        },
      );
      this.dataReady = true;
    },
    showModalAddApp() {
      this.showAddAppModal = true;
    },
    closeModalAddApp() {
      this.showAddAppModal = false;
    },
    reloadPage() {
      this.showAddAppModal = false;
    },
  },
};
</script>
