<template>
  <div>
    <table class="responsive-table smoke-space--list-table">
      <thead>
        <tr>
          <td>{{ $t('space.listTable.id') }}</td>
          <td>{{ $t('space.listTable.name') }}</td>
          <td>{{ $t('space.listTable.slug') }}</td>
          <td>{{ $t('space.listTable.partner_id') }}</td>
          <td>{{ $t('space.listTable.default_language') }}</td>
          <td>{{ $t('space.listTable.private_space') }}</td>
          <td>{{ $t('space.listTable.branding_id') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="smoke-space--list-table--id-column">
            <h5>{{ $t('space.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td class="smoke-space--list-table--name-column">
            <h5>{{ $t('space.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td class="smoke-space--list-table--slug-column">
            <h5>{{ $t('space.listTable.slug') }}:</h5>
            <span :class="{ 'info-not-available': !item.slug }">{{ item.slug | orNone }}</span>
          </td>
          <td class="smoke-space--list-table--partner-id-column">
            <h5>{{ $t('space.listTable.partner_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.partner_id }">{{ item.partner_id | orNone }}</span>
          </td>
          <td class="smoke-space--list-table--default-language-column">
            <h5>{{ $t('space.listTable.default_language') }}:</h5>
            <span :class="{ 'info-not-available': !item.default_language }">{{ item.default_language | orNone }}</span>
          </td>
          <td class="smoke-space--list-table--private-space-column">
            <h5>{{ $t('space.listTable.private_space') }}:</h5>
            <span>{{ item.private_space }}</span>
          </td>
          <td class="smoke-space--list-table--branding-id-column">
            <h5>{{ $t('space.listTable.branding_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.branding_id }">{{ item.branding_id | orNone }}</span>
          </td>
          <td class="smoke-space--list-table--actions-column">
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passSpaceToModal(item);
                showModalUpdateSpace();
              "
              class="smoke-space--list-table--edit-button"
              >{{ $t(`space.listTable.edit`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdateSpaceModal"> <update-space-action :space="currentSpace" @close="closeModalUpdateSpace" @action="reloadPage" /> </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="spaces-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdateSpaceAction from './UpdateSpaceAction';
import { mapGetters } from 'vuex';

export default {
  name: 'space-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateSpaceAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSpace: {},
      pagination: 1,
      showUpdateSpaceModal: false,
    };
  },
  computed: {
    ...mapGetters('space', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'space/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showUpdateSpaceModal = false;
    },
    passSpaceToModal(space) {
      this.currentSpace = space;
    },
    showModalUpdateSpace() {
      this.showUpdateSpaceModal = true;
    },
    closeModalUpdateSpace() {
      this.showUpdateSpaceModal = false;
    },
  },
};
</script>
