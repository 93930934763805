<template>
  <view-main title="page_title.noAccess">
    <div slot="content">
      <v-alert type="warning" transition="scale-transition" :value="true"> {{ $t('pageNotFound.message') }} </v-alert>
    </div>
  </view-main>
</template>

<script>
export default {
  name: 'no-access-view',
};
</script>
