<template>
  <view-main title="page_title.app"> <view-app-form slot="content"></view-app-form> </view-main>
</template>

<script>
import ViewAppForm from './ViewAppForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'app-view',
  mixins: [renderErrorTrackable],
  components: { ViewAppForm },
};
</script>
