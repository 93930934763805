<template>
  <v-data-table :headers="headers" :items="items" hide-actions class="table-basic elevation-2">
    <template slot="items" slot-scope="props">
      <td v-for="key in Object.keys(props.item)" :key="key" class="text-xs-left">{{ props.item[key] }}</td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'data-table',
  props: {
    headers: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
    },
  },
};
</script>
