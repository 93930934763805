import _get from 'lodash/get';

export default ({ Vue, app }) => {
  Vue.filter('state', (text) => {
    const state = {
      AB: app.i18n.t('state.alberta'),
      BC: app.i18n.t('state.british_columbia'),
      MB: app.i18n.t('state.manitoba'),
      NB: app.i18n.t('state.new_brunswick'),
      NL: app.i18n.t('state.newfoundland_labrador'),
      NS: app.i18n.t('state.nova_scotia'),
      NT: app.i18n.t('state.northwest_territories'),
      NU: app.i18n.t('state.nunavut'),
      ON: app.i18n.t('state.ontario'),
      PE: app.i18n.t('state.prince_edward_island'),
      QC: app.i18n.t('state.quebec'),
      SK: app.i18n.t('state.sk'),
      YT: app.i18n.t('state.yukon'),
    };

    return _get(state, text, text);
  });
};
