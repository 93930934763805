import VeeValidate, { Validator } from 'vee-validate';
import fr from '../i18n/vee-validate/fr';
import rangeCheck from 'range_check';

export default ({ Vue }) => {
  Validator.localize('fr', fr);
  Validator.localize('en');

  Vue.use(VeeValidate);

  // To do: translate messages (i18n)
  const validateRange = {
    getMessage(field, params, data) {
      return (data && data.message) || 'Something went wrong';
    },
    validate(value) {
      return new Promise((resolve) => {
        resolve({
          valid: rangeCheck.isRange(value),
          data: { message: `${value} is not in range` },
        });
      });
    },
  };

  VeeValidate.Validator.extend('validateRange', validateRange);
};
