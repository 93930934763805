<template>
  <v-flex>
    <h1 hidden>{{ $t('virtual_money.list.title') }}</h1>

    <v-card class="form-basic pa-2">
      <button-primary :loading="working" :text="$t('virtual_money.form.add')" @click="showModalAddVirtualMoney"></button-primary>
    </v-card>

    <modal-action :show-modal="showAddVirtualMoneyModal">
      <add-virtual-money-action @close="closeModalAddVirtualMoney" @action="reloadPage"></add-virtual-money-action>
    </modal-action>

    <v-flex class="data-section">
      <virtual-money-list v-if="dataReady && virtualMoneys" :items="virtualMoneys"></virtual-money-list>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddVirtualMoneyAction from './AddVirtualMoneyAction';
import VirtualMoneyList from './VirtualMoneyList';

export default {
  name: 'view-virtual-money-form',
  mixins: [actionErrorTrackable, security],
  components: { AddVirtualMoneyAction, VirtualMoneyList },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddVirtualMoneyModal: false,
    };
  },
  computed: {
    ...mapGetters('virtualMoney', ['virtualMoneys']),
  },
  async created() {
    await this.fetchVirtualMoneys();
  },
  methods: {
    async fetchVirtualMoneys() {
      this.dataReady = false;
      await this.executeAction({ action: 'virtualMoney/listVirtualMoneys', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddVirtualMoney() {
      this.showAddVirtualMoneyModal = true;
    },
    closeModalAddVirtualMoney() {
      this.showAddVirtualMoneyModal = false;
    },
    reloadPage() {
      this.showAddVirtualMoneyModal = false;
    },
  },
};
</script>
