export default ({ Vue, app }) => {
  Vue.filter('orNotAvailable', (text) => {
    if (!text || text === '<NO VALUE>') {
      return app.i18n.t('system.na');
    }
    return text;
  });

  Vue.filter('phoneNumber', (text) => {
    if (!text) {
      return app.i18n.t('system.none');
    }
    let cleaned = ('' + text).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return text;
  });

  Vue.filter('orNone', (text) => {
    if (!text || text === '<NO VALUE>') {
      return app.i18n.t('system.none');
    }
    return text;
  });
};
