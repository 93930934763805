import Vue from 'vue';

const service = {
  async listGroups(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generateListGroupsPath(page, limit)}`);
    return response.data.data;
  },

  async createGroup(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/control-access-groups/v1`, payload);
    return response.data.data;
  },

  async updateGroup(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/control-access-groups/v1/${id}`, payload);
    return response.data.data;
  },
};

function generateListGroupsPath(page, limit) {
  let path = '/control-access-groups/v1';
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
