<template>
  <v-alert class="error-banner" v-model="show" type="error" transition="scale-transition" icon="fa-exclamation-triangle" @click="showStack">
    <v-layout column>
      <v-layout row align-center>
        <h2>{{ text }}</h2>
        <v-spacer></v-spacer>
        <div v-if="stack">
          <div v-if="showErrorStack" key="down"><v-icon>fa-angle-down</v-icon></div>
          <div v-else key="up"><v-icon>fa-angle-up</v-icon></div>
        </div>
      </v-layout>
      <span v-if="showErrorStack && stack" class="h6" v-html="displayErrorStackUserFriendly"></span>
    </v-layout>
  </v-alert>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'alert-app-error',
  props: {
    stack: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayErrorStackUserFriendly() {
      return `<v-layout column>${_.reduce(
        this.stack.split('\n'),
        (acc, e) => {
          acc += `<div>${e}</div>`;
          return acc;
        },
        '',
      )}</v-layout>`;
    },
  },
  data() {
    return {
      show: true,
      showErrorStack: false,
    };
  },
  methods: {
    showStack() {
      this.showErrorStack = !this.showErrorStack;
    },
  },
};
</script>
