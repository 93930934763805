<template>
  <card-modal
    icon=""
    :title="$t('alert.update_alert_action.title')"
    context="updateAlert"
    @submit="submit"
    @cancel="cancel"
    cancel_text="alert.update_alert_action.button_cancel"
    submit_text="alert.update_alert_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12>
            <v-select
              name="webhook"
              v-model="currentAlert.webhook.active"
              v-validate="{ required: true, max: 5 }"
              :error-messages="errors.collect('webhook')"
              data-vv-name="webhook"
              :data-vv-as="$t(`alert.update_alert_action.fields.webhook`)"
              :items="webhookOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`alert.update_alert_action.fields.webhook`)"
              class="update-alert--webhook"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout column style="margin-left: 20px">
          <v-layout column v-if="currentAlert.webhook.active === true">
            <v-flex xs12>
              <v-text-field
                name="webhook_url"
                v-model="currentAlert.webhook.uri"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('webhook_url')"
                :label="$t('alert.update_alert_action.fields.webhook_url')"
                data-vv-name="webhook_url"
                :data-vv-as="$t(`alert.update_alert_action.fields.webhook_url`)"
                class="update-alert--url"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                name="authorization_header"
                v-model="currentAlert.webhook.authorization"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('authorization_header')"
                :label="$t('alert.update_alert_action.fields.authorization_header')"
                data-vv-name="authorization_header"
                :data-vv-as="$t(`alert.update_alert_action.fields.authorization_header`)"
                class="update-alert--authorization-header"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout column>
          <v-flex sm6 xs12>
            <v-select
              name="sms"
              v-model="currentAlert.sms.active"
              v-validate="{ required: true, max: 5 }"
              :error-messages="errors.collect('sms')"
              data-vv-name="sms"
              :data-vv-as="$t(`alert.update_alert_action.fields.sms`)"
              :items="smsOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`alert.update_alert_action.fields.sms`)"
              class="update-alert--sms"
            ></v-select>
          </v-flex>
          <v-layout column style="margin-left: 20px" v-if="currentAlert.sms.active === true">
            <v-flex xs12>
              <v-textarea
                name="message_en"
                v-model="currentAlert.message.en"
                v-validate="'required|max:1000'"
                :error-messages="errors.collect('message_en')"
                :label="$t('alert.update_alert_action.fields.message_en')"
                data-vv-name="message_en"
                :data-vv-as="$t(`alert.update_alert_action.fields.message_en`)"
                auto-grow
                rows="1"
                class="update-alert--message-en"
              ></v-textarea>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                rows="1"
                name="message_fr"
                v-model="currentAlert.message.fr"
                v-validate="'required|max:1000'"
                :error-messages="errors.collect('message_fr')"
                :label="$t('alert.update_alert_action.fields.message_fr')"
                data-vv-name="message_fr"
                :data-vv-as="$t(`alert.update_alert_action.fields.message_fr`)"
                auto-grow
                class="update-alert--message-fr"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout column>
          <v-flex sm6 xs12>
            <v-select
              name="email"
              v-model="currentAlert.email.active"
              v-validate="{ required: true, max: 5 }"
              :error-messages="errors.collect('email')"
              data-vv-name="email"
              :data-vv-as="$t(`alert.update_alert_action.fields.email`)"
              :items="emailOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`alert.update_alert_action.fields.email`)"
              class="update-alert--email"
            ></v-select>
          </v-flex>
          <v-layout column style="margin-left: 20px" v-if="currentAlert.email.active === true">
            <v-flex xs12>
              <v-textarea
                name="template_id_en"
                v-model="currentAlert.email.template_id.en"
                v-validate="'required|max:1000'"
                :error-messages="errors.collect('template_id_en')"
                :label="$t('alert.update_alert_action.fields.email_fields.template_id_en')"
                data-vv-name="template_id_en"
                :data-vv-as="$t(`alert.update_alert_action.fields.email_fields.template_id_en`)"
                auto-grow
                rows="1"
                class="update-alert--email-template-id-en"
              ></v-textarea>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                name="template_id_fr"
                v-model="currentAlert.email.template_id.fr"
                v-validate="'max:1000'"
                :error-messages="errors.collect('template_id_fr')"
                :label="$t('alert.update_alert_action.fields.email_fields.template_id_fr')"
                data-vv-name="template_id_fr"
                :data-vv-as="$t(`alert.update_alert_action.fields.email_fields.template_id_fr`)"
                auto-grow
                rows="1"
                class="update-alert--email-template-id-fr"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout column>
          <v-flex sm6 xs12>
            <v-select
              name="push"
              v-model="currentAlert.push.active"
              v-validate="{ required: true, max: 5 }"
              :error-messages="errors.collect('push')"
              data-vv-name="push"
              :data-vv-as="$t(`alert.push.fields.push`)"
              :items="pushOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`alert.update_alert_action.fields.push`)"
              class="update-alert--push"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout column v-if="currentAlert.push.active" style="margin-left: 20px">
          <v-flex xs12>
            <v-textarea
              name="push_title_en"
              v-model="currentAlert.push.title.en"
              v-validate="'required|max:1000'"
              :error-messages="errors.collect('push_title_en')"
              :label="$t('alert.update_alert_action.fields.push_fields.title_en')"
              data-vv-name="push_title_en"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.title_en`)"
              auto-grow
              rows="1"
              class="update-alert--push-title-en"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              name="push_message_en"
              v-model="currentAlert.push.message.en"
              v-validate="'required|max:1000'"
              :error-messages="errors.collect('push_message_en')"
              :label="$t('alert.update_alert_action.fields.message_en')"
              data-vv-name="push_message_en"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.message_en`)"
              auto-grow
              rows="1"
              class="update-alert--push-message-en"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              rows="1"
              name="push_title_fr"
              v-model="currentAlert.push.title.fr"
              v-validate="'required|max:1000'"
              :error-messages="errors.collect('push_title_fr')"
              :label="$t('alert.update_alert_action.fields.push_fields.title_fr')"
              data-vv-name="push_title_fr"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.title_fr`)"
              auto-grow
              class="update-alert--push-title-fr"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              rows="1"
              name="push_message_fr"
              v-model="currentAlert.push.message.fr"
              v-validate="'required|max:1000'"
              :error-messages="errors.collect('push_message_fr')"
              :label="$t('alert.update_alert_action.fields.message_fr')"
              data-vv-name="push_message_fr"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.message_fr`)"
              auto-grow
              class="update-alert--push-message-fr"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              rows="1"
              name="push_data"
              :label="$t('alert.update_alert_action.fields.push_fields.data')"
              auto-grow
              v-validate="'max:1000'"
              :error-messages="errors.collect('push_data')"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.data`)"
              data-vv-name="push_data"
              v-model="currentAlert.push.data"
              class="update-alert--push-data"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              rows="1"
              name="push_android"
              :label="$t('alert.update_alert_action.fields.push_fields.android')"
              auto-grow
              data-vv-name="push_android"
              :error-messages="errors.collect('push_android')"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.android`)"
              v-validate="'max:1000'"
              v-model="currentAlert.push.android"
              class="update-alert--push-android"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              rows="1"
              name="push_apns"
              :label="$t('alert.update_alert_action.fields.push_fields.apns')"
              auto-grow
              data-vv-name="push_apns"
              :error-messages="errors.collect('push_apns')"
              :data-vv-as="$t(`alert.update_alert_action.fields.push_fields.apns`)"
              v-validate="'max:1000'"
              class="update-alert--push-apns"
              v-model="currentAlert.push.apns"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
    <button-primary slot="button-zone--left" link="https://develop.hellonetwork.ca/docs/alert" target="_blank" :text="$t('alert.update_alert_action.help')"></button-primary>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import * as _set from 'lodash/set';
import * as _get from 'lodash/get';

export default {
  name: 'update-alert',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentAlert: mapAlert(this.alert),
      webhookOptions: [
        { name: this.$t('code.alert.webhook.true'), abbr: true },
        { name: this.$t('code.alert.webhook.false'), abbr: false },
      ],
      smsOptions: [
        { name: this.$t('code.alert.sms.true'), abbr: true },
        { name: this.$t('code.alert.sms.false'), abbr: false },
      ],
      pushOptions: [
        { name: this.$t('code.alert.push.true'), abbr: true },
        { name: this.$t('code.alert.push.false'), abbr: false },
      ],
      emailOptions: [
        { name: this.$t('code.alert.email.true'), abbr: true },
        { name: this.$t('code.alert.email.false'), abbr: false },
      ],
    };
  },
  computed: {
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    alert() {
      this.currentAlert = mapAlert(this.alert);
    },
  },
  methods: {
    async submit() {
      if (await this.$validator.validateAll()) {
        await this.executeAction(
          {
            action: 'alert/updateAlert',
            name: 'updateAlert',
            success: this.success,
          },
          {
            id: this.alert.id,
            alert: mapAlertForPayload(this.alert, this.currentAlert),
          },
        );
      }
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.$validator.reset();
      this.$emit('action');
    },
    resetForm() {
      this.currentAlert = mapAlert(this.alert);
    },
  },
};

function mapAlertForPayload(beforeUpdate, source) {
  function trySetMappings(mutatedObject, objectPath, src) {
    if (src !== '') {
      _set(mutatedObject, objectPath, src);
    } else if (src == '' && _get(beforeUpdate, objectPath)) {
      _set(mutatedObject, objectPath, null);
    }
  }

  const params = {
    message: {
      en: source.message.en,
      fr: source.message.fr,
    },
    sms: {
      active: source.sms.active,
    },
    webhook: {
      active: source.webhook.active,
      authorization: source.webhook.authorization,
      uri: source.webhook.uri,
    },
  };
  trySetMappings(params, 'email.active', source.email.active);
  trySetMappings(params, 'email.template_id.en', source.email.template_id.en);
  trySetMappings(params, 'email.template_id.fr', source.email.template_id.fr);
  trySetMappings(params, 'push.active', source.push.active);
  trySetMappings(params, 'push.title.en', source.push.title.en);
  trySetMappings(params, 'push.title.fr', source.push.title.fr);
  trySetMappings(params, 'push.message.en', source.push.message.en);
  trySetMappings(params, 'push.message.fr', source.push.message.fr);
  trySetMappings(params, 'push.data', source.push.data);
  trySetMappings(params, 'push.android', source.push.android);
  trySetMappings(params, 'push.apns', source.push.apns);
  return params;
}

function mapAlert(source) {
  return {
    message: {
      en: source.message.en,
      fr: source.message.fr,
    },
    email: {
      active: source.email.active,
      template_id: {
        fr: source.email.template_id.fr,
        en: source.email.template_id.en,
      },
    },
    sms: {
      active: source.sms.active,
    },
    webhook: {
      active: source.webhook.active,
      authorization: source.webhook.authorization,
      uri: source.webhook.uri,
    },
    push: {
      active: source.push.active,
      title: {
        en: source.push.title.en,
        fr: source.push.title.fr,
      },
      message: {
        en: source.push.message.en,
        fr: source.push.message.fr,
      },
      data: source.push.data,
      android: source.push.android,
      apns: source.push.apns,
    },
  };
}
</script>
