import { mapActions } from 'vuex';
import { createDefaultContext } from '@/utils/context/context-helper';

export default {
  data: () => {
    return { working: false };
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    ...mapActions('general', ['execute']),
    createDefaultContext: createDefaultContext,
    async executeAction(context = {}, params) {
      await this.clearError('global');
      await this.clearError(context.name);
      let res;
      try {
        this.working = true;
        res = await this.execute({ context: this.createDefaultContext(context), params });
      } catch (err) {
        res = this.addError({ contextName: 'global', error: err });
      } finally {
        this.working = false;
      }

      return res;
    },
  },
};
