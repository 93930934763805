import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

export function registerSystemComponent(Vue) {
  // Globally register all base components, because they will be used very frequently.
  const requireComponent = require.context('.', true, /[\w-]+\.vue$/);

  return requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(camelCase(fileName.replace(/^\..*\//, '').replace(/\.\w+$/, '')));

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
}
