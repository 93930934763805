<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('group.add_group_action.title')"
    context="addGroup"
    @submit="submit"
    @cancel="cancel"
    cancel_text="group.add_group_action.button_cancel"
    submit_text="group.add_group_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm12 xs12>
          <v-text-field
            name="name"
            v-model="name"
            v-validate="'required|max:50'"
            :label="$t('group.add_group_action.fields.name')"
            :error-messages="errors.collect('name')"
            data-vv-name="id"
            :data-vv-as="$t('group.add_group_action.fields.name')"
            required
            counter
            maxlength="50"
            class="add-group--name"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-layout sm12 xs12>
          <section class="checkboxes-indented-with-heading">
            <v-label>{{ $t('group.add_group_action.fields.permissions') }}</v-label>
            <div v-if="permissions" class="attach-program-action--permissions">
              <v-layout row wrap>
                <v-checkbox
                  style="width: 50%"
                  v-for="(permission, index) in permissions"
                  :key="index"
                  v-model="selectedPermissions"
                  :label="$t(`program.permissions.${permission}`)"
                  :value="permission"
                  v-validate="'required'"
                  :error-messages="errors.collect('permissions')"
                  data-vv-name="permissions"
                  :data-vv-as="$t('group.add_group_action.fields.permissions')"
                  @change="addToSelectedPermissions(permission)"
                />
              </v-layout>
            </div>
          </section>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { applyCardProgramPermissionPolicy } from '@/utils/ui/card-program-permissions-policy';

export default {
  name: 'add-group-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      dataReady: false,
      name: '',
      selectedPermissions: [],
    };
  },
  computed: {
    ...mapGetters('program', ['permissions']),
    isFormChanged() {
      return true;
      // return this.permissionSelected ? true : false;
    },
  },
  methods: {
    resetForm() {
      this.dataReady = false;
      this.name = '';
      this.selectedPermissions = [];
    },
    addToSelectedPermissions(permission) {
      let value;
      _.includes(this.selectedPermissions, permission) ? (value = true) : (value = false);
      applyCardProgramPermissionPolicy(permission, value, this.selectedPermissions);
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    async submit() {
      if (await this.validationComplete()) {
        const payload = {
          name: this.name,
          permissions: this.selectedPermissions,
        };

        await this.executeAction(
          {
            action: 'group/createGroup',
            name: 'addGroup',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
