<template>
  <v-btn :disabled="isDisabled" :loading="loading" outline color="primary" :href="link" v-on="listeners">{{ text }}</v-btn>
</template>

<script>
export default {
  name: 'button-primary',
  props: {
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>
