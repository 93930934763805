<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('virtual_money.add_action.title')"
    context="createVirtualMoney"
    @submit="submit"
    @cancel="cancel"
    cancel_text="virtual_money.add_action.button_cancel"
    submit_text="virtual_money.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.id"
            v-model="virtualMoney.id"
            :label="$t('virtual_money.add_action.fields.id')"
            :error-messages="errors.collect('virtual_money.id')"
            data-vv-name="virtual_money.id"
            :data-vv-as="$t('virtual_money.add_action.fields.id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.name"
            v-model="virtualMoney.name"
            :label="$t('virtual_money.add_action.fields.name')"
            :error-messages="errors.collect('virtual_money.name')"
            data-vv-name="virtual_money.name"
            :data-vv-as="$t('virtual_money.add_action.fields.name')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.wallet_definition_id"
            v-model="virtualMoney.wallet_definition_id"
            :label="$t('virtual_money.add_action.fields.wallet_definition_id')"
            :error-messages="errors.collect('virtual_money.wallet_definition_id')"
            data-vv-name="virtual_money.wallet_definition_id"
            :data-vv-as="$t('virtual_money.add_action.fields.wallet_definition_id')"
            v-validate="'max:100'"
            counter
            maxlength="100"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.options.load_on_card_program_id"
            v-model="virtualMoney.options.load_on_card_program_id"
            :label="$t('virtual_money.add_action.fields.option_load_on_card_program_id')"
            :error-messages="errors.collect('virtual_money.options.load_on_card_program_id')"
            :data-vv-as="$t('virtual_money.add_action.fields.option_load_on_card_program_id')"
            data-vv-name="virtual_money.options.load_on_card_program_id"
            v-validate="'max:100'"
            counter
            maxlength="100"
          />
        </v-flex>

        <v-checkbox v-model="virtualMoney.options.create_on_account_creation" :label="$t('virtual_money.add_action.fields.option_create_on_account_creation')" />
        <v-checkbox :disabled="!virtualMoney.options.load_on_card_program_id" v-model="virtualMoney.options.auto_adjustment_other_card_program" :label="$t('virtual_money.add_action.fields.option_auto_adjustment_other_card_program')" />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'add-virtual-money-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      dataReady: true,
      virtualMoney: {
        id: '',
        name: '',
        wallet_definition_id: '',
        options: {
          create_on_account_creation: false,
          auto_adjustment_other_card_program: false,
          load_on_card_program_id: '',
        },
      },
    };
  },
  computed: {
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    'virtualMoney.options.load_on_card_program_id'(newVal) {
      if (!newVal) {
        this.virtualMoney.options.auto_adjustment_other_card_program = false;
      }
    },
  },
  async created() {
    this.dataReady = true;
  },
  methods: {
    resetForm() {
      this.virtualMoney = {
        id: '',
        name: '',
        wallet_definition_id: '',
        options: {
          create_on_account_creation: false,
          auto_adjustment_other_card_program: false,
          load_on_card_program_id: '',
        },
      };
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'virtualMoney/createVirtualMoney',
            name: 'createVirtualMoney',
            success: this.success,
          },
          { virtual_money: this.virtualMoney },
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
