import Vue from 'vue';
import camelCase from 'lodash/camelCase';

const requireModule = require.context('.', true, /^((?!\.unit\.).)*-service\.js$/);
const root = {};

requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js') return;

  // Get the module path as an array
  const modulePath = fileName
    .replace(/^\.\/|service\.js$/g, '')
    .split(/\//)
    .map(camelCase);

  root[modulePath] = requireModule(fileName).default;
});

Vue.prototype.$services = root;

export default root;
