import Vue from 'vue';

const service = {
  async getLastPresentment() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/transactions/presentments/last`);
    return response.data.data;
  },
  async getLastAuthorization() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/transactions/authorizations/last`);
    return response.data.data;
  },

  async getLastEhiTimeout() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/transactions/ehi-timeouts/last`);
    return response.data.data;
  },
};

export default service;
