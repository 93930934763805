<template>
  <view-main title="page_title.guest_services_settings"> <view-guest-services-settings-form slot="content"></view-guest-services-settings-form> </view-main>
</template>

<script>
import ViewGuestServicesSettingsForm from './ViewGuestServicesSettingsForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'guest-services-settings-view',
  mixins: [renderErrorTrackable],
  components: { ViewGuestServicesSettingsForm },
};
</script>
