<template>
  <div class="label-text-compact">
    <h6 v-bind:class="[classArray]">{{ label }}</h6>
    <slot name="labelTextLink">
      <p class="label-text--value" v-bind:class="[classArray, isNotAvailable]" :title="title">{{ value | orNotAvailable }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'label-text-compact',
  props: {
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    classArray: {
      type: Array,
      required: false,
    },
  },
  computed: {
    classes() {
      return {
        ...this.$classes,
      };
    },
    isNotAvailable() {
      if (this.$options.filters.orNotAvailable(this.value) === this.value) {
        return;
      }
      return 'label-text-na';
    },
  },
  methods: {
    showForm() {
      this.actionData.showLabelTextForm = !this.actionData.showLabelTextForm;
    },
  },
};
</script>
