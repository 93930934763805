import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';
import _ from 'lodash';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,

  state: {
    virtualMoney: { name: {} },
    virtualMoneys: [],
    pageNumber: defaultPage,
    pageCount: 0,
  },

  getters: {
    virtualMoneys: (state) => state.virtualMoneys,
    virtualMoney: (state) => state.virtualMoney,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },

  mutations: {
    virtualMoneys(state, types) {
      state.virtualMoneys = _.sortBy(types, ['category_id', 'name.en']);
    },
    virtualMoney(state, type) {
      state.virtualMoney = type;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listVirtualMoneys({ commit }, { page, limit }) {
      const service = Vue.prototype.$services.virtualMoney;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const virtualMoneyList = await service.listVirtualMoneys(thePage, theLimit);

      commit('page', thePage);
      commit('pageCount', virtualMoneyList.page_count);
      commit('virtualMoneys', virtualMoneyList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('virtualMoney/listVirtualMoneys', { page });
    },

    async getVirtualMoney({ commit }, { id }) {
      const service = Vue.prototype.$services.virtualMoney;
      const virtualMoney = await service.getVirtualMoney(id);

      commit('virtualMoney', virtualMoney);
    },

    async activateVirtualMoney({ dispatch }, { virtualMoneyId }) {
      const service = Vue.prototype.$services.virtualMoney;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.activateVirtualMoney(virtualMoneyId);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'virtual_money.activate_action.success' });
      await defaultDispatch('virtualMoney/listVirtualMoneys');
    },

    async deactivateVirtualMoney({ dispatch }, { virtualMoneyId }) {
      const service = Vue.prototype.$services.virtualMoney;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.deactivateVirtualMoney(virtualMoneyId);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'virtual_money.deactivate_action.success' });
      await defaultDispatch('virtualMoney/listVirtualMoneys');
    },

    async updateVirtualMoney({ dispatch }, { note_type }) {
      const service = Vue.prototype.$services.virtualMoney;
      const defaultDispatch = safeDispatcher(dispatch);

      const { id } = note_type;
      const virtualMoney = _.pick(note_type, ['name', 'wallet_definition_id', 'options.create_on_account_creation', 'options.load_on_card_program_id', 'options.auto_adjustment_other_card_program']);

      changeEmptyOptionalStringsToNull(virtualMoney);

      await service.updateVirtualMoney(id, virtualMoney);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'virtual_money.update_action.success' });
      await defaultDispatch('virtualMoney/listVirtualMoneys');
    },

    async createVirtualMoney({ dispatch }, { virtual_money }) {
      const service = Vue.prototype.$services.virtualMoney;
      const defaultDispatch = safeDispatcher(dispatch);

      changeEmptyOptionalStringsToNull(virtual_money);

      await service.createVirtualMoney(virtual_money);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'virtual_money.add_action.success' });
      await defaultDispatch('virtualMoney/listVirtualMoneys');
    },
  },
};

function changeEmptyOptionalStringsToNull(virtual_money) {
  if (virtual_money.wallet_definition_id === '') {
    virtual_money.wallet_definition_id = null;
  }

  if (virtual_money.options.load_on_card_program_id === '') {
    virtual_money.options.load_on_card_program_id = null;
  }
}
