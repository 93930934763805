<template>
  <div>
    <table class="responsive-table smoke-note-type--list-table">
      <thead>
        <tr>
          <td>{{ $t('note_type.listTable.id') }}</td>
          <td>{{ $t('note_type.listTable.category') }}</td>
          <td>{{ $t('note_type.listTable.name') }}</td>
          <td>{{ $t('note_type.listTable.active') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="smoke-note-type--list-table--id-column">
            <h5>{{ $t('note_type.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>

          <td class="smoke-note-type--list-table--category-column">
            <h5>{{ $t('note_type.listTable.category') }}:</h5>
            <span :class="{ 'info-not-available': !item.category_id }">{{ item.category_id | orNone }}</span>
          </td>

          <td class="smoke-note-type--list-table--name-column">
            <h5>{{ $t('note_type.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name && !item.name.en }">{{ item.name.en | orNone }}</span>
          </td>

          <td class="smoke-note-type--list-table--active-column">
            <h5>{{ $t('note_type.listTable.active') }}:</h5>
            <span>{{ item.active }}</span>
          </td>

          <td class="smoke-note-type--list-table--actions-column">
            <v-btn align-bottom small outline color="primary" @click="editNoteType(item)" class="smoke-note-type--list-table--edit-button">{{ $t(`note_type.listTable.edit`) }}</v-btn>
          </td>
        </tr>
      </tbody>

      <modal-action :show-modal="showUpdateNoteTypeModal">
        <update-note-type-action :note-type="currentNoteType" @close="closeModalUpdateNoteType" @action="reloadPage" />
      </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="aml-note-types-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdateNoteTypeAction from './UpdateNoteTypeAction';
import { mapGetters } from 'vuex';

export default {
  name: 'note-type-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateNoteTypeAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentNoteType: {},
      pagination: 1,
      showUpdateNoteTypeModal: false,
    };
  },
  computed: {
    ...mapGetters('noteType', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'noteType/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showUpdateNoteTypeModal = false;
    },
    editNoteType(noteType) {
      this.currentNoteType = noteType;
      this.showUpdateNoteTypeModal = true;
    },
    closeModalUpdateNoteType() {
      this.showUpdateNoteTypeModal = false;
    },
  },
};
</script>
