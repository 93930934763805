import Vue from 'vue';
import Errors from './../utils/error/error-types';

const service = {
  async createVirtualMoney(virtualMoney) {
    try {
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/virtual-moneys`, virtualMoney);
      return response.data.data;
    } catch (e) {
      // TODO updating this check (PLM)
      if (noteTypeAlreadyExists(e)) {
        return Promise.reject(transformToHttpError(400, 'note_type.id.already_exists'));
      }

      throw e;
    }
  },

  async activateVirtualMoney(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/virtual-moneys/${id}/activate`);
    return response.data.data;
  },

  async deactivateVirtualMoney(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/virtual-moneys/${id}/deactivate`);
    return response.data.data;
  },

  async updateVirtualMoney(id, virtualMoney) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/virtual-moneys/${id}`, virtualMoney);
    return response.data.data;
  },

  async getVirtualMoney(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/virtual-moneys/${id}`);
    return response.data.data;
  },

  async listVirtualMoneys(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/virtual-money/v1/virtual-moneys', page, limit)}`);
    return response.data.data;
  },
};

function noteTypeAlreadyExists(e) {
  return e.response_code === 'note_type.already.exists';
}

function transformToHttpError(statusCode, code) {
  return new Errors.HttpError(statusCode, { error: { code } });
}

function generatePath(startPath, page, limit) {
  let path = startPath;
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
