import Vue from 'vue';

const service = {
  async listSubscriptionPlans(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/account/v1/subscription-plans', page, limit)}`);
    return response.data.data;
  },

  async createSubscriptionPlan(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/account/v1/subscription-plans`, payload);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit) {
  let path = startPath;
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
