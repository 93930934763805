<template>
  <div class="select-text-wrap">
    <v-select class="select" :items="selectData.options" v-model="selected" :label="$t('selectText.defaultText')" single-line>
      <template slot="selection" slot-scope="data">
        <v-list-tile-content v-text="$t(`${selectData.base_i18n_options_code}.${data.item.value}`)"></v-list-tile-content>
      </template>
      <template slot="item" slot-scope="data">
        <v-list-tile-content v-text="$t(`${selectData.base_i18n_options_code}.${data.item.value}`)"></v-list-tile-content>
      </template>
    </v-select>
    <v-btn v-if="selectData.actionText" @click="doAction" class="select-text--button">{{ $t(selectData.actionText) }}</v-btn>
    <v-icon class="select-text--close-icon" @click="doCancel">highlight_off</v-icon>
  </div>
</template>

<script>
export default {
  name: 'select-text',
  props: {
    selectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: this.selectData ? this.selectData.selected : false,
    };
  },
  watch: {
    selectData() {
      this.selected = this.selectData ? this.selectData.selected : false;
    },
  },
  methods: {
    doAction() {
      this.$emit('action');
    },
    doCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
