import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  computed: {
    ...mapGetters('ui', ['error']),
    isGlobalErrorActive() {
      return !_.isEmpty(this.error) && !_.isUndefined(this.error);
    },
  },
};
