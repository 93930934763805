<script>
import disabler from '@/mixins/disable-with-error';

export default {
  name: 'button-secondary-actions',
  mixins: [disabler],
  props: {
    text: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    item: {
      default: function() {
        return {};
      },
      type: Object,
    },
    small: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data: () => {
    return {
      showActionMenu: false,
    };
  },
  methods: {
    closeMenu() {
      this.showActionMenu = false;
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
  render(h) {
    if (this.actions.reduce((acc, val) => val.isAvailable(this.item) || acc, false)) {
      return h(
        'v-menu',
        {
          props: {
            disabled: this.isGlobalErrorActive,
            'offset-y': true,
            'v-model': this.showActionMenu,
            'v-show': this.actions.reduce((acc, val) => val.isAvailable(this.item) || acc, false),
            left: true,
          },
        },
        [
          h(
            'v-btn',
            {
              props: { small: this.small, outline: true, color: 'info', 'open-on-hover': true, disabled: this.isGlobalErrorActive },
              slot: 'activator',
              on: this.listeners,
              'v-show': this.actions.reduce((acc, val) => val.isAvailable(this.item) || acc, false),
            },
            this.text,
          ),
          h(
            'v-list',
            this.actions.map((e) => {
              if (e.isAvailable(this.item)) {
                const obj = {
                  on: {
                    click: () => {
                      this.closeMenu;
                      e.action(this.item);
                    },
                  },
                  class: {},
                };
                obj.class[`${e.slotName}-action-btn`] = true;
                return h('v-list-tile', obj, [e.name, this.$slots[e.slotName]]);
              }
            }),
          ),
        ],
      );
    }
    return h();
  },
};
</script>
