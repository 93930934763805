const config = {
  oidc: {
    keycloak: true,
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT_URI,
  },
};

export default config;
