<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('agent.add_agent_action.title')"
    context="addAgentAction"
    @submit="submit"
    @cancel="cancel"
    cancel_text="agent.add_agent_action.button_cancel"
    submit_text="agent.add_agent_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            name="first_name"
            v-model="first_name"
            v-validate="'required|max:20'"
            :label="$t('agent.add_agent_action.fields.first_name')"
            :error-messages="errors.collect('first_name')"
            data-vv-name="first_name"
            :data-vv-as="$t('agent.add_agent_action.fields.first_name')"
            required
            counter
            maxlength="20"
            class="add-agent--first-name"
          >
          </v-text-field>
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="last_name"
            v-model="last_name"
            v-validate="'required|max:20'"
            :error-messages="errors.collect('last_name')"
            :label="$t('agent.add_agent_action.fields.last_name')"
            data-vv-name="last_name"
            :data-vv-as="$t('agent.add_agent_action.fields.last_name')"
            required
            counter
            maxlength="20"
            class="add-agent--last-name"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-text-field
            name="email"
            v-validate="'required|email'"
            v-model="email"
            :label="$t('agent.add_agent_action.fields.email')"
            :error-messages="errors.collect('email')"
            data-vv-name="email"
            :data-vv-as="$t('agent.add_agent_action.fields.email')"
            required
            class="add-agent--email"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs12>
          <v-select
            name="language"
            v-validate="'required'"
            v-model="language"
            :label="$t(`agent.add_agent_action.fields.language`)"
            :error-messages="errors.collect('language')"
            data-vv-name="language"
            :data-vv-as="$t(`agent.add_agent_action.fields.language`)"
            :items="languageOptions"
            item-text="name"
            item-value="abbr"
            class="add-agent--language"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <v-select
            name="team"
            v-model="team"
            :label="$t(`agent.add_agent_action.fields.team`)"
            :error-messages="errors.collect('team')"
            data-vv-name="language"
            :data-vv-as="$t(`agent.add_agent_action.fields.team`)"
            :items="teamOptions"
            item-text="name"
            item-value="value"
            class="add-agent--team"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <v-text-field
            name="mobile_number"
            v-validate="{ regex: /^[0-9]{10}$/, min: 10, max: 10 }"
            maxlength="10"
            v-model="mobile_number"
            :label="$t('agent.add_agent_action.fields.mobile_number')"
            :error-messages="errors.collect('mobile_number')"
            data-vv-name="mobile_number"
            :data-vv-as="$t('agent.add_agent_action.fields.mobile_number')"
            required
            class="add-agent--mobile_number"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex sm6 xs12>
          <section class="checkboxes-indented-with-heading">
            <div v-if="notifications" class="attach-program-action--groups">
              <v-label>{{ $t('agent.add_agent_action.groups') }}</v-label>
              <v-checkbox v-for="(group, index) in groups" :key="index" v-model="selectedGroups" :label="group.name" :value="group.id" :error-messages="errors.collect('groups')" data-vv-name="groups" :data-vv-as="$t('agent.add_agent_action.groups')" />
            </div>
          </section>
        </v-flex>
        <v-flex sm6 xs12>
          <section class="checkboxes-indented-with-heading">
            <div v-if="notifications" class="attach-program-action--notifications">
              <v-label>{{ $t('agent.add_agent_action.notifications') }}</v-label>
              <v-checkbox
                v-for="(notification, index) in notifications"
                :key="index"
                v-model="selectedNotifications"
                :label="$t(`code.notifications.${notification}`)"
                :value="notification"
                :error-messages="errors.collect('notifications')"
                data-vv-name="notifications"
                :data-vv-as="$t('agent.add_agent_action.notifications')"
              />
            </div>
          </section>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-agent-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      language: 'en',
      team: '',
      mobile_number: '',
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
      teamOptions: [
        { name: '<None>', value: '' },
        { name: 'Engineering', value: 'engineering' },
        { name: 'Finance', value: 'finance' },
        { name: 'Operations', value: 'operations' },
        { name: 'Product', value: 'product' },
        { name: 'Sales', value: 'sales' },
        { name: 'External', value: 'external' },
      ],
      selectedNotifications: [],
      selectedGroups: [],
    };
  },
  async created() {
    await this.fetchGroups();
  },
  computed: {
    ...mapGetters('program', ['notifications']),
    ...mapGetters('group', ['groups']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.first_name = '';
      this.last_name = '';
      this.email = '';
      this.mobile_number = '';
      this.team = '';
      this.language = 'en';
      this.selectedNotifications = [];
      this.selectedGroups = [];
    },
    async fetchGroups() {
      this.dataReady = false;
      await this.executeAction({ action: 'group/listGroups', success: this.success }, {});
      this.dataReady = true;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'agent/createAgent',
            name: 'addAgentAction',
            success: this.success,
          },
          {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            language: this.language,
            team: this.team || undefined,
            mobile_number: this.mobile_number || undefined,
            notifications: this.selectedNotifications,
            control_access_groups: this.selectedGroups,
          },
        );
      }
    },
    success() {
      this.clear();
      this.$emit('action');
    },
  },
};
</script>
