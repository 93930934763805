<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('virtual_money.listTable.id') }}</td>
          <td>{{ $t('virtual_money.listTable.name') }}</td>
          <td>{{ $t('virtual_money.listTable.wallet_definition') }}</td>
          <td>{{ $t('virtual_money.listTable.load_on_card_program') }}</td>
          <td>{{ $t('virtual_money.listTable.create_on_account_creation') }}</td>
          <td>{{ $t('virtual_money.listTable.auto_adjustment_other_card_program') }}</td>
          <td>{{ $t('virtual_money.listTable.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('virtual_money.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('virtual_money.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('virtual_money.listTable.wallet_definition') }}:</h5>
            <span :class="{ 'info-not-available': !item.wallet_definition_id }">{{ item.wallet_definition_id | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('virtual_money.listTable.load_on_card_program') }}:</h5>
            <span :class="{ 'info-not-available': !item.options.load_on_card_program_id }">{{ item.options.load_on_card_program_id | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('virtual_money.listTable.create_on_account_creation') }}:</h5>
            <span>{{ item.options.create_on_account_creation }}</span>
          </td>
          <td>
            <h5>{{ $t('virtual_money.listTable.auto_adjustment_other_card_program') }}:</h5>
            <span :class="{ 'info-not-available': !item.options.auto_adjustment_other_card_program }">{{ item.options.auto_adjustment_other_card_program | orNone }}</span>
          </td>
          <td>
            <h5>{{ $t('virtual_money.listTable.status') }}:</h5>
            <span :class="{ 'info-not-available': !item.status, [`virtual-money-status-${item.status}`]: true }">{{ $t(`virtual_money.listTable.status_values.${item.status}`) | orNone }}</span>
          </td>
          <td>
            <v-btn :disabled="!isItemCanBeEdited(item)" align-bottom small outline color="primary" @click="editVirtualMoney(item)">{{ $t(`virtual_money.listTable.edit`) }}</v-btn>
          </td>
          <td>
            <v-btn :disabled="!isItemCanBeActivated(item)" align-bottom small outline color="primary" @click="showModalActivateVirtualMoney(item)">{{ $t(`virtual_money.listTable.activate`) }}</v-btn>
          </td>
          <td>
            <v-btn :disabled="!isItemCanBeDeactivated(item)" align-bottom small outline color="error" @click="showModalDeactivateVirtualMoney(item)">{{ $t(`virtual_money.listTable.deactivate`) }}</v-btn>
          </td>
        </tr>
      </tbody>

      <modal-action :show-modal="showEditVirtualMoneyModal">
        <update-virtual-money-action :selected-virtual-money="currentVirtualMoney" @close="closeModalEditVirtualMoney" @action="reloadPage" />
      </modal-action>

      <modal-confirm
        :show-modal="showActivateVirtualMoneyModal"
        :title="$t('virtual_money.activate_action.title')"
        context="DeletePackageConfirmationModal"
        @submit="activateVirtualMoney()"
        @close="closeModalActivateVirtualMoney"
        :text="$t('virtual_money.activate_action.text')"
        cancel_text="virtual_money.activate_action.button_cancel"
        submit_text="virtual_money.activate_action.button_save"
        :submit_working="working"
      ></modal-confirm>

      <modal-confirm
        :show-modal="showDeactivateVirtualMoneyModal"
        :title="$t('virtual_money.deactivate_action.title')"
        context="DeletePackageConfirmationModal"
        @submit="deactivateVirtualMoney()"
        @close="closeModalDeactivateVirtualMoney"
        :text="$t('virtual_money.deactivate_action.text')"
        cancel_text="virtual_money.activate_action.button_cancel"
        submit_text="virtual_money.activate_action.button_save"
        :submit_working="working"
      ></modal-confirm>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="virtual-money-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdateVirtualMoneyAction from './UpdateVirtualMoneyAction';
import { mapGetters } from 'vuex';

export default {
  name: 'virtual-money-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateVirtualMoneyAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentVirtualMoney: {},
      pagination: 1,
      showEditVirtualMoneyModal: false,
      showActivateVirtualMoneyModal: false,
      showDeactivateVirtualMoneyModal: false,
    };
  },
  computed: {
    ...mapGetters('virtualMoney', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'virtualMoney/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showEditVirtualMoneyModal = false;
      this.showActivateVirtualMoneyModal = false;
      this.showDeactivateVirtualMoneyModal = false;
    },

    // Update Virtual Money
    isItemCanBeEdited(item) {
      return item.status === 'pending';
    },
    editVirtualMoney(virtualMoney) {
      this.currentVirtualMoney = virtualMoney;
      this.showEditVirtualMoneyModal = true;
    },
    closeModalEditVirtualMoney() {
      this.showEditVirtualMoneyModal = false;
    },

    // Activate Virtual Money
    isItemCanBeActivated(item) {
      return item.status === 'pending';
    },
    async activateVirtualMoney() {
      await this.executeAction(
        {
          action: 'virtualMoney/activateVirtualMoney',
          success: this.activateVirtualMoneySuccess,
        },
        { virtualMoneyId: this.currentVirtualMoney.id },
      );
    },
    showModalActivateVirtualMoney(virtualMoney) {
      this.currentVirtualMoney = virtualMoney;
      this.showActivateVirtualMoneyModal = true;
    },
    closeModalActivateVirtualMoney() {
      this.showActivateVirtualMoneyModal = false;
    },
    activateVirtualMoneySuccess() {
      this.reloadPage();
    },

    // Deactivate Virtual Money
    isItemCanBeDeactivated(item) {
      return item.status !== 'deactivated';
    },
    async deactivateVirtualMoney() {
      await this.executeAction(
        {
          action: 'virtualMoney/deactivateVirtualMoney',
          success: this.deactivateVirtualMoneySuccess,
        },
        { virtualMoneyId: this.currentVirtualMoney.id },
      );
    },
    showModalDeactivateVirtualMoney(virtualMoney) {
      this.currentVirtualMoney = virtualMoney;
      this.showDeactivateVirtualMoneyModal = true;
    },
    closeModalDeactivateVirtualMoney() {
      this.showDeactivateVirtualMoneyModal = false;
    },
    deactivateVirtualMoneySuccess() {
      this.reloadPage();
    },
  },
};
</script>
