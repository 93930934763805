import Vue from 'vue';

const defaultPage = 1;
let paginationLimit = 1000;

export default {
  namespaced: true,
  state: {
    organizations: [],
  },

  getters: {
    organizations: (state) => state.organizations,
  },

  mutations: {
    organizations(state, organizations) {
      state.organizations = organizations;
    },
  },

  actions: {
    async listOrganizations({ commit }, { page, limit }) {
      const orgService = Vue.prototype.$services.organization;
      const excludeOrgKey = 'fidem';
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const organizations = await orgService.listOrganizations(thePage, theLimit, excludeOrgKey);

      commit('organizations', organizations);
    },
  },
};
