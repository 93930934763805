<template>
  <v-flex>
    <h1 hidden>{{ $t('program.list.title') }}</h1>
    <v-card class="form-basic pa-2"> <button-primary class="smoke-card-program--btn-add" :loading="working" :text="$t('program.form.add')" @click="showModalAddProgram"></button-primary> </v-card>

    <modal-action :show-modal="showAddProgramModal"> <add-program-action @close="closeModalAddProgram" @action="reloadPage"></add-program-action> </modal-action>

    <v-flex class="data-section"> <programs-list v-if="dataReady && programs" :items="programs"></programs-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddProgramAction from './AddProgramAction';
import ProgramsList from './ProgramsList';

export default {
  name: 'view-program-form',
  mixins: [actionErrorTrackable, security],
  components: { ProgramsList, AddProgramAction },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddProgramModal: false,
    };
  },
  computed: {
    ...mapGetters('program', ['programs']),
  },
  async created() {
    await this.fetchPrograms();
  },
  methods: {
    async fetchPrograms() {
      this.dataReady = false;
      await this.executeAction({ action: 'program/listPrograms', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddProgram() {
      this.showAddProgramModal = true;
    },
    closeModalAddProgram() {
      this.showAddProgramModal = false;
    },
    reloadPage() {
      this.showAddProgramModal = false;
    },
  },
};
</script>
