<template>
  <v-flex>
    <h1 hidden>{{ $t('partner.list.title') }}</h1>
    <v-card class="form-basic pa-2"><button-primary class="smoke-partner--btn-add" :loading="working" :text="$t('partner.form.add')" @click="showModalAddPartner"></button-primary> </v-card>

    <modal-action :show-modal="showAddPartnerModal" :avoid-rendering="true"> <add-partner-action @close="closeModalAddPartner" @action="reloadPage"></add-partner-action> </modal-action>

    <v-flex class="data-section"> <partner-list v-if="dataReady && partners" :items="partners"></partner-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddPartnerAction from './AddPartnerAction';
import PartnerList from './PartnerList';

export default {
  name: 'view-partner-form',
  mixins: [actionErrorTrackable, security],
  components: { PartnerList, AddPartnerAction },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddPartnerModal: false,
    };
  },
  computed: {
    ...mapGetters('partner', ['partners']),
  },
  async created() {
    await this.fetchPartners();
  },
  methods: {
    async fetchPartners() {
      this.dataReady = false;
      await this.executeAction({ action: 'partner/listPartners', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddPartner() {
      this.showAddPartnerModal = true;
    },
    closeModalAddPartner() {
      this.showAddPartnerModal = false;
    },
    reloadPage() {
      this.showAddPartnerModal = false;
    },
  },
};
</script>
