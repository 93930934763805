<template>
  <view-main title="page_title.group"> <view-group-form slot="content"></view-group-form> </view-main>
</template>

<script>
import ViewGroupForm from './ViewGroupForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'group-view',
  mixins: [renderErrorTrackable],
  components: { ViewGroupForm },
};
</script>
