<script>
import { mapGetters } from 'vuex';
import { security } from '@/mixins';
import Vue from 'vue';
import router from '@/router';

export default {
  mixins: [security],
  computed: {
    ...mapGetters('security', ['authenticated', 'startupPath']),
  },
  watch: {
    startupPath(path) {
      Vue.nextTick(() => {
        router.push({ path });
      });
    },
  },
  mounted() {
    if (!this.authenticated) {
      this.$router.push({ name: 'login' });
    }
    if (this.authenticated && this.startupPath) {
      this.$router.push({ path: this.startupPath });
    }
  },
  render() {
    return {};
  },
};
</script>
