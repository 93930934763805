<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('subscription_plan.add_action.title')"
    context="addSubscriptionPlan"
    @submit="submit"
    @cancel="cancel"
    cancel_text="subscription_plan.add_action.button_cancel"
    submit_text="subscription_plan.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    data-test="subscription-plan-add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="plan.name"
            v-model="plan.name"
            :label="$t('subscription_plan.add_action.fields.name')"
            :error-messages="errors.collect('plan.name')"
            data-vv-name="plan.name"
            :data-vv-as="$t('subscription_plan.add_action.fields.name')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            data-test="subscription-plan-add-modal-name"
          />
        </v-flex>

        <v-flex xs12>
          <v-select
            :items="partners"
            v-model="plan.partner_id"
            :item-text="(item) => `${item.name} (${item.id})`"
            item-value="id"
            :label="subscription_plan_partner_id"
            v-validate="'required'"
            :error-messages="errors.collect('partner_id')"
            data-vv-name="organizations"
            :data-vv-as="$t('subscription_plan.add_action.fields.partner_id')"
            data-test="subscription-plan-add-modal-partner-id"
          />
        </v-flex>

        <v-flex xs6>
          <v-text-field
            type="text"
            name="subscription_plan.monthly_fee"
            v-model="plan.monthly_fee"
            :label="$t('subscription_plan.add_action.fields.monthly_fee')"
            :error-messages="errors.collect('subscription_plan.monthly_fee')"
            data-vv-name="subscription_plan.monthly_fee"
            :data-vv-as="$t('subscription_plan.add_action.fields.monthly_fee')"
            data-test="subscription-plan-add-modal-monthly-fee"
          />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-subscription-plan-action',

  $_veeValidate: {
    validator: 'new',
  },

  mixins: [actionErrorTrackable, security],

  data() {
    return {
      plan: {},
    };
  },

  computed: {
    ...mapGetters('partner', ['partners']),

    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },

  methods: {
    resetForm() {
      this.plan = {};
    },

    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'subscriptionPlan/createSubscriptionPlan',
            name: 'addSubscriptionPlan',
            success: this.success,
          },
          this.plan,
        );
      }
      return await true;
    },

    async validationComplete() {
      return this.$validator.validateAll();
    },

    success() {
      this.clear();
      this.$emit('action');
    },

    clear() {
      this.resetForm();
      this.$validator.reset();
    },

    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
