<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('branding_category.update_action.title')"
    context="updateBrandingCategory"
    @submit="submit"
    @cancel="cancel"
    cancel_text="branding_category.add_action.button_cancel"
    submit_text="branding_category.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-branding-category--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="branding_category.id"
            v-model="currentBrandingCategory.id"
            :label="$t('branding_category.update_action.fields.id')"
            :error-messages="errors.collect('branding_category.id')"
            data-vv-name="branding_category.id"
            :data-vv-as="$t('branding_category.update_action.fields.id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-branding-category--update--id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="branding_category.name.fr"
            v-model="currentBrandingCategory.name.fr"
            :label="$t('branding_category.update_action.fields.name.fr')"
            :error-messages="errors.collect('branding_category.name.fr')"
            data-vv-name="branding_category.name.fr"
            :data-vv-as="$t('branding_category.update_action.fields.name.fr')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-branding-category--update--name-fr"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="branding_category.name.en"
            v-model="currentBrandingCategory.name.en"
            :label="$t('branding_category.update_action.fields.name.en')"
            :error-messages="errors.collect('branding_category.name.en')"
            data-vv-name="branding_category.name.en"
            :data-vv-as="$t('branding_category.update_action.fields.name.en')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-branding-category--update--name-en"
          />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-branding-category-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    brandingCategory: {
      type: Object,
      required: true,
      default: () => {
        return { name: {} };
      },
    },
  },
  data() {
    return {
      currentBrandingCategory: { name: {} },
    };
  },
  computed: {
    ...mapGetters('brandingCategory', ['getBrandingCategory']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    brandingCategory(value) {
      this.currentBrandingCategory = _.cloneDeep(value);
    },
  },
  methods: {
    resetForm() {
      this.currentBrandingCategory = _.cloneDeep(this.getBrandingCategory(this.brandingCategory.key));
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'brandingCategory/updateBrandingCategory',
            name: 'updateBrandingCategory',
            success: this.success,
          },
          { branding_category: this.currentBrandingCategory },
        );
      }
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
