import { transformGeneralError } from '@/utils/error/error-transformer';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('ui', ['addError']),
  },
  errorCaptured(err, vm, info) {
    this.addError({ contextName: 'render', error: transformGeneralError(err, info) });
    return false;
  },
};
