<template>
  <div v-cloak id="app">
    <v-app id="inspire">
      <v-snackbar
        v-for="(snackbar, index) in snackbars"
        :key="index"
        :timeout="0"
        :top="snackbar.y === 'top'"
        :bottom="snackbar.y === 'bottom'"
        :right="snackbar.x === 'right'"
        :left="snackbar.x === 'left'"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :vertical="snackbar.mode === 'vertical'"
        v-model="snackbar.show"
        >{{ $t(snackbar.text) }}</v-snackbar
      >
      <v-navigation-drawer v-model="drawer" temporary app>
        <v-list dense>
          <v-list-tile v-for="item in navItems" :key="item.title" :to="item.theRoute">
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="!authenticated" @click="login()">
            <v-list-tile-action>
              <v-icon>check</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('navigation.login') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile class="button-logout" v-if="authenticated" @click="logoutSession()">
            <v-list-tile-action>
              <v-icon>cancel</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('navigation.logout') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
      <v-toolbar app fixed>
        <v-toolbar-side-icon class="hidden-sm-and-up" @click.stop="drawer = !drawer" />
        <v-toolbar-title>
          <img width="110px" src="@/assets/logo-text.png" />
        </v-toolbar-title>
        <v-spacer />
        <v-btn v-ripple="false" v-for="item in navItems" :to="item.theRoute" :key="item.title" class="hidden-xs-only" flat>{{ item.title }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-ripple="false" v-if="!authenticated" class="hidden-xs-only" flat @click="login()">{{ $t('navigation.login') }}</v-btn>
        <v-btn v-ripple="false" v-if="authenticated" class="button-logout hidden-xs-only" flat @click="logoutSession()">{{ $t('navigation.logout') }}</v-btn>
      </v-toolbar>
      <v-content>
        <router-view />
      </v-content>
      <v-footer class="justify-center" app>
        <span class="white--text">&copy; Hello Network Inc. {{ new Date().getFullYear() }} v{{ version }}</span>
        <span class="hidden-sm-and-down white--text">&nbsp;#{{ buildNumber }}</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { security } from '@/mixins';

export default {
  mixins: [security],
  data: function data() {
    return {
      drawer: null,
      buildNumber: process.env.VUE_APP_BUILD_NUMBER || 'DEV',
      version: process.env.VUE_APP_VERSION,
    };
  },
  watch: {
    $route: 'checkSecurityStatus',
  },
  computed: {
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('ui', ['snackbars']),
    navItems() {
      const navItems = [];
      if (this.authenticated) {
        if (this.isAgentInternal()) {
          navItems.push({
            title: this.$t(`navigation.status`),
            icon: 'person',
            theRoute: '/status',
          });

          navItems.push({
            title: this.$t(`navigation.agent`),
            icon: 'person',
            theRoute: '/agent',
          });

          navItems.push({
            title: this.$t(`navigation.app`),
            icon: 'person',
            theRoute: '/app',
          });

          navItems.push({
            title: this.$t(`navigation.package`),
            icon: 'person',
            theRoute: '/package',
          });

          navItems.push({
            title: this.$t(`navigation.group`),
            icon: 'person',
            theRoute: '/group',
          });

          navItems.push({
            title: this.$t(`navigation.program`),
            icon: 'person',
            theRoute: '/program',
          });

          navItems.push({
            title: this.$t(`navigation.partner`),
            icon: 'person',
            theRoute: '/partner',
          });

          navItems.push({
            title: this.$t(`navigation.alert`),
            icon: 'person',
            theRoute: '/alert',
          });

          navItems.push({
            title: this.$t(`navigation.space`),
            icon: 'person',
            theRoute: '/space',
          });

          navItems.push({
            title: this.$t(`navigation.virtual_money`),
            icon: 'person',
            theRoute: '/virtual-money',
          });

          navItems.push({
            title: this.$t(`navigation.branding_category`),
            icon: 'person',
            theRoute: '/branding-category',
          });

          navItems.push({
            title: this.$t(`navigation.note_type`),
            icon: 'person',
            theRoute: '/note-type',
          });

          navItems.push({
            title: this.$t(`navigation.subscription_plans`),
            icon: 'person',
            theRoute: '/subscription-plan',
          });

          navItems.push({
            title: this.$t(`navigation.guest_services_settings`),
            icon: 'person',
            theRoute: '/guest-services-settings',
          });
        }
      }
      return navItems;
    },
  },
  methods: {
    ...mapActions('security', ['logout', 'checkSecurityStatus']),
    ...mapActions('ui', ['changeLocale']),
    async logoutSession() {
      await this.logout();
      this.$router.push({ name: 'root' });
    },
    async login() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
