<template>
  <card :title="transactionType" icon="" class="view-alert">
    <template slot="content">
      <article>
        <v-layout row wrap>
          <v-flex xs3>
            <label-text :label="$t('transactions.list.item.transaction_date')" :value="transaction.transaction_date | formatInvertedDateWithElapsedDays"></label-text>
          </v-flex>
          <v-flex xs3>
            <label-text :label="$t('transactions.list.item.card_public_token')">
              <p v-if="!transaction.card_public_token" class="label-text--value" slot="labelTextLink">{{ $t('system.na') }}</p>
              <a v-else slot="labelTextLink" :href="redirectUrl" target="_blank">{{ transaction.card_public_token }}</a>
            </label-text>
          </v-flex>

          <v-flex xs3>
            <label-text :label="$t('transactions.list.item.transaction_id')" :value="transaction.transaction_id"></label-text>
          </v-flex>

          <v-flex xs3>
            <label-text :label="$t('transactions.list.item.merchant_name')" :value="transaction.merchant_name"></label-text>
          </v-flex>

          <v-flex xs3>
            <label-text :label="$t('transactions.list.item.amount')" :value="transaction.amount"></label-text>
          </v-flex>
        </v-layout>
      </article>
    </template>
  </card>
</template>

<script>
import config from '@/config';

export default {
  name: 'transactions-list-item',
  computed: {
    config() {
      return config;
    },
    redirectUrl() {
      return `${process.env.VUE_APP_CONSOLE_ADMIN_URL}/card/${this.transaction.card_public_token}`;
    },
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    transactionType: {
      type: String,
    },
  },
};
</script>
