<template>
  <card icon="fal fa-user" :title="`${$t('note_type.list.title')}`">
    <template slot="headerZoneRight"></template>

    <template slot="content">
      <note-type-table :items="items"></note-type-table>
    </template>
  </card>
</template>

<script>
import NoteTypeTable from './NoteTypeTable';

export default {
  name: 'note-type-list',
  components: { NoteTypeTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
