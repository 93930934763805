import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';
import errorTypes from '@/utils/error/error-types';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    groups: [],
    pageNumber: defaultPage,
    pageCount: 0,
  },

  getters: {
    getGroup: (state) => (id) => state.groups.find((group) => group.id === id),
    groups: (state) => state.groups,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },

  mutations: {
    groups(state, groupsList) {
      state.groups = groupsList;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listGroups({ commit }, { page, limit }) {
      const groupService = Vue.prototype.$services.group;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const groupsList = await groupService.listGroups(thePage, theLimit);

      commit('page', thePage);
      commit('pageCount', groupsList.page_count);
      commit('groups', groupsList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('group/listGroups', { page });
    },

    async createGroup({ dispatch, state }, payload) {
      const groupService = Vue.prototype.$services.group;
      const defaultDispatch = safeDispatcher(dispatch);

      await groupService.createGroup(payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'group.add_group_action.success' });
      await defaultDispatch('group/listGroups', { page: state.pageNumber });
    },

    async updateGroup({ dispatch, state }, { id, group }) {
      const groupService = Vue.prototype.$services.group;
      const defaultDispatch = safeDispatcher(dispatch);

      const payload = {
        name: group.name,
        permissions: group.permissions,
      };

      await groupService.updateGroup(id, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'group.update_group_action.success' });

      await defaultDispatch('group/listGroups', { page: state.pageNumber });
    },

    async deactivateGroup({ dispatch, state }, key) {
      const service = Vue.prototype.$services.group;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.deactivateGroup(key);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'group.deactivate_action.success' });
      await defaultDispatch('group/listGroups', { page: state.pageNumber });
    },

    async activateGroup({ dispatch, state }, key) {
      const service = Vue.prototype.$services.group;
      const defaultDispatch = safeDispatcher(dispatch);

      await service.activateGroup(key);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'group.activate_action.success' });
      await defaultDispatch('group/listGroups', { page: state.pageNumber });
    },

    async deleteGroup({ dispatch, state }, key) {
      const groupService = Vue.prototype.$services.group;
      const defaultDispatch = safeDispatcher(dispatch);

      const res = await groupService.deleteGroup(key);

      if (res.result === 'fail') {
        res.error.code = 'group.deletion';
        throw new errorTypes.HttpError('fail', res);
      }

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'group.delete_group_action.success' });

      await defaultDispatch('group/listGroups', { page: state.pageNumber });
    },
  },
};
