import Vue from 'vue';

const service = {
  async listTrigger(cardProgramKey) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/trigger/v1/card-programs/${cardProgramKey}`);
    return response.data.data.items;
  },
  async updateTrigger({ cardProgramKey, id }, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/trigger/v1/card-programs/${cardProgramKey}/${id}`, payload);
    return response.data.data;
  },
};

export default service;
