<template>
  <card icon="fal fa-user" :title="`${$t('group.list.title')}`">
    <template slot="headerZoneRight"> </template>

    <template slot="content">
      <groups-table :items="items"></groups-table>
    </template>
  </card>
</template>

<script>
import GroupsTable from './GroupsTable';

export default {
  name: 'groups-list',
  components: { GroupsTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
