<template>
  <v-dialog scrollable v-model="showDialog" content-class="scrollable-modal">
    <v-card>
      <v-card-title v-if="modalData.title"
        ><h3>{{ $t(modalData.title) }}</h3></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="scrollable-zone">
        <slot name="modalContent"> {{ modalData.content }} </slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <button-primary @click="close" :text="modalData.closeText ? $t(modalData.closeText) : $t('scrollableModal.defaultCloseText')"></button-primary>
        <button-primary v-if="modalData.actionText" @click="doAction" :text="$t(modalData.actionText)"></button-primary>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'modal-scrollable-zone',
  props: {
    modalData: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      showDialog: this.showModal,
    };
  },
  watch: {
    showModal: function(newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      this.showDialog = newVal;
    },
    showDialog: function(newVal) {
      // Important for the reactivity if clicking out of the dialog box closes the modal
      if (newVal === false) {
        this.$emit('close');
      }
    },
  },
  methods: {
    doAction() {
      this.$emit('action');
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
