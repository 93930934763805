<template>
  <v-flex>
    <h1 hidden>{{ $t('transactions.list.title') }}</h1>
    <v-card> </v-card>
    <v-flex class="data-section" v-if="dataReady">
      <transactions-list-item v-if="lastPresentment" :transaction-type="$t('transactions.list.last_presentment')" :transaction="lastPresentment" />
      <transactions-list-item v-if="lastAuthorization" :transaction-type="$t('transactions.list.last_authorization')" :transaction="lastAuthorization" />
      <transactions-list-item v-if="lastEhiTimeout" :transaction-type="$t('transactions.list.last_ehi_timeout')" :transaction="lastEhiTimeout" />
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import TransactionsListItem from './TransactionsListItem.vue';

export default {
  name: 'transaction-list',
  mixins: [actionErrorTrackable, security],
  components: { TransactionsListItem },
  data() {
    return {
      dataReady: false,
    };
  },
  async mounted() {
    await this.fetchTransactions();
  },
  computed: {
    ...mapGetters('transactions', ['lastPresentment', 'lastAuthorization', 'lastEhiTimeout']),
  },
  methods: {
    async fetchTransactions() {
      await Promise.all([this.executeAction({ action: 'transactions/getLastPresentment' }), this.executeAction({ action: 'transactions/getLastAuthorization' }), this.executeAction({ action: 'transactions/getLastEhiTimeout' })]);
      this.dataReady = true;
    },
  },
};
</script>
