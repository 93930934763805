function applyCardProgramPermissionPolicy(permission, value, permissions, notifications = false) {
  if (value === true) {
    if (permission === 'manage_application') {
      !permissions.includes('view_application') ? permissions.push('view_application') : null;
      if (notifications) {
        !notifications.includes('application') ? notifications.push('application') : null;
      }
    }
    if (permission === 'manage_card') {
      !permissions.includes('view_card') ? permissions.push('view_card') : null;
      if (notifications) {
        !notifications.includes('card') ? notifications.push('card') : null;
      }
    }
    if (permission === 'manage_alert' && !permissions.includes('view_alert')) {
      permissions.push('view_alert');
    }
    if (permission === 'manage_merchant' && !permissions.includes('view_merchant')) {
      permissions.push('view_merchant');
    }
    if (permission === 'manage_offer' && !permissions.includes('view_offer')) {
      permissions.push('view_offer');
    }
    if (permission === 'manage_reward' && !permissions.includes('view_reward')) {
      permissions.push('view_reward');
    }
    if (permission === 'manage_aml_program' && !permissions.includes('view_application')) {
      permissions.push('view_application');
    }
    if (permission === 'manage_aml_program' && !permissions.includes('view_card')) {
      permissions.push('view_card');
    }
  }
}

export { applyCardProgramPermissionPolicy };
