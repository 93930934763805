import VueKeyCloak from '@dsb-norge/vue-keycloak-js';

import Auth from './keycloak/Auth.js';
import config from '@/config';

export default ({ Vue, startAppFn }) => {
  Vue.use(VueKeyCloak, {
    logout: {
      redirectUri: config.oidc.redirectUri,
    },
    config: {
      url: config.oidc.url,
      realm: config.oidc.realm,
      clientId: config.oidc.clientId,
    },
    init: {
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
    onInitError: (err) => {
      console.log('Error from KeyCloak initialization'); // eslint-disable-line no-console
      console.log(err); // eslint-disable-line no-console
    },
    onReady: () => {
      startAppFn();
    },
  });

  Vue.use(Auth);
};
