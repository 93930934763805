import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';

const defaultPage = 1;
let paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    brandingCategory: { name: {} },
    brandingCategories: [],
    pageNumber: defaultPage,
    pageCount: 0,
  },

  getters: {
    brandingCategories: (state) => state.brandingCategories,
    getBrandingCategory: (state) => (key) => state.brandingCategories.find((category) => category.key === key),
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
  },

  mutations: {
    brandingCategories(state, categories) {
      state.brandingCategories = categories;
    },
    brandingCategory(state, category) {
      state.brandingCategory = category;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async listBrandingCategories({ commit }, { page, limit }) {
      const brandingService = Vue.prototype.$services.brandingCategory;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;

      const categoryList = await brandingService.listBrandingCategories(thePage, theLimit);

      commit('page', thePage);
      commit('pageCount', categoryList.page_count);
      commit('brandingCategories', categoryList.items);
    },

    async changePage({ dispatch }, { page }) {
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('brandingCategory/listBrandingCategories', { page });
    },

    async getBrandingCategory({ commit }, { key }) {
      const brandingCategoryService = Vue.prototype.$services.brandingCategory;
      const brandingCategory = await brandingCategoryService.getBrandingCategory(key);

      commit('brandingCategory', brandingCategory);
    },

    async updateBrandingCategory({ dispatch }, { branding_category }) {
      const brandingCategoryService = Vue.prototype.$services.brandingCategory;
      const defaultDispatch = safeDispatcher(dispatch);

      const { key } = branding_category;
      delete branding_category.key;

      await brandingCategoryService.updateBrandingCategory(key, branding_category);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'branding_category.update_action.success' });
      await defaultDispatch('brandingCategory/listBrandingCategories', {}); // Pass empty object otherwise listBrandingCategories tries to parse page and limit of undefined.
    },

    async createBrandingCategory({ dispatch }, { branding_category }) {
      const brandingCategoryService = Vue.prototype.$services.brandingCategory;
      const defaultDispatch = safeDispatcher(dispatch);

      await brandingCategoryService.createBrandingCategory(branding_category);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'branding_category.add_action.success' });
      await defaultDispatch('brandingCategory/listBrandingCategories');
    },
  },
};
