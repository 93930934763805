<template>
  <div>
    <error context="global" /> <error context="render" />
    <v-container fill-height>
      <v-layout column>
        <v-layout row align-left align-start> <slot name="content"> </slot> </v-layout>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'view-main',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    document.title = this.$t(this.title);
  },
};
</script>
