<template>
  <v-flex>
    <h1 hidden>{{ $t('guest_services_settings.list.title') }}</h1>
    <v-card class="form-basic pa-2"><button-primary class="smoke-guest-services-settings--btn-add" :loading="working" :text="$t('guest_services_settings.form.add')" @click="showModalAddGuestServicesSettings"></button-primary> </v-card>
    <modal-action :show-modal="showAddGuestServicesSettingsModal"> <add-guest-services-settings-action @close="closeModalAddGuestServicesSettings" @action="reloadPage"></add-guest-services-settings-action> </modal-action>
    <v-flex class="data-section"> <guest-services-settings-list v-if="dataReady && guestServicesSettings" :items="guestServicesSettings"></guest-services-settings-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddGuestServicesSettingsAction from './AddGuestServicesSettingsAction';
import GuestServicesSettingsList from './GuestServicesSettingsList';

export default {
  name: 'view-guest-services-settings-form',
  mixins: [actionErrorTrackable, security],
  components: { GuestServicesSettingsList, AddGuestServicesSettingsAction },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      dataReady: false,
      showAddGuestServicesSettingsModal: false,
    };
  },
  computed: {
    ...mapGetters('guestServicesSettings', ['guestServicesSettings']),
  },
  async created() {
    await this.fetchGuestServicesSettings();
  },
  methods: {
    async fetchGuestServicesSettings() {
      this.dataReady = false;
      await this.executeAction({ action: 'guestServicesSettings/listGuestServicesSettings', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddGuestServicesSettings() {
      this.showAddGuestServicesSettingsModal = true;
    },
    closeModalAddGuestServicesSettings() {
      this.showAddGuestServicesSettingsModal = false;
    },
    reloadPage() {
      this.showAddGuestServicesSettingsModal = false;
    },
  },
};
</script>
