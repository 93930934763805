import Vue from 'vue';

const service = {
  async listPrograms(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatelistProgramsPath(page, limit)}`);
    return response.data.data;
  },

  async getProgram(key) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/card-programs/v1/${key}`);
    return response.data.data;
  },

  async createCardProgram(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/card-programs/v1`, payload);
    return response.data.data;
  },

  async updateCardProgram(key, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-programs/v1/${key}`, payload);
    return response.data.data;
  },
};

function generatelistProgramsPath(page, limit) {
  let path = '/card-programs/v1';
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
