<script>
export default {
  name: 'card-data',
  render() {
    return (
      <v-card class="data-card">
        <v-layout row wrap align-center class="data-card--header-zone">
          <v-flex class="d-inline-flex data-card--header-zone__left" align-content-start>
            {this.$slots.headerZoneLeft}
          </v-flex>
          <v-spacer />
          <v-flex class="d-inline-flex data-card--header-zone__right" justify-end>
            {this.$slots.headerZoneRight}
          </v-flex>
        </v-layout>
        <v-divider class="data-card--divider" />

        <v-layout row wrap align-center justify-start class="data-card--content-zone">
          <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
            {this.$slots.contentZoneUpper}
          </v-flex>
          <v-flex xs12 class="d-inline-flex data-card--content-zone__middle">
            {this.$slots.contentZoneMiddle}
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-end class="data-card--footer-zone">
          {this.$slots.footerZone}
        </v-layout>
      </v-card>
    );
  },
};
</script>
