<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('agent.edit_permissions_action.title')"
    context="editPermissions"
    @submit="submit"
    @cancel="cancel"
    cancel_text="agent.edit_permissions_action.button_cancel"
    submit_text="agent.edit_permissions_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <h4>{{ program.name }}</h4>
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <section class="checkboxes-indented-with-heading">
            <div v-if="permissions" class="edit-permissions-action--permissions">
              <v-label>{{ $t('agent.edit_permissions_action.permissions') }}</v-label>
              <v-checkbox
                v-for="(permission, index) in permissions"
                :key="index"
                v-model="selectedPermissions"
                :label="$t(`program.permissions.${permission}`)"
                :value="permission"
                v-validate="'required'"
                :error-messages="errors.collect('permissions')"
                data-vv-name="permissions"
                :data-vv-as="$t('agent.edit_permissions_action.permissions')"
                @change="addToSelectedPermissions(permission)"
              />
            </div>
          </section>
        </v-flex>
        <v-flex sm6 xs12>
          <section class="checkboxes-indented-with-heading">
            <div v-if="notifications" class="edit-permissions-action--notifications">
              <v-label>{{ $t('agent.edit_permissions_action.notifications') }}</v-label>
              <v-checkbox
                v-for="(notification, index) in notifications"
                :key="index"
                v-model="selectedNotifications"
                :label="$t(`code.notifications.${notification}`)"
                :value="notification"
                :error-messages="errors.collect('notifications')"
                data-vv-name="notifications"
                :data-vv-as="$t('agent.edit_permissions_action.notifications')"
              />
            </div>
          </section>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import { applyCardProgramPermissionPolicy } from '../../utils/ui/card-program-permissions-policy';
import _includes from 'lodash/includes';

export default {
  name: 'edit-permissions-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    agentKey: {
      type: String,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataReady: false,
      selectedPermissions: this.program.permissions,
      selectedNotifications: this.program.notifications,
    };
  },
  computed: {
    ...mapGetters('program', ['permissions', 'notifications']),
    isFormChanged() {
      // TODO: check if checkbox state altered
      return true;
    },
  },
  watch: {
    program(newVal) {
      this.selectedPermissions = newVal.permissions;
      this.selectedNotifications = newVal.notifications;
    },
  },
  methods: {
    addToSelectedPermissions(permission) {
      let value;
      _includes(this.selectedPermissions, permission) ? (value = true) : (value = false);
      applyCardProgramPermissionPolicy(permission, value, this.selectedPermissions, this.selectedNotifications);
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.selectedPermissions = this.program.permissions;
      this.selectedNotifications = this.program.notifications;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'agent/attachProgramToAgent',
            name: 'editPermissions',
            success: this.success,
          },
          {
            key: this.agentKey,
            programKey: this.program.key,
            payload: { permissions: this.selectedPermissions, notifications: this.selectedNotifications },
          },
        );
      }
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async fetchPrograms() {
      this.dataReady = false;
      await this.executeAction(
        {
          action: 'program/listPrograms',
          success: this.success,
        },
        {},
      );
      this.dataReady = true;
    },
  },
};
</script>
