import Vue from 'vue';
import { safeDispatcher } from '../../utils/context/context-helper';
import { cypress } from '@/mixins';

const defaultKeyword = '';
const defaultPage = 1;
let paginationLimit = 20;

if (cypress.computed.cypress()) {
  paginationLimit = 5;
}

export default {
  namespaced: true,
  state: {
    agents: [],
    currentKeyword: defaultKeyword,
    currentExcludeDeactivated: true,
    pageNumber: defaultPage,
    pageCount: 0,
  },

  getters: {
    agentsFromSearch: (state) => state.agents,
    currentKeyword: (state) => state.currentKeyword,
    currentExcludeDeactivated: (state) => state.currentExcludeDeactivated,
    pageNumber: (state) => state.pageNumber,
    pageCount: (state) => state.pageCount,
    agent: (state) => (key) => {
      return state.agents.find((agent) => agent.key === key);
    },
  },

  mutations: {
    agents(state, agentsFromSearch) {
      state.agents = agentsFromSearch;
    },
    keyword(state, keyword) {
      state.currentKeyword = keyword;
    },
    excludeDeactivated(state, excludeDeactivated) {
      state.currentExcludeDeactivated = excludeDeactivated;
    },
    pageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    page(state, page) {
      state.pageNumber = page;
    },
  },

  actions: {
    async searchAgents({ commit }, { page, limit, keyword, exclude_deactivated = false }) {
      const agentService = Vue.prototype.$services.agent;
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || defaultKeyword;

      const agentsFromSearch = await agentService.searchAgent(thePage, theLimit, theKeyword, exclude_deactivated);

      commit('excludeDeactivated', exclude_deactivated);
      commit('keyword', theKeyword);
      commit('page', thePage);
      commit('pageCount', agentsFromSearch.page_count);
      commit('agents', agentsFromSearch.items);
    },

    async changePage({ commit, state }, { page }) {
      commit('page', page);

      const agentService = Vue.prototype.$services.agent;
      const limit = paginationLimit;
      const keyword = state.currentKeyword;
      const exclude_deactivated = state.currentExcludeDeactivated;

      const agentsFromSearch = await agentService.searchAgent(page, limit, keyword, exclude_deactivated);

      commit('pageCount', agentsFromSearch.page_count);
      commit('agents', agentsFromSearch.items);
    },

    async createAgent({ dispatch, state }, payload) {
      const agentService = Vue.prototype.$services.agent;
      const defaultDispatch = safeDispatcher(dispatch);

      await agentService.createAgent(payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'agent.add_agent_action.success' });

      await defaultDispatch('agent/searchAgents', { page: state.pageNumber, keyword: state.currentKeyword, exclude_deactivated: state.currentExcludeDeactivated });
    },

    async updateAgent({ dispatch, state }, { key, agent }) {
      const agentService = Vue.prototype.$services.agent;
      const defaultDispatch = safeDispatcher(dispatch);

      await agentService.updateAgent(key, agent);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'agent.update_agent_action.success' });

      await defaultDispatch('agent/searchAgents', { page: state.pageNumber, keyword: state.currentKeyword, exclude_deactivated: state.currentExcludeDeactivated });
    },

    async deleteAgent({ dispatch, state }, key) {
      const agentService = Vue.prototype.$services.agent;
      const defaultDispatch = safeDispatcher(dispatch);

      const res = await agentService.deleteAgent(key);

      if (res.result === 'fail') {
        res.error.code = 'agent.deletion';
        throw new errorTypes.HttpError('fail', res);
      }

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'agent.delete_agent_action.success' });

      await defaultDispatch('agent/searchAgents', { page: state.pageNumber, keyword: state.currentKeyword, exclude_deactivated: state.currentExcludeDeactivated });
    },

    async undeleteAgent({ dispatch, state }, key) {
      const agentService = Vue.prototype.$services.agent;
      const defaultDispatch = safeDispatcher(dispatch);

      const res = await agentService.undeleteAgent(key);

      if (res.result === 'fail') {
        res.error.code = 'agent.undeletion';
        throw new errorTypes.HttpError('fail', res);
      }

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'agent.undelete_agent_action.success' });

      await defaultDispatch('agent/searchAgents', { page: state.pageNumber, keyword: state.currentKeyword, exclude_deactivated: state.currentExcludeDeactivated });
    },

    async attachProgramToAgent({ dispatch, state }, { key, programKey, payload }) {
      const agentService = Vue.prototype.$services.agent;
      const defaultDispatch = safeDispatcher(dispatch);

      await agentService.attachProgramToAgent(key, programKey, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'agent.attach_program_action.success' });

      await defaultDispatch('agent/searchAgents', { page: state.pageNumber, keyword: state.currentKeyword, exclude_deactivated: state.currentExcludeDeactivated });
    },

    async removeProgramFromAgent({ dispatch, state }, { key, programKey, payload }) {
      const agentService = Vue.prototype.$services.agent;
      const defaultDispatch = safeDispatcher(dispatch);

      await agentService.removeProgramFromAgent(key, programKey, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'agent.remove_program_action.success' });

      await defaultDispatch('agent/searchAgents', { page: state.pageNumber, keyword: state.currentKeyword, exclude_deactivated: state.currentExcludeDeactivated });
    },
  },
};
