import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    lastPresentment: {},
    lastAuthorization: {},
    lastEhiTimeout: {},
  },
  mutations: {
    lastPresentment(state, presentment) {
      state.lastPresentment = presentment;
    },
    lastAuthorization(state, authorization) {
      state.lastAuthorization = authorization;
    },
    lastEhiTimeout(state, ehiTimeout) {
      state.lastEhiTimeout = ehiTimeout;
    },
  },
  getters: {
    lastPresentment: (state) => state.lastPresentment,
    lastAuthorization: (state) => state.lastAuthorization,
    lastEhiTimeout: (state) => state.lastEhiTimeout,
  },
  actions: {
    async getLastPresentment({ commit }) {
      const transactionsService = Vue.prototype.$services.transaction;

      const lastPresentment = await transactionsService.getLastPresentment();

      commit('lastPresentment', lastPresentment);
    },

    async getLastAuthorization({ commit }) {
      const transactionsService = Vue.prototype.$services.transaction;

      const lastAuthorization = await transactionsService.getLastAuthorization();

      commit('lastAuthorization', lastAuthorization);
    },

    async getLastEhiTimeout({ commit }) {
      const transactionsService = Vue.prototype.$services.transaction;

      const lastEhiTimeout = await transactionsService.getLastEhiTimeout();

      commit('lastEhiTimeout', lastEhiTimeout);
    },
  },
};
