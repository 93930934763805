<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('package.update_package_action.title')"
    context="updatePackage"
    @submit="submit"
    @cancel="cancel"
    cancel_text="package.update_package_action.button_cancel"
    submit_text="package.update_package_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            name="id"
            v-model="currentPackage.id"
            v-validate="'required|max:30'"
            :label="$t('package.update_package_action.fields.id')"
            :error-messages="errors.collect('id')"
            data-vv-name="id"
            :data-vv-as="$t('package.update_package_action.fields.id')"
            required
            counter
            maxlength="30"
            class="update-package--id"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="name"
            v-model="currentPackage.name"
            v-validate="'required|max:30'"
            :label="$t('package.update_package_action.fields.name')"
            :error-messages="errors.collect('name')"
            data-vv-name="name"
            :data-vv-as="$t('package.update_package_action.fields.name')"
            required
            counter
            maxlength="30"
            class="update-package--name"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-select :items="programNameAndKeys" v-model="currentPackage.program" item-text="name" item-value="key" :label="currentPackage.program" class="update-package--program" />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="card_design"
            v-model="currentPackage.card_design"
            v-validate="'required|max:8'"
            :label="$t('package.update_package_action.fields.card_design')"
            :error-messages="errors.collect('card_design')"
            data-vv-card_design="card_design"
            :data-vv-as="$t('package.update_package_action.fields.card_design')"
            required
            counter
            maxlength="8"
            class="update-package--card_design"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            name="logo_front_id"
            v-model="currentPackage.logo_front_id"
            v-validate="'max:30'"
            :label="$t('package.update_package_action.fields.logo_front_id')"
            :error-messages="errors.collect('logo_front_id')"
            data-vv-logo_front_id="logo_front_id"
            :data-vv-as="$t('package.update_package_action.fields.logo_front_id')"
            maxlength="30"
            class="add-package--logo_front_id"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="logo_back_id"
            v-model="currentPackage.logo_back_id"
            v-validate="'max:30'"
            :label="$t('package.update_package_action.fields.logo_back_id')"
            :error-messages="errors.collect('logo_back_id')"
            data-vv-logo_back_id="logo_back_id"
            :data-vv-as="$t('package.update_package_action.fields.logo_back_id')"
            maxlength="30"
            class="add-package--logo_back_id"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-text-field
            name="product_ref"
            v-model="currentPackage.product_ref"
            v-validate="'required|max:50'"
            :label="$t('package.update_package_action.fields.product_ref')"
            :error-messages="errors.collect('product_ref')"
            data-vv-product_ref="product_ref"
            :data-vv-as="$t('package.update_package_action.fields.product_ref')"
            required
            counter
            maxlength="50"
            class="update-package--product_ref"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="carrier_type"
            v-model="currentPackage.carrier_type"
            v-validate="'required|max:30'"
            :label="$t('package.update_package_action.fields.carrier_type')"
            :error-messages="errors.collect('carrier_type')"
            data-vv-carrier_type="carrier_type"
            :data-vv-as="$t('package.update_package_action.fields.carrier_type')"
            required
            counter
            maxlength="30"
            class="update-package--carrier_type"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="card_product_id"
            v-model="currentPackage.card_product_id"
            v-validate="'max:50'"
            :label="$t('package.update_package_action.fields.card_product_id')"
            :error-messages="errors.collect('card_product_id')"
            data-vv-card_product_id="card_product_id"
            :data-vv-as="$t('package.update_package_action.fields.card_product_id')"
            counter
            maxlength="50"
            class="update-package--card_product_id"
          />
        </v-flex>
        <v-flex sm6 xs12>
          <v-checkbox v-model="currentPackage.bulk" :label="$t('package.update_package_action.fields.bulk')" :error-messages="errors.collect('bulk')" data-vv-bulk="bulk" :data-vv-as="$t('package.update_package_action.fields.bulk')" />
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

import _ from 'lodash';

export default {
  name: 'update-package-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  props: {
    package: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPackage: {
        id: this.package.id,
        name: this.package.name,
        program: this.package.card_program_key,
        card_product_id: this.package.card_product_id,
        logo_front_id: this.package && this.package.gps ? this.package.gps.logo_front_id : '',
        logo_back_id: this.package && this.package.gps ? this.package.gps.logo_back_id : '',
        card_design: this.package && this.package.gps ? this.package.gps.card_design : '',
        product_ref: this.package && this.package.gps ? this.package.gps.product_ref : '',
        carrier_type: this.package && this.package.gps ? this.package.gps.carrier_type : '',
        bulk: this.package.bulk,
      },
    };
  },
  computed: {
    ...mapGetters('program', ['programNameAndKeys']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    package() {
      this.currentPackage = {
        id: this.package.id,
        name: this.package.name,
        program: this.package.card_program_key,
        card_product_id: this.package.card_product_id,
        logo_front_id: this.package && this.package.gps ? this.package.gps.logo_front_id : '',
        logo_back_id: this.package && this.package.gps ? this.package.gps.logo_back_id : '',
        card_design: this.package && this.package.gps ? this.package.gps.card_design : '',
        product_ref: this.package && this.package.gps ? this.package.gps.product_ref : '',
        carrier_type: this.package && this.package.gps ? this.package.gps.carrier_type : '',
        bulk: this.package.bulk,
      };
    },
  },
  methods: {
    resetForm() {
      const originalPackage = this.$store.getters['package/packageByKey'](this.package.key);

      this.currentPackage = {
        id: originalPackage.id,
        name: originalPackage.name,
        program: originalPackage.card_program_key,
        card_product_id: originalPackage.card_product_id,
        logo_front_id: originalPackage.gps.logo_front_id,
        logo_back_id: originalPackage.gps.logo_back_id,
        card_design: originalPackage.gps.card_design,
        product_ref: originalPackage.gps.product_ref,
        carrier_type: originalPackage.gps.carrier_type,
        bulk: originalPackage.bulk,
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const cardPackage = {
          id: this.currentPackage.id,
          name: this.currentPackage.name,
          program: this.currentPackage.program,
          card_product_id: this.currentPackage.card_product_id,
          gps: {
            logo_front_id: this.currentPackage.logo_front_id,
            logo_back_id: this.currentPackage.logo_back_id,
            card_design: this.currentPackage.card_design,
            product_ref: this.currentPackage.product_ref,
            carrier_type: this.currentPackage.carrier_type,
          },
          bulk: this.currentPackage.bulk,
        };

        await this.executeAction(
          {
            action: 'package/updatePackage',
            name: 'updatePackage',
            success: this.success,
          },
          {
            key: this.package.key,
            cardPackage,
          },
        );
      }
    },
    success() {
      this.$validator.reset();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
