import axios from 'axios';
import errorTypes from '@/utils/error/error-types';

function isUnauthorized(status) {
  return status === 401;
}
function isForbidden(status) {
  return status === 403;
}
function isMethodNotAllowed(status) {
  return status === 405;
}

function isValidationError(status, code) {
  return status === 400 && code === 'validation.error';
}

function isServerError(status) {
  return status >= 500;
}

export default ({ Vue, router, store }) => {
  Vue.prototype.$axios = axios;

  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.validateStatus = (status) => (status >= 200 && status < 300) || status === 404;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let statusCode = error.response.status;
      let errorCode = error.response.data.error.code;

      if (isUnauthorized(statusCode) || isForbidden(statusCode)) {
        store.dispatch('security/logout');
        router.push({ name: 'login' });
        throw new errorTypes.SystemError(error.response.status, error.response.data);
      }

      if (isMethodNotAllowed(statusCode) || isValidationError(statusCode, errorCode) || isServerError(statusCode)) {
        throw new errorTypes.SystemError(error.response.status, error.response.data);
      }

      throw new errorTypes.HttpError(error.response.status, error.response.data);
    },
  );
};
