import Vue from 'vue';
import Errors from './../utils/error/error-types';

const service = {
  async createGuestServicesSettings(payload) {
    try {
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/guest-services-settings`, payload);
      return response.data.data;
    } catch (e) {
      if (guestServicesSettingsAlreadyExists(e)) {
        return Promise.reject(transformToHttpError(400, 'guest_services_settings.id.already_exists'));
      }

      throw e;
    }
  },

  async updateGuestServicesSettings(partner_id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/guest-services-settings/${partner_id}`, payload);

    if (guestServicesSettingsNotFound(response.data.error)) {
      return Promise.reject(transformToHttpError(400, 'guest_services_settings.not_found'));
    }

    return response.data.data;
  },

  async getGuestServicesSettings(partner_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/guest-services-settings/${partner_id}`);
    return response.data.data;
  },

  async listGuestServicesSettings(params) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/partner/v1/partners/guest-services-settings', params)}`);
    return response.data.data;
  },
};

function guestServicesSettingsNotFound(error) {
  return error && error.code === 'system.resource.not.found';
}

function guestServicesSettingsAlreadyExists(e) {
  return e.response_code === 'guest_services_settings.already.exists';
}

function transformToHttpError(statusCode, code) {
  return new Errors.HttpError(statusCode, { error: { code } });
}

function generatePath(startPath, { partner_id, page, limit }) {
  let path = startPath;
  if (page || limit || partner_id) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
