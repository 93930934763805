<template>
  <v-layout column>
    <v-layout class="py-1" row align-content-center fill-height>
      <v-layout row>
        <slot name="author"
          ><span class="message-card--author" v-if="author">{{ author }}</span></slot
        >
        <span class="message-card--date px-3" v-if="date">{{ date }}</span>
      </v-layout>
    </v-layout>
    <slot name="content"><v-layout v-if="content" column v-html="generateHtml(content, 'p')"></v-layout></slot> <slot name="divider"></slot>
  </v-layout>
</template>

<script>
export default {
  name: 'layout-download',
  props: {
    author: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
  },
};
</script>
