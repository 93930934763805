<template>
  <v-flex>
    <h1 hidden>{{ $t('group.list.title') }}</h1>
    <v-card class="form-basic pa-2"> <button-primary class="group-form--btn-add" :loading="working" :text="$t('group.form.add')" @click="showModalAddGroup"></button-primary> </v-card>

    <modal-action :show-modal="showAddGroupModal"> <add-group-action @close="closeModalAddGroup" @action="reloadPage"></add-group-action> </modal-action>

    <v-flex class="data-section"> <groups-list v-if="dataReady && groups" :items="groups"></groups-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, cypress, security } from '@/mixins';
import GroupsList from './GroupsList';
import AddGroupAction from './AddGroupAction';

export default {
  name: 'view-group-form',
  mixins: [actionErrorTrackable, security, cypress],
  components: { GroupsList, AddGroupAction },
  data() {
    return {
      dataReady: false,
      showAddGroupModal: false,
    };
  },
  computed: {
    ...mapGetters('group', ['groups']),
  },
  async created() {
    await this.fetchGroups();
  },
  methods: {
    async fetchGroups() {
      this.dataReady = false;
      await this.executeAction({ action: 'group/listGroups', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddGroup() {
      this.showAddGroupModal = true;
    },
    closeModalAddGroup() {
      this.showAddGroupModal = false;
    },
    reloadPage() {
      this.showAddGroupModal = false;
    },
  },
};
</script>
