<template>
  <div>
    <table class="responsive-table smoke-guest-services-settings--list-table">
      <thead>
        <tr>
          <td>{{ $t('guest_services_settings.listTable.partner_id') }}</td>
          <td>{{ $t('guest_services_settings.listTable.consumer_payment_notice.fr') }}</td>
          <td>{{ $t('guest_services_settings.listTable.consumer_payment_notice.en') }}</td>
          <td>{{ $t('guest_services_settings.listTable.consumer_purchase_over_limit_notice.fr') }}</td>
          <td>{{ $t('guest_services_settings.listTable.consumer_purchase_over_limit_notice.en') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="smoke-guest-services-settings--list-table--partner_id-column">
            <h5>{{ $t('guest_services_settings.listTable.partner_id') }}:</h5>
            <span :class="{ 'info-not-available': !item.partner_id }">{{ item.partner_id | orNone }}</span>
          </td>
          <td class="smoke-guest-services-settings--list-table--consumer_payment_notice-fr-column">
            <h5>{{ $t('guest_services_settings.listTable.consumer_payment_notice.fr') }}:</h5>
            <span :class="{ 'info-not-available': !item.consumer_payment_notice || !item.consumer_payment_notice.fr }">{{ item.consumer_payment_notice.fr | orNone }}</span>
          </td>
          <td class="smoke-guest-services-settings--list-table--consumer_payment_notice-en-column">
            <h5>{{ $t('guest_services_settings.listTable.consumer_payment_notice.en') }}:</h5>
            <span :class="{ 'info-not-available': !item.consumer_payment_notice || !item.consumer_payment_notice.en }">{{ item.consumer_payment_notice.en | orNone }}</span>
          </td>
          <td class="smoke-guest-services-settings--list-table--consumer_purchase_over_limit_notice-fr-column">
            <h5>{{ $t('guest_services_settings.listTable.consumer_purchase_over_limit_notice.fr') }}:</h5>
            <span :class="{ 'info-not-available': !item.consumer_purchase_over_limit_notice || !item.consumer_purchase_over_limit_notice.fr }">{{ item.consumer_purchase_over_limit_notice.fr | orNone }}</span>
          </td>
          <td class="smoke-guest-services-settings--list-table--consumer_purchase_over_limit_notice-en-column">
            <h5>{{ $t('guest_services_settings.listTable.consumer_purchase_over_limit_notice.en') }}:</h5>
            <span :class="{ 'info-not-available': !item.consumer_purchase_over_limit_notice || !item.consumer_purchase_over_limit_notice.en }">{{ item.consumer_purchase_over_limit_notice.en | orNone }}</span>
          </td>
          <td class="smoke-guest-services-settings--list-table--actions-column">
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passGuestServicesSettingsToModal(item);
                showModalUpdateGuestServicesSettings();
              "
              class="smoke-guest-services-settings--list-table--edit-button"
              >{{ $t(`guest_services_settings.listTable.edit`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdateGuestServicesSettingsModal"> <update-guest-services-settings-action :guest-services-settings="currentGuestServicesSettings" @close="closeModalUpdateGuestServicesSettings" @action="reloadPage" /> </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="guest-services-settings-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import UpdateGuestServicesSettingsAction from './UpdateGuestServicesSettingsAction';
import { mapGetters } from 'vuex';

export default {
  name: 'guest-services-settings-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateGuestServicesSettingsAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentGuestServicesSettings: {},
      pagination: 1,
      showUpdateGuestServicesSettingsModal: false,
    };
  },
  computed: {
    ...mapGetters('guestServicesSettings', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'guestServicesSettings/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.showUpdateGuestServicesSettingsModal = false;
    },
    passGuestServicesSettingsToModal(item) {
      this.currentGuestServicesSettings = item;
    },
    showModalUpdateGuestServicesSettings() {
      this.showUpdateGuestServicesSettingsModal = true;
    },
    closeModalUpdateGuestServicesSettings() {
      this.showUpdateGuestServicesSettingsModal = false;
    },
  },
};
</script>
