<template>
  <v-flex>
    <h1 hidden>{{ $t('space.list.title') }}</h1>
    <v-card class="form-basic pa-2"><button-primary class="smoke-space--btn-add" :loading="working" :text="$t('space.form.add')" @click="showModalAddSpace"></button-primary> </v-card>
    <modal-action :show-modal="showAddSpaceModal"> <add-space-action @close="closeModalAddSpace" @action="reloadPage"></add-space-action> </modal-action>
    <v-flex class="data-section"> <space-list v-if="dataReady && spaces" :items="spaces"></space-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AddSpaceAction from './AddSpaceAction';
import SpaceList from './SpaceList';

export default {
  name: 'view-space-form',
  mixins: [actionErrorTrackable, security],
  components: { SpaceList, AddSpaceAction },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      dataReady: false,
      showAddSpaceModal: false,
    };
  },
  computed: {
    ...mapGetters('space', ['spaces']),
  },
  async created() {
    await this.fetchSpaces();
  },
  methods: {
    async fetchSpaces() {
      this.dataReady = false;
      await this.executeAction({ action: 'space/listSpaces', success: this.success }, {});
      this.dataReady = true;
    },
    showModalAddSpace() {
      this.showAddSpaceModal = true;
    },
    closeModalAddSpace() {
      this.showAddSpaceModal = false;
    },
    reloadPage() {
      this.showAddSpaceModal = false;
    },
  },
};
</script>
