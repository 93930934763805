<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('agent.attach_program_action.title')"
    context="attachProgramToAgent"
    @submit="submit"
    @cancel="cancel"
    cancel_text="agent.attach_program_action.button_cancel"
    submit_text="agent.attach_program_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout>
        <v-flex xs12> <v-select class="attach-program-action--programs" :items="unassignedPrograms" v-model="programKey" item-text="name" item-value="key" :label="dropdownLabel" /> </v-flex>
      </v-layout>

      <v-layout v-if="programSelected" row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <section class="checkboxes-indented-with-heading">
            <div v-if="permissions" class="attach-program-action--permissions">
              <v-label>{{ $t('agent.attach_program_action.permissions') }}</v-label>
              <v-checkbox
                v-for="(permission, index) in permissions"
                :key="index"
                v-model="selectedPermissions"
                :label="$t(`program.permissions.${permission}`)"
                :value="permission"
                v-validate="'required'"
                :error-messages="errors.collect('permissions')"
                data-vv-name="permissions"
                :data-vv-as="$t('agent.attach_program_action.permissions')"
                @change="addToSelectedPermissions(permission)"
              />
            </div>
          </section>
        </v-flex>
        <v-flex sm6 xs12>
          <section class="checkboxes-indented-with-heading">
            <div v-if="notifications" class="attach-program-action--notifications">
              <v-label>{{ $t('agent.attach_program_action.notifications') }}</v-label>
              <v-checkbox
                v-for="(notification, index) in notifications"
                :key="index"
                v-model="selectedNotifications"
                :label="$t(`code.notifications.${notification}`)"
                :value="notification"
                :error-messages="errors.collect('notifications')"
                data-vv-name="notifications"
                :data-vv-as="$t('agent.attach_program_action.notifications')"
              />
            </div>
          </section>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import _ from 'lodash';
import { applyCardProgramPermissionPolicy } from '../../utils/ui/card-program-permissions-policy';

export default {
  name: 'attach-program-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    agentKey: {
      type: String,
      required: true,
    },
    programs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      programKey: '',
      dataReady: false,
      dropdownLabel: this.$t('agent.attach_program_action.fields.programs'),
      selectedPermissions: [],
      selectedNotifications: [],
      unassignedPrograms: [],
    };
  },
  computed: {
    ...mapGetters('program', ['programNameAndKeys', 'permissions', 'notifications']),
    isFormChanged() {
      return this.programSelected ? true : false;
    },
    programSelected() {
      return this.programKey && this.programKey !== this.dropdownLabel;
    },
  },
  watch: {
    programs() {
      this.unassignedPrograms = _.differenceBy(this.programNameAndKeys, this.programs, 'key');
    },
  },
  async created() {
    await this.fetchPrograms();
  },
  methods: {
    addToSelectedPermissions(permission) {
      let value;
      _.includes(this.selectedPermissions, permission) ? (value = true) : (value = false);
      applyCardProgramPermissionPolicy(permission, value, this.selectedPermissions, this.selectedNotifications);
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.programKey = this.dropdownLabel;
      this.selectedPermissions = [];
      this.selectedNotifications = [];
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'agent/attachProgramToAgent',
            name: 'attachProgramToAgent',
            success: this.success,
          },
          {
            key: this.agentKey,
            programKey: this.programKey,
            payload: { permissions: this.selectedPermissions, notifications: this.selectedNotifications },
          },
        );
      }
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async fetchPrograms() {
      this.dataReady = false;
      await this.executeAction(
        {
          action: 'program/listPrograms',
          success: this.success,
        },
        {},
      );
      this.dataReady = true;
    },
  },
};
</script>
