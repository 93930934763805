<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('partner.update_partner_action.title')"
    context="updatePartner"
    @submit="submit"
    @cancel="cancel"
    cancel_text="partner.update_partner_action.button_cancel"
    submit_text="partner.update_partner_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    class="smoke-partner--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="partner.id"
            v-model="currentPartner.id"
            disabled
            :label="$t('partner.update_partner_action.fields.id')"
            :error-messages="errors.collect('partner.id')"
            data-vv-name="partner.id"
            :data-vv-as="$t('partner.update_partner_action.fields.id')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--update--id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.name"
            v-model="currentPartner.name"
            :label="$t('partner.update_partner_action.fields.name')"
            :error-messages="errors.collect('currentPartner.name')"
            data-vv-name="currentPartner.name"
            :data-vv-as="$t('partner.update_partner_action.fields.name')"
            v-validate="'required|max:100'"
            required
            counter
            maxlength="100"
            class="smoke-partner--update--name"
          />
        </v-flex>
        <v-flex xs12>
          <v-select
            name="partner.region"
            v-model="currentPartner.region"
            :label="$t('partner.update_partner_action.fields.region')"
            :error-messages="errors.collect('partner.region')"
            data-vv-name="partner.region"
            :data-vv-as="$t('partner.update_partner_action.fields.region')"
            :items="regionOptions"
            v-validate="'required|max:20'"
            class="smoke-partner--update--region"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-select
            name="partner.timezone"
            v-model="currentPartner.timezone"
            :label="$t('partner.update_partner_action.fields.timezone')"
            :error-messages="errors.collect('partner.timezone')"
            data-vv-name="partner.timezone"
            :data-vv-as="$t('partner.update_partner_action.fields.timezone')"
            :items="timezoneOptions"
            v-validate="'required|max:20'"
            class="smoke-partner--update--timezone"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.business_id"
            v-model="currentPartner.business_id"
            :label="$t('partner.update_partner_action.fields.business_id')"
            :error-messages="errors.collect('currentPartner.business_id')"
            data-vv-name-id="currentPartner.business_id"
            :data-vv-as="$t('partner.update_partner_action.fields.business_id')"
            v-validate="'max:20'"
            required
            counter
            maxlength="20"
            class="smoke-partner--update--business-id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.card_packages_en"
            v-model="currentPartner.card_packages.en"
            :label="$t('partner.update_partner_action.fields.card_packages_en')"
            :error-messages="errors.collect('currentPartner.card_packages.en')"
            data-vv-name="currentPartner.card_packages.en"
            :data-vv-as="$t('partner.update_partner_action.fields.card_packages_en')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--update--card_package_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.card_packages_fr"
            v-model="currentPartner.card_packages.fr"
            :label="$t('partner.update_partner_action.fields.card_packages_fr')"
            :error-messages="errors.collect('currentPartner.card_packages.fr')"
            data-vv-card_name="currentPartner.card_packages.fr"
            :data-vv-as="$t('partner.update_partner_action.fields.card_packages_fr')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--update--card_package_fr"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.renew_card_packages_en"
            v-model="currentPartner.renew_card_packages.en"
            :label="$t('partner.update_partner_action.fields.renew_card_packages_en')"
            :error-messages="errors.collect('currentPartner.renew_card_packages.en')"
            data-vv-name="currentPartner.renew_card_packages.en"
            :data-vv-as="$t('partner.update_partner_action.fields.renew_card_packages_en')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--update--renew_card_package_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.renew_card_packages_fr"
            v-model="currentPartner.renew_card_packages.fr"
            :label="$t('partner.update_partner_action.fields.renew_card_packages_fr')"
            :error-messages="errors.collect('currentPartner.renew_card_packages.fr')"
            data-vv-card_name="currentPartner.card_packages.fr"
            :data-vv-as="$t('partner.update_partner_action.fields.renew_card_packages_fr')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
            class="smoke-partner--update--renew_card_package_fr"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.instant_card_packages_en"
            v-model="currentPartner.instant_card_packages.en"
            :label="$t('partner.update_partner_action.fields.instant_card_packages_en')"
            :error-messages="errors.collect('currentPartner.instant_card_packages.en')"
            data-vv-name="currentPartner.instant_card_packages.en"
            :data-vv-as="$t('partner.update_partner_action.fields.instant_card_packages_en')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--update--instant_card_packages_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.instant_card_packages_fr"
            v-model="currentPartner.instant_card_packages.fr"
            :label="$t('partner.update_partner_action.fields.instant_card_packages_fr')"
            :error-messages="errors.collect('currentPartner.instant_card_packages.fr')"
            data-vv-card_name="currentPartner.instant_card_packages.fr"
            :data-vv-as="$t('partner.update_partner_action.fields.instant_card_packages_fr')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--update--instant_card_packages_fr"
          />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.instant_renew_card_packages_en"
            v-model="currentPartner.instant_renew_card_packages.en"
            :label="$t('partner.update_partner_action.fields.instant_renew_card_packages_en')"
            :error-messages="errors.collect('currentPartner.instant_renew_card_packages.en')"
            data-vv-name="currentPartner.instant_renew_card_packages.en"
            :data-vv-as="$t('partner.update_partner_action.fields.instant_renew_card_packages_en')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--update--instant_renew_card_package_en"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="currentPartner.instant_renew_card_packages_fr"
            v-model="currentPartner.instant_renew_card_packages.fr"
            :label="$t('partner.update_partner_action.fields.instant_renew_card_packages_fr')"
            :error-messages="errors.collect('currentPartner.instant_renew_card_packages.fr')"
            data-vv-card_name="currentPartner.instant__renew_card_packages.fr"
            :data-vv-as="$t('partner.update_partner_action.fields.instant_renew_card_packages_fr')"
            v-validate="'max:50'"
            counter
            maxlength="50"
            class="smoke-partner--update--instant_renew_card_package_fr"
          />
        </v-flex>
      </v-layout>

      <v-tabs height="34" class="modal-tabs">
        <v-tab key="options" class="smoke-partner--update--tab-options">{{ $t('partner.update_partner_action.fields.options.title') }}</v-tab>
        <v-tab key="webhooks" class="smoke-partner--update--tab-webhooks">{{ $t('partner.update_partner_action.fields.webhooks.title') }}</v-tab>
        <v-tab key="content" class="smoke-partner--update--tab-content">{{ $t('partner.update_partner_action.fields.content.title') }}</v-tab>
        <v-tab key="logo" class="smoke-partner--update--tab-logos">{{ $t('partner.update_partner_action.fields.logo.title') }}</v-tab>
        <v-tab key="features" class="smoke-partner--update--tab-features">{{ $t('partner.update_partner_action.fields.features.title') }}</v-tab>
        <v-tab key="apply" class="smoke-partner--update--tab-apply">{{ $t('partner.update_partner_action.fields.apply.title') }}</v-tab>
        <v-tab key="apply" class="smoke-partner--update--tab-instant-card-application">{{ $t('partner.update_partner_action.fields.instant_card_application.title') }}</v-tab>
        <v-tab key="connector_interfaces" class="smoke-partner--add--tab-connector-interfaces">{{ $t('partner.update_partner_action.fields.connector_interfaces.title') }}</v-tab>

        <v-tab-item key="options">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('partner.update_partner_action.fields.options.title') }}</v-label>
              <div class="add-partner-action--options">
                <v-flex xs12>
                  <v-text-field
                    type="text"
                    name="currentPartner.options.integration_jwt_secret"
                    v-model="currentPartner.options.integration_jwt_secret"
                    :label="$t('partner.update_partner_action.fields.options.integration_jwt_secret')"
                    class="smoke-partner--update--integration_jwt_secret"
                    :error-messages="errors.collect('currentPartner.options.integration_jwt_secret')"
                    data-vv-name="currentPartner.options.integration_jwt_secret"
                    :data-vv-as="$t('partner.update_partner_action.fields.options.integration_jwt_secret')"
                    v-validate="'max:255'"
                    maxlength="255"
                  >
                  </v-text-field>
                </v-flex>
                <v-checkbox v-model="currentPartner.options.card_activation_reminder.active" :label="$t('partner.update_partner_action.fields.options.card_activation_reminder')" class="smoke-partner--update--card_activation_reminder" />
                <v-checkbox v-model="currentPartner.options.join_program.active" :label="$t('partner.update_partner_action.fields.options.join_program')" class="smoke-partner--update--join_program" />
                <v-checkbox v-model="currentPartner.options.card_application.active" :label="$t('partner.update_partner_action.fields.options.card_application')" class="smoke-partner--update--card_application" />
                <v-checkbox
                  v-model="currentPartner.options.card_application.send_alert_on_approval"
                  :label="$t('partner.update_partner_action.fields.options.card_application_send_alert_on_approval')"
                  class="smoke-partner--update--card_application_send_alert_on_approval"
                />
                <v-checkbox v-model="currentPartner.options.card_activation.active" :label="$t('partner.update_partner_action.fields.options.card_activation')" class="smoke-partner--update--card_activation" />
                <v-checkbox v-model="currentPartner.options.manageable" :label="$t('partner.update_partner_action.fields.options.manageable')" class="smoke-partner--update--manageable" />
                <v-checkbox v-model="currentPartner.options.invite_only" :label="$t('partner.update_partner_action.fields.options.invite_only')" class="smoke-partner--update--invite_only" />
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs11>
              <v-text-field
                name="allowed_card_package_ids"
                v-model="temp_allowed_card_package_ids"
                :label="$t('partner.update_partner_action.fields.options.allowed_card_package_ids')"
                class="smoke-partner--update--allowed_card_package_ids"
                v-validate="'max:255'"
                :error-messages="errors.collect('allowed_card_package_ids')"
                data-vv-name="allowed_card_package_ids"
                required
                counter
                maxlength="255"
                :data-vv-as="$t('partner.update_partner_action.fields.options.allowed_card_package_ids')"
              ></v-text-field>
            </v-flex>
            <v-flex xs1>
              <button type="button" class="smoke-partner--update--add-allowed_card_package_ids" @click="addAllowedCardPacakgeIdToArray">
                <v-icon small>fal fa-plus</v-icon>
              </button>
            </v-flex>
            <v-flex v-if="currentPartner && currentPartner.options.card_application.allowed_card_package_ids && currentPartner.options.card_application.allowed_card_package_ids.length > 0" xs11 class="smoke-partner--add--allowed_card_package_ids-section">
              <v-label>{{ $t('partner.update_partner_action.fields.options.allowed_card_package_ids') }}</v-label>
              <ul class="smoke-partner--update--allowed_card_package_ids-list">
                <li v-for="(allowedCardPacakgeId, index) in currentPartner.options.card_application.allowed_card_package_ids" :key="index">
                  {{ allowedCardPacakgeId }}
                  <button type="button" class="smoke-partner--update--remove-allowed_card_package_ids" @click="removeAllowedCardPacakgeIdFromArray(allowedCardPacakgeId)">
                    <v-icon small>fal fa-minus-circle</v-icon>
                  </button>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="webhooks" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.webhooks.title') }}</v-label>
            </v-flex>
          </v-layout>

          <!-- MEMBERSHIP -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="currentPartner.webhooks.membership_activity.active" :label="$t('partner.update_partner_action.fields.webhooks.membership_activity')" class="smoke-partner--update--webhooks_membership_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.webhooks.membership_activity.uri"
                v-model="currentPartner.webhooks.membership_activity.uri"
                :label="$t('partner.update_partner_action.fields.webhooks.uri') + `${currentPartner.webhooks.membership_activity.active ? ' *' : ''}`"
                class="smoke-partner--update--webhooks_membership_activity_uri"
                :error-messages="errors.collect('currentPartner.webhooks.membership_activity.uri')"
                data-vv-name="currentPartner.webhooks.membership_activity.uri"
                :data-vv-as="$t('partner.update_partner_action.fields.webhooks.uri')"
                :disabled="webhooksMembershipActivityDisabled()"
                v-validate="`${currentPartner.webhooks.membership_activity.active ? 'required' : ''}|max:255`"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.webhooks.membership_activity.authorization"
                v-model="currentPartner.webhooks.membership_activity.authorization"
                :label="$t('partner.update_partner_action.fields.webhooks.authorization')"
                class="smoke-card-partner--update--webhooks_membership_activity_authorization"
                :error-messages="errors.collect('currentPartner.webhooks.membership_activity.authorization')"
                data-vv-name="currentPartner.webhooks.membership_activity.authorization"
                :data-vv-as="$t('partner.update_partner_action.fields.webhooks.authorization')"
                :disabled="webhooksMembershipActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <!-- MEMBER -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="currentPartner.webhooks.member_activity.active" :label="$t('partner.update_partner_action.fields.webhooks.member_activity')" class="smoke-partner--update--webhooks_member_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.webhooks.member_activity.uri"
                v-model="currentPartner.webhooks.member_activity.uri"
                :label="$t('partner.update_partner_action.fields.webhooks.uri') + `${currentPartner.webhooks.member_activity.active ? ' *' : ''}`"
                class="smoke-partner--update--webhooks_member_activity_uri"
                :error-messages="errors.collect('currentPartner.webhooks.member_activity.uri')"
                data-vv-name="currentPartner.webhooks.member_activity.uri"
                :data-vv-as="$t('partner.update_partner_action.fields.webhooks.uri')"
                :disabled="webhooksMemberActivityDisabled()"
                v-validate="`${currentPartner.webhooks.member_activity.active ? 'required' : ''}|max:255`"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.webhooks.member_activity.authorization"
                v-model="currentPartner.webhooks.member_activity.authorization"
                :label="$t('partner.update_partner_action.fields.webhooks.authorization')"
                class="smoke-card-partner--update--webhooks_member_activity_authorization"
                :error-messages="errors.collect('currentPartner.webhooks.member_activity.authorization')"
                data-vv-name="currentPartner.webhooks.member_activity.authorization"
                :data-vv-as="$t('partner.update_partner_action.fields.webhooks.authorization')"
                :disabled="webhooksMemberActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <!-- OFFER -->
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="currentPartner.webhooks.offer_activity.active" :label="$t('partner.update_partner_action.fields.webhooks.offer_activity')" class="smoke-partner--update--webhooks_offer_activity_check"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.webhooks.offer_activity.uri"
                v-model="currentPartner.webhooks.offer_activity.uri"
                :label="$t('partner.update_partner_action.fields.webhooks.uri') + `${currentPartner.webhooks.offer_activity.active ? ' *' : ''}`"
                class="smoke-partner--update--webhooks_offer_activity_uri"
                :error-messages="errors.collect('currentPartner.webhooks.offer_activity.uri')"
                data-vv-name="currentPartner.webhooks.offer_activity.uri"
                :data-vv-as="$t('partner.update_partner_action.fields.webhooks.uri')"
                :disabled="webhooksOfferActivityDisabled()"
                v-validate="`${currentPartner.webhooks.offer_activity.active ? 'required' : ''}|max:255`"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.webhooks.offer_activity.authorization"
                v-model="currentPartner.webhooks.offer_activity.authorization"
                :label="$t('partner.update_partner_action.fields.webhooks.authorization')"
                class="smoke-card-partner--update--webhooks_offer_activity_authorization"
                :error-messages="errors.collect('currentPartner.webhooks.offer_activity.authorization')"
                data-vv-name="currentPartner.webhooks.offer_activity.authorization"
                :data-vv-as="$t('partner.update_partner_action.fields.webhooks.authorization')"
                :disabled="webhooksOfferActivityDisabled()"
                v-validate="'max:255'"
                maxlength="255"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="content" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.content.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.brand_color"
                v-model="currentPartner.content.brand_color"
                :label="$t('partner.update_partner_action.fields.content.brand_color')"
                :error-messages="errors.collect('currentPartner.content.brand_color')"
                data-vv-name="currentPartner.content.brand_color"
                :data-vv-as="$t('partner.update_partner_action.fields.content.brand_color')"
                v-validate="'length:7'"
                required
                counter
                maxlength="7"
                class="smoke-partner--update--content_brand_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${currentPartner.content.brand_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.content.name_en"
                v-model="currentPartner.content.name.en"
                :label="$t('partner.update_partner_action.fields.content.name_en')"
                :error-messages="errors.collect('currentPartner.content.name.en')"
                data-vv-name="currentPartner.content.name.en"
                :data-vv-as="$t('partner.update_partner_action.fields.content.name_en')"
                v-validate="'required|max:100'"
                required
                counter
                maxlength="100"
                class="smoke-partner--update--content_name_en"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.content.url_en"
                v-model="currentPartner.content.url.en"
                :label="$t('partner.update_partner_action.fields.content.url_en')"
                :error-messages="errors.collect('currentPartner.content.url.en')"
                data-vv-name="currentPartner.content.url.en"
                :data-vv-as="$t('partner.update_partner_action.fields.content.url_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--update--content_url_en"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.content.program_url_en"
                v-model="currentPartner.content.program_url.en"
                :label="$t('partner.update_partner_action.fields.content.program_url_en')"
                :error-messages="errors.collect('currentPartner.content.program_url.en')"
                data-vv-name="currentPartner.content.program_url.en"
                :data-vv-as="$t('partner.update_partner_action.fields.content.program_url_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--update--content_program_url_en"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.content.name_fr"
                v-model="currentPartner.content.name.fr"
                :label="$t('partner.update_partner_action.fields.content.name_fr')"
                :error-messages="errors.collect('currentPartner.content.name.fr')"
                data-vv-name="currentPartner.content.name.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.content.name_fr')"
                v-validate="'max:100'"
                counter
                maxlength="100"
                class="smoke-partner--update--content_name_fr"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.content.url_fr"
                v-model="currentPartner.content.url.fr"
                :label="$t('partner.update_partner_action.fields.content.url_fr')"
                :error-messages="errors.collect('currentPartner.content.url.fr')"
                data-vv-name="currentPartner.content.url.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.content.url_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_url_fr"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="url"
                name="currentPartner.content.program_url_fr"
                v-model="currentPartner.content.program_url.fr"
                :label="$t('partner.update_partner_action.fields.content.program_url_fr')"
                :error-messages="errors.collect('currentPartner.content.program_url.fr')"
                data-vv-name="currentPartner.content.program_url.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.content.program_url_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_program_url_fr"
              />
            </v-flex>
          </v-layout>
          <v-layout row class="section-pad-top">
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.content.default_location.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="partner.content.default_location.street"
                v-model="currentPartner.content.default_location.address.street"
                :label="$t('partner.update_partner_action.fields.content.default_location.street')"
                :error-messages="errors.collect('partner.content.default_location.street')"
                data-vv-name="partner.content.default_location.street"
                :data-vv-as="$t('partner.update_partner_action.fields.content.default_location.street')"
                v-validate="'max:50'"
                counter
                maxlength="50"
                class="smoke-partner--add--content_default_location_street"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.city"
                v-model="currentPartner.content.default_location.address.city"
                :label="$t('partner.update_partner_action.fields.content.default_location.city')"
                :error-messages="errors.collect('partner.content.default_location.city')"
                data-vv-name="partner.content.default_location.city"
                :data-vv-as="$t('partner.update_partner_action.fields.content.default_location.city')"
                v-validate="'max:50'"
                counter
                maxlength="50"
                class="smoke-partner--add--content_default_location_city"
              />
            </v-flex>
            <v-flex xs12 sm4>
              <v-select
                name="partner.content.default_location.state"
                v-model="currentPartner.content.default_location.address.state"
                :label="$t('partner.update_partner_action.fields.content.default_location.state')"
                :error-messages="errors.collect('partner.content.default_location.state')"
                data-vv-name="partner.content.default_location.state"
                :data-vv-as="$t('partner.update_partner_action.fields.content.default_location.state')"
                :items="stateOptions"
                item-text="name"
                item-value="abbr"
                v-validate="'max:50'"
                class="smoke-partner--add--content_default_location_state"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm4>
              <v-select
                name="partner.content.default_location.country"
                v-model="currentPartner.content.default_location.address.country"
                :label="$t('partner.update_partner_action.fields.content.default_location.country')"
                :error-messages="errors.collect('partner.content.default_location.country')"
                data-vv-name="partner.content.default_location.country"
                :data-vv-as="$t(`partner.update_partner_action.fields.content.default_location.country`)"
                :items="countryOptions"
                v-validate="'max:50'"
                item-text="name"
                item-value="abbr"
                class="smoke-partner--add--content_default_location_country"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.zip"
                v-model="currentPartner.content.default_location.address.zip"
                :label="$t('partner.update_partner_action.fields.content.default_location.zip')"
                :error-messages="errors.collect('partner.content.default_location.zip')"
                data-vv-name="partner.content.default_location.zip"
                :data-vv-as="$t('partner.update_partner_action.fields.content.default_location.zip')"
                v-validate="{ regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
                maxlength="7"
                class="smoke-partner--add--content_default_location_zip"
              />
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.lat"
                v-model="currentPartner.content.default_location.coordinates.lat"
                :label="$t('partner.update_partner_action.fields.content.default_location.lat')"
                :error-messages="errors.collect('partner.content.default_location.lat')"
                data-vv-name="partner.content.default_location.lat"
                :data-vv-as="$t('partner.update_partner_action.fields.content.default_location.lat')"
                v-validate="'max:50'"
                maxlength="50"
                class="smoke-partner--add--content_default_location_lat"
              />
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field
                type="text"
                name="partner.content.default_location.lng"
                v-model="currentPartner.content.default_location.coordinates.lng"
                :label="$t('partner.update_partner_action.fields.content.default_location.lng')"
                :error-messages="errors.collect('partner.content.default_location.lng')"
                data-vv-name="partner.content.default_location.lng"
                :data-vv-as="$t('partner.update_partner_action.fields.content.default_location.lng')"
                v-validate="'max:50'"
                maxlength="50"
                class="smoke-partner--add--content_default_location_lng"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="logo" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.logo.light_background') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.light_background.horizontal_en"
                v-model="currentPartner.content.logo.light_background.horizontal.en"
                :label="$t('partner.update_partner_action.fields.logo.horizontal_en')"
                :error-messages="errors.collect('currentPartner.content.logo.light_background.horizontal.en')"
                data-vv-name="currentPartner.content.logo.light_background.horizontal.en"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.horizontal_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--update--content_light_background_horizontal_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img :src="currentPartner.content.logo.light_background.horizontal.en"></v-img> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.light_background.horizontal_fr"
                v-model="currentPartner.content.logo.light_background.horizontal.fr"
                :label="$t('partner.update_partner_action.fields.logo.horizontal_fr')"
                :error-messages="errors.collect('currentPartner.logo.light_background.horizontal_fr')"
                data-vv-name="currentPartner.logo.light_background.horizontal_fr"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.horizontal_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_light_background_horizontal_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img v-if="currentPartner.content.logo.light_background.horizontal.fr" :src="currentPartner.content.logo.light_background.horizontal.fr" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.light_background.square_en"
                v-model="currentPartner.content.logo.light_background.square.en"
                :label="$t('partner.update_partner_action.fields.logo.square_en')"
                :error-messages="errors.collect('currentPartner.content.logo.light_background.square.en')"
                data-vv-name="currentPartner.content.logo.light_background.square.en"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.square_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--update--content_light_background_square_en"
              />
            </v-flex>
            <v-flex x s3 class="tab-logos-image-preview"> <v-img :src="currentPartner.content.logo.light_background.square.en" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.light_background.square_fr"
                v-model="currentPartner.content.logo.light_background.square.fr"
                :label="$t('partner.update_partner_action.fields.logo.square_fr')"
                :error-messages="errors.collect('currentPartner.content.logo.light_background.square.fr')"
                data-vv-name="currentPartner.content.logo.light_background.square.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.square_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_light_background_square_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview"> <v-img v-if="currentPartner.content.logo.light_background.square.fr" :src="currentPartner.content.logo.light_background.square.fr" /> </v-flex>
          </v-layout>

          <v-layout row class="section-pad-top">
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.logo.dark_background') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.dark_background.horizontal_en"
                v-model="currentPartner.content.logo.dark_background.horizontal.en"
                :label="$t('partner.update_partner_action.fields.logo.horizontal_en')"
                :error-messages="errors.collect('currentPartner.content.logo.dark_background.horizontal.en')"
                data-vv-name="currentPartner.content.logo.dark_background.horizontal.en"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.horizontal_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--update--content_dark_background_horizontal_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img :src="currentPartner.content.logo.dark_background.horizontal.en" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.dark_background.horizontal_fr"
                v-model="currentPartner.content.logo.dark_background.horizontal.fr"
                :label="$t('partner.update_partner_action.fields.logo.horizontal_fr')"
                :error-messages="errors.collect('currentPartner.content.logo.dark_background.horizontal.fr')"
                data-vv-name="currentPartner.content.logo.dark_background.horizontal.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.horizontal_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_dark_background_horizontal_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img v-if="currentPartner.content.logo.dark_background.horizontal.fr" :src="currentPartner.content.logo.dark_background.horizontal.fr" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.dark_background.square_en"
                v-model="currentPartner.content.logo.dark_background.square.en"
                :label="$t('partner.update_partner_action.fields.logo.square_en')"
                :error-messages="errors.collect('currentPartner.content.logo.dark_background.square.en')"
                data-vv-name="currentPartner.content.logo.dark_background.square.en"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.square_en')"
                v-validate="'required|url|max:255'"
                required
                counter
                maxlength="255"
                class="smoke-partner--update--content_dark_background_square_en"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img :src="currentPartner.content.logo.dark_background.square.en" /> </v-flex>

            <v-flex sm9 xs12>
              <v-text-field
                type="url"
                name="currentPartner.logo.dark_background.square_fr"
                v-model="currentPartner.content.logo.dark_background.square.fr"
                :label="$t('partner.update_partner_action.fields.logo.square_fr')"
                :error-messages="errors.collect('currentPartner.content.logo.dark_background.square.fr')"
                data-vv-name="currentPartner.content.logo.dark_background.square.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.logo.square_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_dark_background_square_fr"
              />
            </v-flex>
            <v-flex xs3 class="tab-logos-image-preview tab-logos-image-preview--dark"> <v-img v-if="currentPartner.content.logo.dark_background.square.fr" :src="currentPartner.content.logo.dark_background.square.fr" /> </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item kye="features" class="section-pad-top">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('partner.update_partner_action.fields.features.title') }}</v-label>
            </v-flex>
            <v-flex v-for="feature in checkboxFeature" :key="feature.value" xs12 class="smoke-partner--update--features">
              <v-checkbox :class="{ [`smoke-partner--update--features--${feature.value}`]: true }" xs12 v-model="currentPartner.features" :label="feature.label" :value="feature.value"></v-checkbox>
            </v-flex>

            <v-flex xs11>
              <v-text-field
                name="feature"
                v-model="temp_feature"
                :label="$t('partner.update_partner_action.fields.features.other')"
                class="smoke-partner--update--other-feature"
                v-validate="'max:255'"
                :error-messages="errors.collect('feature')"
                data-vv-name="feature"
                required
                counter
                maxlength="255"
                :data-vv-as="$t('partner.update_partner_action.fields.features.other')"
              ></v-text-field>
            </v-flex>

            <v-flex xs1>
              <button type="button" class="smoke-partner--update--add-other-feature" @click="addFeatureToArray" :disabled="disable_add_feature">
                <v-icon small>fal fa-plus</v-icon>
              </button>
            </v-flex>

            <v-flex v-if="currentPartner && currentPartner.features && currentPartner.features.length > 0" xs11 class="smoke-partner--update--features-section">
              <v-label>{{ $t('partner.update_partner_action.fields.features.active') }}</v-label>
              <ul class="smoke-partner--update--features-list">
                <li v-for="(feature, index) in currentPartner.features" :key="index">
                  {{ feature }}
                  <button type="button" class="smoke-partner--update--remove-feature" @click="removeFeatureFromArray(feature)">
                    <v-icon small>fal fa-minus-circle</v-icon>
                  </button>
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="apply" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.apply.header_logo') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.apply.header_logo_url_en"
                v-model="currentPartner.content.apply.header_logo_url.en"
                :label="$t('partner.update_partner_action.fields.apply.url_en')"
                :error-messages="errors.collect('currentPartner.content.apply.header_logo_url.en')"
                data-vv-name="currentPartner.content.apply.header_logo_url.en"
                :data-vv-as="$t('partner.update_partner_action.fields.apply.url_en')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_apply_header_url_en"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.apply.header_logo_url_fr"
                v-model="currentPartner.content.apply.header_logo_url.fr"
                :label="$t('partner.update_partner_action.fields.apply.url_fr')"
                :error-messages="errors.collect('currentPartner.content.apply.header_logo_url.fr')"
                data-vv-name="currentPartner.content.apply.header_logo_url.fr"
                :data-vv-as="$t('partner.update_partner_action.fields.apply.url_fr')"
                v-validate="'url|max:255'"
                counter
                maxlength="255"
                class="smoke-partner--update--content_apply_header_url_fr"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.apply.body_action_color"
                v-model="currentPartner.content.apply.body_action_color"
                :label="$t('partner.update_partner_action.fields.apply.body_action_color')"
                :error-messages="errors.collect('currentPartner.apply.body_action_color')"
                data-vv-name="currentPartner.apply.body_action_color"
                :data-vv-as="$t('partner.update_partner_action.fields.apply.body_action_color')"
                v-validate="'length:7'"
                counter
                maxlength="7"
                class="smoke-partner--update--content_body_action_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${currentPartner.content.apply.body_action_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.apply.header_background_color"
                v-model="currentPartner.content.apply.header_background_color"
                :label="$t('partner.update_partner_action.fields.apply.header_background_color')"
                :error-messages="errors.collect('currentPartner.apply.header_background_color')"
                data-vv-name="currentPartner.apply.header_background_color"
                :data-vv-as="$t('partner.update_partner_action.fields.apply.header_background_color')"
                v-validate="'length:7'"
                counter
                maxlength="7"
                class="smoke-partner--update--content_header_background_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${currentPartner.content.apply.header_background_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                type="text"
                name="currentPartner.apply.header_text_color"
                v-model="currentPartner.content.apply.header_text_color"
                :label="$t('partner.update_partner_action.fields.apply.header_text_color')"
                :error-messages="errors.collect('currentPartner.apply.header_text_color')"
                data-vv-name="currentPartner.apply.header_text_color"
                :data-vv-as="$t('partner.update_partner_action.fields.apply.header_text_color')"
                v-validate="'length:7'"
                counter
                maxlength="7"
                class="smoke-partner--update--content_header_text_color"
              >
                <template v-slot:append><div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${currentPartner.content.apply.header_text_color || 'transparent'}; borderRadius: 5px;`"></div></template>
              </v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-checkbox v-model="currentPartner.content.apply.preview_page_active" :label="$t('partner.update_partner_action.fields.apply.preview_page_active')" class="smoke-partner--update--content_preview_page_active" />
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="instant_card_application">
          <v-layout row wrap>
            <v-flex sm6 xs12 class="checkboxes-indented-with-heading">
              <v-label>{{ $t('partner.update_partner_action.fields.instant_card_application.title') }}</v-label>
              <div class="add-partner-action--options">
                <v-flex xs12>
                  <v-checkbox v-model="currentPartner.account_fields.gender" :label="$t('partner.update_partner_action.fields.instant_card_application.gender')" class="smoke-partner--add--card_application_gender" />

                  <v-checkbox v-model="currentPartner.account_fields.year_of_birth" :label="$t('partner.update_partner_action.fields.instant_card_application.year_of_birth')" class="smoke-partner--add--card_application_day_of_birth" />
                </v-flex>
              </div>
            </v-flex>
          </v-layout>
        </v-tab-item>

        <v-tab-item key="connector_interfaces" class="section-pad-top">
          <v-layout row>
            <v-flex xs12>
              <v-label>{{ $t('partner.update_partner_action.fields.connector_interfaces.title') }}</v-label>
            </v-flex>
          </v-layout>

          <v-layout row wrap v-for="(connector_interface_name, index) in getConnectorInterfaces" :key="index">
            <v-flex xs12>
              <v-checkbox v-model="currentPartner.connector_interfaces[connector_interface_name].active" :label="connector_interface_name" :class="`smoke-partner--update--connector_interface_${connector_interface_name}_active`"></v-checkbox>
            </v-flex>
            <v-flex xs12>
              <v-select
                name="language"
                :disabled="!currentPartner.connector_interfaces[connector_interface_name].active"
                v-model="currentPartner.connector_interfaces[connector_interface_name].connector_name"
                :label="$t('partner.update_partner_action.fields.connector_interfaces.connector_name')"
                :error-messages="errors.collect(`${connector_interface_name}_connector_name`)"
                :data-vv-name="`${connector_interface_name}_connector_name`"
                :data-vv-as="$t(`partner.update_partner_action.fields.connector_interfaces.connector_name`)"
                :items="getConnectorNames"
                :class="`smoke-partner--update--connector_interface_${connector_interface_name}_connector_name`"
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import states from '@/utils/states';
import { mapGetters } from 'vuex';
import CANADIAN_TIMEZONES from '@/tools/canadian-timezones';

export default {
  name: 'update-partner-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkboxFeature: [
        { label: 'Magis Prime', value: 'magis-prime' },
        { label: 'Hello Offer', value: 'hello-offers' },
        { label: 'Hello Workplace', value: 'hello-workplace' },
        { label: 'Request Consent Communication', value: 'request-consent-communication' },
      ],
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: states.selectOptions(this),
      regionOptions: ['ON', 'QC', 'NB'],
      timezoneOptions: [...CANADIAN_TIMEZONES],
      temp_feature: '',
      temp_allowed_card_package_ids: '',
      disable_add_feature: true,
      currentPartner: {
        id: '',
        name: '',
        card_packages: {
          en: '',
          fr: '',
        },
        renew_card_packages: {
          en: '',
          fr: '',
        },
        instant_card_packages: {
          en: '',
          fr: '',
        },
        instant_renew_card_packages: {
          en: '',
          fr: '',
        },
        features: [],
        options: {
          integration_jwt_secret: null,
          card_activation_reminder: {
            active: false,
          },
          join_program: {
            active: false,
          },
          card_application: {
            active: false,
            send_alert_on_approval: false,
            allowed_card_package_ids: [],
          },
          card_activation: {
            active: false,
          },
          manageable: false,
          invite_only: false,
        },
        webhooks: {
          membership_activity: {
            active: false,
            uri: null,
            authorization: null,
          },
          member_activity: {
            active: false,
            uri: null,
            authorization: null,
          },
          offer_activity: {
            active: false,
            uri: null,
            authorization: null,
          },
        },
        account_fields: {
          gender: null,
          year_of_birth: null,
        },
        connector_interfaces: {
          magis: {
            active: false,
          },
        },
        content: {
          name: {
            en: '',
            fr: null,
          },
          default_location: {
            address: {
              street: undefined,
              city: undefined,
              state: undefined,
              country: undefined,
              zip: undefined,
            },
            coordinates: {
              lat: undefined,
              lng: undefined,
            },
          },
          url: {
            en: '',
            fr: null,
          },
          program_url: {
            en: '',
            fr: null,
          },
          logo: {
            light_background: {
              horizontal: {
                en: '',
                fr: null,
              },
              square: {
                en: '',
                fr: null,
              },
            },
            dark_background: {
              horizontal: {
                en: '',
                fr: null,
              },
              square: {
                en: '',
                fr: null,
              },
            },
          },
          apply: {
            header_logo_url: {
              en: null,
              fr: null,
            },
            body_action_color: null,
            header_background_color: null,
            header_text_color: null,
            preview_page_active: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('partner', ['getLocalPartnerById', 'getConnectorNames', 'getConnectorInterfaces']),
    isFormChanged() {
      const changed = Object.keys(this.fields).some((key) => this.fields[key].changed);
      const featuresChanged = this.currentPartner.features === this.partner.features ? false : true;

      return changed || featuresChanged;
    },
  },
  watch: {
    partner(value) {
      this.currentPartner = _.cloneDeep(value);

      if (!_.get(this.currentPartner, 'content.default_location')) {
        _.set(this.currentPartner, 'content.default_location.address.street', '');
        _.set(this.currentPartner, 'content.default_location.address.city', '');
        _.set(this.currentPartner, 'content.default_location.address.country', '');
        _.set(this.currentPartner, 'content.default_location.address.state', '');
        _.set(this.currentPartner, 'content.default_location.address.zip', '');
        _.set(this.currentPartner, 'content.default_location.coordinates.lat', '');
        _.set(this.currentPartner, 'content.default_location.coordinates.lng', '');
      }

      if (!_.get(this.currentPartner, 'instant_card_packages')) {
        _.set(this.currentPartner, 'instant_card_packages.en', '');
        _.set(this.currentPartner, 'instant_card_packages.fr', '');
      }

      if (!_.get(this.currentPartner, 'instant_renew_card_packages')) {
        _.set(this.currentPartner, 'instant_renew_card_packages.en', '');
        _.set(this.currentPartner, 'instant_renew_card_packages.fr', '');
      }
    },
    temp_feature() {
      if (this.temp_feature && this.isFeatureValid()) {
        this.disable_add_feature = false;
      } else {
        this.disable_add_feature = true;
      }
    },
  },
  methods: {
    isFeatureValid() {
      if (this.errors.has('feature')) {
        return false;
      }
      return true;
    },
    addAllowedCardPacakgeIdToArray() {
      this.currentPartner.options.card_application.allowed_card_package_ids.push(this.temp_allowed_card_package_ids);
    },
    removeAllowedCardPacakgeIdFromArray(allowedCardPacakgeId) {
      const updatedAllowedCardPacakgeId = _.filter(this.currentPartner.options.card_application.allowed_card_package_ids, (item) => {
        return item !== allowedCardPacakgeId;
      });
      this.currentPartner.options.card_application.allowed_card_package_ids = updatedAllowedCardPacakgeId;
    },
    addFeatureToArray() {
      this.currentPartner.features.push(this.temp_feature);
    },
    removeFeatureFromArray(feature) {
      const updatedFeature = _.filter(this.currentPartner.features, (item) => {
        return item !== feature;
      });
      this.currentPartner.features = updatedFeature;
    },
    resetForm() {
      this.currentPartner = _.cloneDeep(this.getLocalPartnerById(this.partner.id));
      this.temp_feature = '';
      this.disable_add_feature = true;
    },
    async submit() {
      if (await this.validationComplete()) {
        this.currentPartner.options.integration_jwt_secret = this.currentPartner.options.integration_jwt_secret === '' ? null : this.currentPartner.options.integration_jwt_secret;
        await this.executeAction(
          {
            action: 'partner/updatePartner',
            name: 'updatePartner',
            success: this.success,
          },
          { partner: this.buildPayload() },
        );
      }
    },
    buildPayload() {
      if (!this.currentPartner.content.default_location.address.street) {
        return _.omit(this.currentPartner, ['content.default_location']);
      }
      return this.currentPartner;
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$validator.reset();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$validator.reset();
      this.$emit('close');
    },
    webhooksMembershipActivityDisabled() {
      return !this.currentPartner.webhooks.membership_activity.active;
    },
    webhooksMemberActivityDisabled() {
      return !this.currentPartner.webhooks.member_activity.active;
    },
    webhooksOfferActivityDisabled() {
      return !this.currentPartner.webhooks.offer_activity.active;
    },
  },
};
</script>
