<template>
  <card-modal icon="fal fa-key" :title="$t('app.get_api_secret.title')" context="apiSecret" @cancel="cancel" cancel_text="app.get_api_secret.button_cancel">
    <div slot="content">
      <label-text :label="$t('app.get_api_secret.label')">
        <p slot="labelTextLink" class="label-text--value card--api-secret-field">
          <v-layout row>
            {{ apiSecret }} <button v-if="apiSecret" class="clipboard" v-clipboard:copy="apiSecret"><v-icon small>file_copy</v-icon></button>
          </v-layout>
        </p>
      </label-text>
    </div>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'get-api-secret-action',
  mixins: [actionErrorTrackable],
  props: {
    appKey: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('app', ['apiSecret']),
  },
  watch: {
    visible() {
      if (this.visible === true) {
        this.getApiSecret();
      }
    },
  },
  methods: {
    async getApiSecret() {
      await this.executeAction(
        {
          action: 'app/getApp',
          name: 'apiSecret',
        },
        this.appKey,
      );
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
