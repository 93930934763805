import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    brandings: [],
  },
  getters: {
    brandings: (state) => state.brandings,
  },
  mutations: {
    brandings(state, brandings) {
      state.brandings = brandings;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const brandingService = Vue.prototype.$services.branding;

      return brandingService.searchBrandings(page, limit, keyword);
    },
    async listBrandings({ commit }) {
      const brandingService = Vue.prototype.$services.branding;
      const brandings = await brandingService.listBrandings(1, 1000);

      commit('brandings', brandings);
    },
  },
};
