import Vue from 'vue';

const service = {
  async listPackages(page, limit, status) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generateListPackagesPath(page, limit, status)}`);
    return response.data.data;
  },

  async createPackage(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1`, payload);
    return response.data.data;
  },

  async updatePackage(key, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}`, payload);
    return response.data.data;
  },

  async deletePackage(key) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}`);
    return response.data;
  },

  async deactivatePackage(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}/deactivate`, {});
    return response.data;
  },

  async activatePackage(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/${key}/activate`, {});
    return response.data;
  },
};

function generateListPackagesPath(page, limit, status) {
  let path = '/card-packages/v1';
  if (page || limit || status) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
