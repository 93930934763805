<template>
  <view-main title="page_title.alert"> <view-alert-list slot="content" :alert-id="alertId"></view-alert-list> </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import ViewAlertList from './ViewAlertList.vue';

export default {
  name: 'alert-view',
  mixins: [renderErrorTrackable],
  components: { ViewAlertList },
  props: {
    alertId: {
      type: String,
      required: false,
    },
  },
};
</script>
