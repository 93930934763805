<template>
  <card-modal
    icon="fal fa-user-edit"
    :title="$t('virtual_money.update_action.title')"
    context="updateVirtualMoney"
    @submit="submit"
    @cancel="cancel"
    cancel_text="virtual_money.add_action.button_cancel"
    submit_text="virtual_money.add_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.name"
            v-model="currentVirtualMoney.name"
            :label="$t('virtual_money.update_action.fields.name')"
            :error-messages="errors.collect('virtual_money.name')"
            data-vv-name="virtual_money.name"
            :data-vv-as="$t('virtual_money.update_action.fields.name')"
            v-validate="'required|max:50'"
            required
            counter
            maxlength="50"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.wallet_definition_id"
            v-model="currentVirtualMoney.wallet_definition_id"
            :label="$t('virtual_money.update_action.fields.wallet_definition_id')"
            :error-messages="errors.collect('virtual_money.wallet_definition_id')"
            data-vv-name="virtual_money.wallet_definition_id"
            :data-vv-as="$t('virtual_money.update_action.fields.wallet_definition_id')"
            v-validate="'max:100'"
            counter
            maxlength="100"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            type="text"
            name="virtual_money.option_load_on_card_program_id"
            v-model="currentVirtualMoney.options.load_on_card_program_id"
            :label="$t('virtual_money.update_action.fields.option_load_on_card_program_id')"
            :error-messages="errors.collect('virtual_money.option_load_on_card_program_id')"
            data-vv-name="virtual_money.option_load_on_card_program_id"
            :data-vv-as="$t('virtual_money.update_action.fields.option_load_on_card_program_id')"
            v-validate="'max:100'"
            counter
            maxlength="100"
          />
        </v-flex>

        <v-checkbox v-model="currentVirtualMoney.options.create_on_account_creation" :label="$t('virtual_money.update_action.fields.option_create_on_account_creation')" />
        <v-checkbox :disabled="!currentVirtualMoney.options.load_on_card_program_id" v-model="currentVirtualMoney.options.auto_adjustment_other_card_program" :label="$t('virtual_money.update_action.fields.option_auto_adjustment_other_card_program')" />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-virtual-money-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    selectedVirtualMoney: {
      type: Object,
      required: true,
      default: () => {
        return { name: {} };
      },
    },
  },
  data() {
    return {
      currentVirtualMoney: { name: {}, options: {} },
    };
  },
  computed: {
    ...mapGetters('virtualMoney', ['virtualMoney']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    selectedVirtualMoney(value) {
      this.currentVirtualMoney = _.cloneDeep(value);
      this.currentVirtualMoney.options = {};
      this.fetchVirtualMoney(value.id);
    },
    'currentVirtualMoney.options.load_on_card_program_id'(newVal) {
      if (!newVal) {
        this.currentVirtualMoney.options.auto_adjustment_other_card_program = false;
      }
    },
  },
  methods: {
    resetForm() {
      this.currentVirtualMoney = _.cloneDeep(this.virtualMoney);
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'virtualMoney/updateVirtualMoney',
            name: 'updateVirtualMoney',
            success: this.success,
          },
          { note_type: this.currentVirtualMoney },
        );
      }
    },
    async fetchVirtualMoney(id) {
      this.dataReady = false;
      await this.executeAction({ action: 'virtualMoney/getVirtualMoney', success: null }, { id });
      this.currentVirtualMoney = _.cloneDeep(this.virtualMoney);
      this.dataReady = true;
    },
    async validationComplete() {
      return this.$validator.validateAll();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
