import Vue from 'vue';
import Errors from './../utils/error/error-types';

const service = {
  async createBrandingCategory(category) {
    try {
      const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/branding-categories`, category);
      return response.data.data;
    } catch (e) {
      if (brandingCategoryAlreadyExists(e)) {
        return Promise.reject(transformToHttpError(400, 'branding_category.id.already_exists'));
      }

      throw e;
    }
  },

  async updateBrandingCategory(key, category) {
    try {
      const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/branding-categories/${key}`, category);
      return response.data.data;
    } catch (e) {
      if (brandingCategoryAlreadyExists(e)) {
        return Promise.reject(transformToHttpError(400, 'branding_category.id.already_exists'));
      }
      throw e;
    }
  },

  async getBrandingCategory(key) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/branding-categories/${key}`);
    return response.data.data;
  },

  async listBrandingCategories(page, limit) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/branding-categories', page, limit)}`);
    return response.data.data;
  },
};

function brandingCategoryAlreadyExists(e) {
  return e.response_code === 'branding_category.already.exists';
}

function transformToHttpError(statusCode, code) {
  return new Errors.HttpError(statusCode, { error: { code } });
}

function generatePath(startPath, page, limit) {
  let path = startPath;
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
