<template>
  <view-main title="page_title.alert"> <transactions-list slot="content"></transactions-list> </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import TransactionsList from './TransactionsList.vue';

export default {
  name: 'status-view',
  mixins: [renderErrorTrackable],
  components: { TransactionsList },
};
</script>
