<template>
  <v-flex>
    <h1 hidden>{{ $t('agent.form.title') }}</h1>
    <v-card>
      <form-search :input-label="'agent.form.label'" :submit-text="'agent.form.submit'" @submit="submit" :max-length="50">
        <div slot="main-input">
          <v-text-field class="form-search--search-field" :label="$t('agent.form.label')" v-model="keyword" data-vv-name="keyword" :error-messages="errors.collect('keyword')" :maxlength="50" />
          <v-checkbox :label="$t('agent.form.exclude_deactivated')" v-model="exclude_deactivated" />
        </div>
        <template slot="buttons"><button-primary class="agent-form--btn-add" :loading="working" :text="$t('agent.form.add')" @click="showModalAddAgent"></button-primary></template>
      </form-search>
    </v-card>
    <modal-action :show-modal="showAddAgentModal"> <add-agent-action @close="closeModalAddAgent()" @action="reloadPage"></add-agent-action> </modal-action>
    <v-flex class="data-section"> <agents-list v-if="dataReady && agentsFromSearch" :items="agentsFromSearch"></agents-list> </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, cypress, security } from '@/mixins';
import AddAgentAction from './AddAgentAction';
import AgentsList from './AgentsList';

export default {
  name: 'view-agent-form',
  mixins: [actionErrorTrackable, security, cypress],
  components: { AddAgentAction, AgentsList },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      keyword: '',
      exclude_deactivated: true,
      dataReady: false,
      showAddAgentModal: false,
    };
  },
  computed: {
    ...mapGetters('agent', ['agentsFromSearch']),
  },
  async created() {
    await this.fetchAgents();
  },
  methods: {
    showModalAddAgent() {
      this.showAddAgentModal = true;
    },
    closeModalAddAgent() {
      this.showAddAgentModal = false;
    },
    reloadPage() {
      this.showAddAgentModal = false;
    },
    async fetchWithKeyword() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.clearAllError();
        await this.fetchAgents();
      }
    },
    async fetchAgents() {
      this.dataReady = false;
      await this.executeAction(
        {
          action: 'agent/searchAgents',
          success: this.success,
        },
        {
          keyword: this.keyword,
          exclude_deactivated: this.exclude_deactivated,
        },
      );
      this.dataReady = true;
    },
    async submit(searchTerm) {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.fetchWithKeyword();
      }
    },
    clear() {
      this.keyword = '';
      this.$validator.reset();
    },
  },
};
</script>
