<template>
  <view-main title="page_title.note_type">
    <view-note-type-form slot="content"></view-note-type-form>
  </view-main>
</template>

<script>
import ViewNoteTypeForm from './ViewVirtualMoneyForm.vue';
import { renderErrorTrackable } from '@/mixins';

export default {
  name: 'virtual-money-view',
  mixins: [renderErrorTrackable],
  components: { ViewNoteTypeForm },
};
</script>
