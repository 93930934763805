<template>
  <div>
    <table class="groups-table responsive-table">
      <thead>
        <tr>
          <td>{{ $t('group.listTable.id') }}</td>
          <td>{{ $t('group.listTable.name') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="groups-table--id">
            <h5>{{ $t('group.listTable.id') }}:</h5>
            <span :class="{ 'info-not-available': !item.id }">{{ item.id | orNone }}</span>
          </td>
          <td class="groups-table--name">
            <h5>{{ $t('group.listTable.name') }}:</h5>
            <span :class="{ 'info-not-available': !item.name }">{{ item.name | orNone }}</span>
          </td>
          <td class="groups-table--actions">
            <v-btn
              align-bottom
              small
              outline
              color="primary"
              @click="
                passGroupToModal(item);
                showModalUpdateGroup();
              "
              class="groups-table--update-group"
              >{{ $t(`group.listTable.edit`) }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <modal-action :show-modal="showUpdateGroupModal">
        <update-group-action :group="currentGroup" @close="closeModalUpdateGroup" @action="reloadPage" />
      </modal-action>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="pageCount > 1" class="apps-table--pagination" v-model="pagination" :length="pageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import UpdateGroupAction from './UpdateGroupAction';

export default {
  name: 'groups-table',
  mixins: [actionErrorTrackable, security],
  components: { UpdateGroupAction },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      currentCardGroup: {},
      pagination: 1,
      currentGroup: {},
      showUpdateGroupModal: false,
    };
  },
  computed: {
    ...mapGetters('group', ['pageCount', 'pageNumber']),
  },
  watch: {
    pageNumber() {
      this.pagination = this.pageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'group/changePage',
          success: this.success,
        },
        {
          page: newValue,
        },
      );
    },
  },
  methods: {
    reloadPage() {
      this.currentGroup = {};
      this.showUpdateGroupModal = false;
    },
    passGroupToModal(cardGroup) {
      this.currentGroup = cardGroup;
    },
    showModalUpdateGroup() {
      this.showUpdateGroupModal = true;
    },
    closeModalUpdateGroup() {
      this.showUpdateGroupModal = false;
    },
  },
};
</script>
