<template>
  <card icon="fal fa-user" :title="`${$t('branding_category.list.title')}`">
    <template slot="headerZoneRight"> </template>

    <template slot="content">
      <branding-category-table :items="items"></branding-category-table>
    </template>
  </card>
</template>

<script>
import BrandingCategoryTable from './BrandingCategoryTable';

export default {
  name: 'branding-category-list',
  components: { BrandingCategoryTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
